import React from 'react';
import {Form, Table} from "react-bootstrap";
import {useAddAlertMutation, useEditAlertMutation, useGetAlertQuery} from "../../redux/services/SettingAlertService";
import {Controller, useForm} from "react-hook-form";
import {getCurrentDateTime, sweetAlert} from "../../utils";
import LoadingButton from "../../components/LoadingButton";
import moment from "moment";

const AlertsEditView = ({isEdit, alertEdit, alertView, resetAll}) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    const deferredQuery = `?sharable_id=${alertEdit?.id}&sharable_type=${'App\\Models\\Identity'}`

    const {data: data2, isLoading, isFetching, refetch} = useGetAlertQuery({deferredQuery})

    const [editRequest, {isLoading: addLoading}] = useEditAlertMutation()

    const data = data2?.response?.data
    const alertColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: true,

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const onSubmit = async (data) => {
        data['id'] = alertEdit?.id
        data['sharable_id'] = isEdit?.id
        data['sharable_type'] = 'lead'
        await editRequest(data).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    reset()
                    resetAll()
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    console.log('alertView', alertView)
    return (
        <>
            {alertEdit ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Name:</Form.Label>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={alertEdit?.name}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="text" placeholder="Enter name"
                                    isInvalid={errors.name}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Alert Message:</Form.Label>
                        <Controller
                            name="message"
                            control={control}
                            defaultValue={alertEdit?.message || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="text" placeholder="Enter alert message"
                                    isInvalid={errors.message}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description:</Form.Label>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={alertEdit?.description || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    as="textarea" rows={3} placeholder="Enter description"
                                    isInvalid={errors.description}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Alert Type:</Form.Label>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue={alertEdit?.type || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Select
                                    {...field}
                                    isInvalid={errors.type}
                                >
                                    <option value="">Please Select</option>
                                    <option value="email">Email</option>
                                    <option value="notification">Notification</option>
                                </Form.Select>
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date/Time:</Form.Label>
                        <Controller
                            name="date_time"
                            control={control}
                            defaultValue={alertEdit?.date_time || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="datetime-local"
                                    min={getCurrentDateTime()}
                                    isInvalid={errors.date_time}
                                />
                            )}
                        />
                    </Form.Group>
                    <LoadingButton
                        isLoading={addLoading}
                        name="Update"
                        form="lead-status"
                    />
                </Form>
            ) : (
                <div>
                    <Form.Group className="mb-3">
                        <Form.Label>Name:</Form.Label>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={alertView?.name}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="text" placeholder="Enter name"
                                    isInvalid={errors.name}
                                    disabled={true}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Alert Message:</Form.Label>
                        <Controller
                            name="message"
                            control={control}
                            defaultValue={alertView?.message || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="text" placeholder="Enter alert message"
                                    isInvalid={errors.message}
                                    disabled={true}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description:</Form.Label>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={alertView?.description || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    as="textarea" rows={3} placeholder="Enter description"
                                    isInvalid={errors.description}
                                    disabled={true}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Alert Type:</Form.Label>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue={alertView?.type || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Select
                                    {...field}
                                    isInvalid={errors.type}
                                    disabled={true}
                                >
                                    <option value="">Please Select</option>
                                    <option value="email">Email</option>
                                    <option value="notification">Notification</option>
                                </Form.Select>
                            )}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date/Time:</Form.Label>
                        <Controller
                            name="date_time"
                            control={control}
                            defaultValue={alertView?.date_time || ''}
                            rules={{
                                required: true
                            }}
                            render={({field}) => (
                                <Form.Control
                                    {...field}
                                    type="datetime-local"
                                    min={getCurrentDateTime()}
                                    isInvalid={errors.date_time}
                                    disabled={true}
                                />
                            )}
                        />
                    </Form.Group>
                </div>
            )}
        </>
    );
};

export default AlertsEditView;