import {newsApi} from './api'
import {
    ADD_Alert_ENDPOINT, ADD_ROLE_ENDPOINT, ADD_USER_ENDPOINT,
    DELETE_Alert_ENDPOINT, DELETE_ROLE_ENDPOINT, DELETE_USER_ENDPOINT,
    Edit_Alert_ENDPOINT, EDIT_ROLE_ENDPOINT, EDIT_USER_ENDPOINT,
    GET_Alert_ENDPOINT, GET_ROLE_ENDPOINT, GET_USER_ENDPOINT, VIEW_ROLE_ENDPOINT, VIEW_USER_ENDPOINT
} from "./apiConstant";

const prefix = "User";
export const UsersService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        [`get${prefix}`]: build.query({
            query: () => ({
                url: `${GET_USER_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        [`view${prefix}`]: build.query({
            query: (id) => ({
                url: `${VIEW_USER_ENDPOINT+id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
        }),
        [`add${prefix}`]: build.mutation({
            query: (form) => ({
                url: `${ADD_USER_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        [`edit${prefix}`]: build.mutation({
            query: (form) => {
                console.log(form);
                return {
                    url: `${EDIT_USER_ENDPOINT}${form?.id}`,
                    method: 'POST',
                    body: form,
                    headers: {
                        'Accept': 'application/json',
                    },
                }
            },
            invalidatesTags: ['LeadStatus'],
        }),
        [`remove${prefix}`] : build.mutation({
            query: (id) => ({
                url: `${DELETE_USER_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetUserQuery,
    useViewUserQuery,
    useAddUserMutation,
    useEditUserMutation,
    useRemoveUserMutation
} = UsersService