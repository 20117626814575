import React from 'react';
import {Button, Form, Offcanvas} from "react-bootstrap";
import MultiSelect from "../../components/MultiSelect";

const AtivityLogsFilter = ({show, close}) => {
    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Activity Logs Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>User:</Form.Label>
                            <Form.Select>
                                <option value="">Please Select</option>
                                <option value="">User 1</option>
                                <option value="">User 2</option>
                                <option value="">User 3</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Date:</Form.Label>
                            <div className="row g-1">
                                <div className="col">
                                    <Form.Control type="date" />
                                </div>
                                <div className="col">
                                    <Form.Control type="date" />
                                </div>
                            </div>
                        </Form.Group>

                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header className="row">
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default AtivityLogsFilter;