import React, { useState } from 'react';
import { Nav, Offcanvas, Tab, Table } from "react-bootstrap";
import { fullName, sweetAlert } from "../../utils";
import Alerts from "./Alerts";
import AssignTags from "./AssignTags";
import { Link, useNavigate } from "react-router-dom";
import { useConvertToIdentityMutation } from "../../redux/services/LeadService";
import History from "../IdentitiesPage/History";
import AlertsEditView from "./AlertsEditView";

const LeadsSide = ({ show, close, isEdit, remove }) => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('alerts');
    const [alertView, setAlertView] = useState(null)
    const [alertEdit, setAlertEdit] = useState(null)
    const [convert, { isLoading }] = useConvertToIdentityMutation()

    const onConvert = () => {
        convert({ id: isEdit?.id })
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    navigate('/identifies/list')
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    return (
        <>
            <Offcanvas show={show} className="canvas-lg bg-light" onHide={close} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        Lead Details
                    </Offcanvas.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={close}><i
                        className="ri-close-line"></i></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card border shadow-sm">
                                <div className="card-header">
                                    <div class="row m-0 justify-content-between w-100">
                                        <div class="col-auto p-0">
                                            <h4>ID#{isEdit?.id?.toString()?.padStart(4, '0')}</h4>
                                        </div>
                                        <div class="col-auto p-0">
                                            <button
                                                className="btn btn-info text-white"
                                                onClick={onConvert}
                                            >
                                                Convert To Identity
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-cen3er">Lead Info</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Name:</label>
                                                            <p>{isEdit?.name || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Email:</label>
                                                            <p>{isEdit?.email || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Phone Number:</label>
                                                            <p>{isEdit?.phone || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Gender:</label>
                                                            <p className='text-capitalize'>{isEdit?.gender?.toLowerCase() || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-2">
                                                            <label className="form-label">Notes:</label>
                                                            <p>{isEdit?.note || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-left">Address Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Street Address:</label>
                                                            <p>{isEdit?.street_address || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Appt Number:</label>
                                                            <p>{isEdit?.appt_number || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">City:</label>
                                                            <p>{isEdit?.city || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">State:</label>
                                                            <p>{isEdit?.state || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Zip Code:</label>
                                                            <p>{isEdit?.zip_code || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Metro Area:</label>
                                                            <p>{isEdit?.metro_area || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="d-flex gap-2 mt-3">
                                                <Link
                                                    to={`/lead/edit/${isEdit?.id}`}
                                                    className="text-white px-5  btn btn-primary"
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="text-white px-5 btn btn-danger"
                                                    onClick={() => remove(isEdit)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3" style={{ position: "sticky", top: 0 }}>
                            <div className="side-bar">
                                <div className="card border shadow-sm">
                                    <div className="card-header bg-info">
                                        <span className='text-white'><span
                                            className=" font-weight-bold">Last Activity:</span> {isEdit?.last_activity}</span>
                                    </div>
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Created At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.created_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Updated At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.updated_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">State:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.state || '-'}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Zip:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.zip_code || '-'}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <AssignTags id={isEdit?.id} />
                                        </li>

                                        <li className="list-group-item  pt-5">
                                            <span className=" font-weight-bold d-block mb-2">Created By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img
                                                    src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`}
                                                    className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item ">
                                            <span className=" font-weight-bold d-block mb-2">Updated By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img
                                                    src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`}
                                                    className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="alerts">Alerts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="history">History</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className="card">
                                    <div className="card-body">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="alerts">
                                                <Alerts isEdit={isEdit} setAlertEdit={setAlertEdit} setAlertView={setAlertView} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="history">
                                                <History isEdit={isEdit} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                        {(alertView || alertEdit) && (
                            <div className="col-md-3 pt-3">
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <AlertsEditView
                                            isEdit={isEdit}
                                            alertView={alertView}
                                            alertEdit={alertEdit}
                                            resetAll={() => {
                                                setAlertView(null)
                                                setAlertEdit(null)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Offcanvas.Body>
                {/* <Offcanvas.Header>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" onClick={close} className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header> */}
            </Offcanvas>
        </>
    );
};

export default LeadsSide;