import { newsApi } from './api'
import {FORGOT_ENDPOINT, LOGIN_ENDPOINT, VERIFY_ENDPOINT, RESET_ENDPOINT} from "./apiConstant";

export const authApi = newsApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: (credentials) => ({
                url: `${LOGIN_ENDPOINT}`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            // invalidatesTags: ['Auth'],
        }),
        forgot: build.mutation({
            query: (form) => ({
                url: `${FORGOT_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
        }),
        verify: build.mutation({
            query: (form) => ({
                url: `${VERIFY_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
        }),
        reset: build.mutation({
            query: (form) => ({
                url: `${RESET_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
        }),
    }),
    overrideExisting: false,
})

export const { useLoginMutation, useForgotMutation, useVerifyMutation, useResetMutation } = authApi