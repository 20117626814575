import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import DataTable from 'react-data-table-component';
import { customTableStyles, fullName, sweetAlert, sweetAlertConfirmation } from "../../utils";
import { Link, Route, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import IdentifiesView from './ViewSide';
import IdentityFilter from './Filters';
import {
    useGetIdentityQuery,
    useRemoveIdentitiesMutation,
    useRemoveIdentityMutation
} from "../../redux/services/IdentityService";
import usePermission from "../../hooks/usePermission";

const IdentifiesListPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()

    // const navigate = useNavigate()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [filterText, setFilterText] = useState('')
    const [query, setQuery] = React.useState('')
    const deferredQuery = useDeferredValue(query)

    const { data: data2, isLoading, isFetching, refetch } = useGetIdentityQuery({ deferredQuery })
    // delete
    const [removeRequest] = useRemoveIdentityMutation()
    const [removesReq] = useRemoveIdentitiesMutation()

    const data = data2?.response?.data

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }
    const handleEdit = (item) => {
        setIsEdit(item)
        setIsShow(true)
    }
    const handleAdd = () => {
        setIsEdit(null)
        setIsShow(true)
        // reset()
    }

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                setIsView(false)
                                refetch()
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    const handleChange = useCallback(({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        setSelectedRows(selectedRows?.map((item) => item?.id))
    }, []);


    const contextActions = React.useMemo(() => {
        const handleDelete = (rows) => {
            console.log('rows', rows)
            sweetAlertConfirmation()
                .then(async (result) => {
                    if (result.isConfirmed) {
                        await removesReq(rows)
                            .unwrap()
                            .then((res) => {
                                if (res?.status) {
                                    sweetAlert(res?.message, 'success')
                                    setIsView(false)
                                    refetch()
                                    setToggleCleared(pre => !pre)
                                }
                            })
                            .catch((err) => {
                                sweetAlert(err?.data?.message, 'error')
                            })
                    }
                })
        };

        return (
            <a
                aria-label="Delete"
                onClick={() => handleDelete(selectedRows)}
                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
            >
                <i className="ri-delete-bin-7-line" />
            </a>
        );
    }, [data, selectedRows, toggleCleared]);


    const columns = [
        {
            name: 'ID#',
            selector: row => row.id,
            sortable: true,
            width: '70px',
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '210px',
        },
        {
            name: 'Gender',
            selector: row => {
                return <span className='text-capitalize'>{row?.gender?.toLowerCase()}</span>
            },
            sortable: true,
            grow: 0,
        },
        {
            name: 'Address',
            selector: row => row.street_address,
            sortable: true,
            grow: 2,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            width: '130px'
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Metro Area',
            selector: row => row.metro_area,
            sortable: true,
            width: '160px',
        },
        {
            name: 'Real/Fake',
            selector: row => <span className='text-capitalize'>{row?.real_fake?.toLowerCase()}</span>,
            sortable: true,
            width: '110px',
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            width: '130px',
        },
        {
            name: 'Added By',
            // selector: row => row.addedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.added_by?.name, row?.added_by?.last_name)}</span>
            ),
            sortable: true,
            width: '110px',
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
            width: '130px',
        },
        {
            name: 'Updated By',
            // selector: row => row.updatedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.updated_by?.name, row?.updated_by?.last_name)}</span>
            ),
            sortable: true,
            width: '120px',
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className="hstack gap-1 fs-1">
                        {checkPermission('identity.view') && (
                            <Button
                                aria-label="View"
                                onClick={() => handleView(row)}
                                className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-eye-line" />
                            </Button>
                        )}
                        {checkPermission('identity.edit') && (
                            <>
                                <Link
                                    aria-label="Edit"
                                    to={`/identifies/edit/${row.id}`}
                                    target="_blank"
                                    className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                >
                                    <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                </Link>
                                <Link
                                    aria-label="Edit"
                                    to={`/identifies/edit/${row.id}`}
                                    className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-edit-line" />
                                </Link>
                            </>
                        )}
                        {checkPermission('identity.delete') && (
                            <a
                                aria-label="Delete"
                                onClick={() => handleRemove(row)}
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line" />
                            </a>
                        )}
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '180px'
        },
    ];

    useEffect(() => {
        if (state) {
            console.log('state', state)
            handleView(state)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])



    return (
        <>
            <PageHeader name="Identities List" />


            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 ">
                                    <div className="col-auto p-0 d-flex gap-1">
                                        {checkPermission('identity.store') && (
                                            <Link to="/identifies/import" className="btn btn-primary">
                                                Import Identities
                                            </Link>
                                        )}
                                        {checkPermission('identity.store') && (
                                            <Link to="/identifies/create" className="btn btn-primary">
                                                Create Identities
                                            </Link>
                                        )}
                                    </div>
                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsFilter(true)}
                                        >
                                            <i className="ri-filter-2-fill" />
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-auto p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary"
                                                id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center"
                                            style={{ height: 100 }}>
                                            <i className="ri-loader-2-fill fs-25 rotation" />
                                        </span>
                                    </>}
                                    title={<></>}
                                    contextActions={contextActions}
                                    selectableRows
                                    clearSelectedRows={toggleCleared}
                                    onSelectedRowsChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {isView && (
                <IdentifiesView
                    show={isView}
                    close={() => setIsView(false)}
                    isEdit={isEdit}
                    remove={handleRemove}
                />
            )}

            <IdentityFilter
                show={isFilter}
                close={() => setIsFilter(false)}
                setQuery={setQuery}
            />
        </>
    );
};

export default IdentifiesListPage;
