import React, { useState } from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import { Badge, Tab, Nav, Table, ListGroup } from 'react-bootstrap';
import SearchSelect from '../../components/SearchSelect';
import {fullName} from "../../utils";
const TeamViewSide = ({ show, close, isEdit }) => {
    const [activeTab, setActiveTab] = useState('alerts');
    const alertData = [
        {
            name: 'Product A',
            type: 'Type A',
            createdAt: '2024-03-09T12:00:00',
        },
        {
            name: 'Product B',
            type: 'Type B',
            createdAt: '2024-03-08T10:30:00',
        },
        {
            name: 'Product C',
            type: 'Type C',
            createdAt: '2024-03-07T15:45:00',
        },
        {
            name: 'Product D',
            type: 'Type D',
            createdAt: '2024-03-06T11:20:00',
        },
        {
            name: 'Product E',
            type: 'Type E',
            createdAt: '2024-03-05T09:10:00',
        },
        {
            name: 'Product F',
            type: 'Type F',
            createdAt: '2024-03-04T14:30:00',
        },
        {
            name: 'Product G',
            type: 'Type G',
            createdAt: '2024-03-03T08:45:00',
        },
        {
            name: 'Product H',
            type: 'Type H',
            createdAt: '2024-03-02T10:00:00',
        },
        {
            name: 'Product I',
            type: 'Type I',
            createdAt: '2024-03-01T13:20:00',
        },
        {
            name: 'Product J',
            type: 'Type J',
            createdAt: '2024-02-29T09:30:00',
        },
    ];
    return (
        <>
            <Offcanvas show={show} className="canvas-lg bg-light" onHide={close} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        Team Details
                    </Offcanvas.Title>
                    <button className="btn-close" onClick={close}><i className="ri-close-line"></i></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card border shadow-sm">
                                <div className="card-header">
                                    <h4>ID#0001</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                            <h5 className="text-left">Team Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Team Name:</label>
                                                            <p>{isEdit?.name || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">League:</label>
                                                            <p>{isEdit?.league_relation?.name || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg">
                                                        <div className="mb-2">
                                                            <label className="form-label">Link To Account Manager:</label>
                                                            <p>{isEdit?.account_manager || '-'}</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-left">Cost Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label"># of Account</label>
                                                            <p>{isEdit?.team || '-'}</p>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label"># of Seats</label>
                                                            <p>{isEdit?.no_of_seat}</p>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Total Cost</label>
                                                            <p>{isEdit?.total_cost}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">PSL Total</label>
                                                            <p>{isEdit?.psl_paid_of}</p>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-lg-12 d-none">
                                            <div className="d-flex gap-2 mt-3">
                                                <button type="button" className="text-white px-5  btn btn-primary" data-dashlane-label="true" data-dashlane-rid="8e865cb1e4d1fe99" data-form-type="">Edit</button><button type="button" className="text-white px-5 btn btn-danger" data-dashlane-label="true" data-dashlane-rid="389c8d26ebcd8d16" data-form-type="">Delete</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div className="col-md-3" style={{position: "sticky", top: 0}}>
                            <div className="side-bar">
                                <div className="card border shadow-sm">
                                    <div className="card-header bg-info d-none">
                                        <span className='text-white'><span className=" font-weight-bold">Last Activity:</span> 7/4/2023 11:18AM</span>
                                    </div>
                                    <ul className="list-group">


                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Created At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.created_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Updated At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.updated_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Total Amounts:</span>
                                            <span className=" font-weight-semibold font-13">12,000</span>
                                        </li>
                                        
                                        
                                        <li className="list-group-item  pt-5">
                                            <span className=" font-weight-bold d-block mb-2">Created By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img
                                                    src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`}
                                                    className='user-image' alt=""/>
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item ">
                                            <span className=" font-weight-bold d-block mb-2">Updated By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img
                                                    src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`}
                                                    className='user-image' alt=""/>
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
                {/* <Offcanvas.Header>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" onClick={close} className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header> */}
            </Offcanvas >
        </>
    );
};

export default TeamViewSide;