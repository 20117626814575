import React, { useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { onInputChangeHandler } from "../../helpers";
import { useAddUserMutation } from "../../redux/services/UsersService";
import { sweetAlert } from "../../utils";
import { responseError } from "../../helpers/responseHandler";
import LoadingButton from "../../components/LoadingButton";
import InputMask from 'react-input-mask';

const UsersModal = ({ show, roles, handleClose }) => {

    const inital = {
        fname: '',
        lname: '',
        email: '',
        roles: null,
        password: ''
    };
    const [form, setForm] = useState({ ...inital });
    const [request, { isLoading }] = useAddUserMutation();

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        await request(form).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    handleClose();
                }
            })
            .catch((err) => {
                responseError(err);
            });
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={onSubmitHandler}>
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title className="h5">Create User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                First Name:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <input
                                className={"form-control"}
                                name={"fname"}
                                type="text"
                                value={form?.fname}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                required={true}
                                placeholder="Enter First Name"
                            />
                            {/*<Form.Control type="text" placeholder="Enter first name" />*/}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Last Name:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <input
                                className={"form-control"}
                                name={"lname"}
                                type="text"
                                value={form?.lname}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                required={true}
                                placeholder="Enter Last Name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Email:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <input
                                className={"form-control"}
                                name={"email"}
                                type="text"
                                value={form?.email}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                required={true}
                                placeholder="Enter Your Email"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Phone:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <InputMask
                                mask="(999) 999-9999"
                                placeholder="(123) 555-6789"
                                maskChar={null}
                                className={"form-control"}
                                name={"phone"}
                                type="text"
                                value={form?.phone}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                required={true}
                            // onChange={(e) => console.log('Year:', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>
                                Password:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <input
                                className={"form-control"}
                                name={"password"}
                                type="password"
                                value={form?.password}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                required={true}
                                placeholder="*******"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Gender:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <select required={true}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                className={"form-control"}
                                name="gender" id=""
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Role:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <select required={true}
                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                className={"form-control"}
                                name="roles" id=""
                            >
                                <option value="">Select Role</option>
                                {
                                    roles?.map((val, key) => (
                                        <option value={val?.id} key={key}>{val?.name}</option>
                                    ))
                                }
                            </select>
                            {/*<Form.Select>*/}
                            {/*    <option value="">User</option>*/}
                            {/*    <option value="">Admin</option>*/}
                            {/*</Form.Select>*/}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <Button type={"button"} variant="dark" onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton isLoading={isLoading} name={"Save"} />
                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    );
};

export default UsersModal;