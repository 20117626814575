import React, { useState } from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import { Badge, Tab, Nav, Table, ListGroup } from 'react-bootstrap';
import SearchSelect from '../../components/SearchSelect';
import { fullName } from "../../utils";
import { Link } from "react-router-dom";
const TicketViewSide = ({ show, close, isEdit, remove, renderStatus }) => {
    const [activeTab, setActiveTab] = useState('alerts');
    const alertData = [
        {
            name: 'Product A',
            type: 'Type A',
            createdAt: '2024-03-09T12:00:00',
        },
        {
            name: 'Product B',
            type: 'Type B',
            createdAt: '2024-03-08T10:30:00',
        },
        {
            name: 'Product C',
            type: 'Type C',
            createdAt: '2024-03-07T15:45:00',
        },
        {
            name: 'Product D',
            type: 'Type D',
            createdAt: '2024-03-06T11:20:00',
        },
        {
            name: 'Product E',
            type: 'Type E',
            createdAt: '2024-03-05T09:10:00',
        },
        {
            name: 'Product F',
            type: 'Type F',
            createdAt: '2024-03-04T14:30:00',
        },
        {
            name: 'Product G',
            type: 'Type G',
            createdAt: '2024-03-03T08:45:00',
        },
        {
            name: 'Product H',
            type: 'Type H',
            createdAt: '2024-03-02T10:00:00',
        },
        {
            name: 'Product I',
            type: 'Type I',
            createdAt: '2024-03-01T13:20:00',
        },
        {
            name: 'Product J',
            type: 'Type J',
            createdAt: '2024-02-29T09:30:00',
        },
    ];
    return (
        <>
            <Offcanvas show={show} className="canvas-lg bg-light" onHide={close} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        Ticket Details
                    </Offcanvas.Title>
                    <button className="btn-close" onClick={close}><i className="ri-close-line"></i></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card border shadow-sm">
                                <div className="card-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <div class="col-md-6">
                                            <h4>ID#{isEdit?.id?.toString()?.padStart(4, '0')}</h4>
                                        </div>
                                        <div class="col-auto">
                                            {renderStatus()}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {/* {isEdit?.identity_relation?.name && (
                                            <div className="col-md-12">
                                                <div className="single-data mt-4">
                                                    <h5 className="text-cen3er">Team Info</h5>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-3">
                                                            <div className="mb-2">
                                                                <label className="form-label">Real/Fake:</label>
                                                                <p class={"m-0}>{isEdit?.real_fake || '-'}</p>
                                                            </div>
                                                        </div>
                                                        {isEdit?.real_fake === 'fake' && (
                                                            <>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Spoof
                                                                            Number:</label>
                                                                        <p class={"m-0}>{isEdit?.spoof_number || '-'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Spoof
                                                                            Address:</label>
                                                                        <p class={"m-0}>{isEdit?.spoof_address || '-'}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}

                                        <div className="col-md-12">
                                            <div className="single-data">
                                                <h5 className="text-left">Ticket Info</h5>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Season Ticket year:</label>
                                                            <p class={"m-0"}>{isEdit?.season_ticket_year || '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Account #</label>
                                                            <p class={"m-0"}>{isEdit?.account || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Ticket Cost:</label>
                                                            <p class={"m-0"}>{isEdit?.ticket_cost ? `$${isEdit?.ticket_cost}` : '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Cost per Seat:</label>
                                                            <p class={"m-0"}>{isEdit?.cost_per_game ? `$${isEdit?.cost_per_game}` : '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Donation:</label>
                                                            <p class={"m-0"}>{isEdit?.donation ? `$${isEdit?.donation}` : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Paid:</label>
                                                            <p class={"m-0"}>{isEdit?.paid || '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Paid Date:</label>
                                                            <p class={"m-0"}>{isEdit?.paid_date || '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">PO Date:</label>
                                                            <p class={"m-0"}>{isEdit?.po_date || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">PSL Cost:</label>
                                                            <p class={"m-0"}>{isEdit?.psl_cost || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">PSL Paid:</label>
                                                            <p class={"m-0"}>{isEdit?.psl_paid || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">PSL Paid Date:</label>
                                                            <p class={"m-0"}>{isEdit?.psl_paid_date || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="col-md-12">
                                            <div className="single-data">
                                                <h5 className="text-left">Seating Info</h5>
                                                <div className="row">

                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Section:</label>
                                                            <p class={"m-0"}>{isEdit?.section || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Row:</label>
                                                            <p class={"m-0"}>{isEdit?.row || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label">Seat #:</label>
                                                            <p class={"m-0"}>{isEdit?.seat || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label"># of Seats:</label>
                                                            <p class={"m-0"}>{isEdit?.no_of_seat || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="mb-0">
                                                            <label className="form-label"># of Games:</label>
                                                            <p class={"m-0"}>{isEdit?.no_of_game || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {Boolean(Number(isEdit?.parking)) && (
                                            <>
                                                <hr/>
                                                <div className="col-md-12">
                                                    <div className="single-data">
                                                        <h5 className="text-left">Parking Info</h5>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label">Parking Location:</label>
                                                                    <p class={"m-0"}>{isEdit?.parking_location || '-'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label"># of Parking
                                                                        Passes:</label>
                                                                    <p class={"m-0"}>{isEdit?.no_of_parking_passes || '-'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label">Parking Costs:</label>
                                                                    <p class={"m-0"}>{isEdit?.parking_cost ? `$ ${isEdit?.parking_cost}` : '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label">Parking PO:</label>
                                                                    <p class={"m-0"}>{isEdit?.parking_po || '-'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label">Parking PO Date:</label>
                                                                    <p class={"m-0"}>{isEdit?.parking_po_date || '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="col-lg-12">
                                            <div className="d-flex gap-2 mt-3">
                                                <Link to={`/edit-ticket/${isEdit.id}`}
                                                    className="text-white px-5  btn btn-primary"
                                                    data-dashlane-label="true" data-dashlane-rid="8e865cb1e4d1fe99"
                                                    data-form-type="">Edit</Link>
                                                <button onClick={() => remove(isEdit)} type="button"
                                                    className="text-white px-5 btn btn-danger"
                                                    data-dashlane-label="true" data-dashlane-rid="389c8d26ebcd8d16"
                                                    data-form-type="">Delete
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3" style={{ position: "sticky", top: 0 }}>
                            <div className="side-bar">
                                <div className="card border shadow-sm">
                                    <div className="card-header bg-info d-none">
                                        <span className='text-white'>
                                            <span className=" font-weight-bold">
                                                Last Activity:
                                            </span>
                                            7/4/2023 11:18AM
                                        </span>
                                    </div>
                                    <ul className="list-group">


                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Created At:</span>
                                            <span className=" font-weight-semibold font-13 text-capitalize">
                                                {isEdit?.created_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Updated At:</span>
                                            <span className=" font-weight-semibold font-13 text-capitalize">
                                                {isEdit?.updated_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Team:</span>
                                            <span className=" font-weight-semibold font-13 text-capitalize">
                                                {isEdit?.team_relation?.name || '-'}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Identity:</span>
                                            <span className=" font-weight-semibold font-13 text-capitalize">
                                                {isEdit?.identity_relation?.name || '-'} ({isEdit?.real_fake || '-'})
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Ticket Type:</span>
                                            <span className=" font-weight-semibold font-13 text-capitalize">
                                                {isEdit?.ticket_type_relation?.title || '-'}
                                            </span>
                                        </li>
                                        {isEdit?.real_fake === 'fake' && (
                                            <>
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span className=" text-muted">Spoof Number:</span>
                                                    <span className=" font-weight-semibold font-13 text-capitalize">
                                                        {isEdit?.spoof_number || '-'}
                                                    </span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span className=" text-muted">Spoof Address:</span>
                                                    <span className=" font-weight-semibold font-13 text-capitalize">
                                                        {isEdit?.spoof_address || '-'}
                                                    </span>
                                                </li>
                                            </>
                                        )}

                                        <li className="list-group-item  pt-5">
                                            <span className=" font-weight-bold d-block mb-2">Created By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`} className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item ">
                                            <span className=" font-weight-bold d-block mb-2">Updated By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`} className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
                {/* <Offcanvas.Header>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" onClick={close} className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header> */}
            </Offcanvas >
        </>
    );
};

export default TicketViewSide;