import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import {Accordion, Form} from "react-bootstrap";
import {useEditRoleMutation, useViewRoleQuery} from "../../redux/services/RolesService";
import {sweetAlert} from "../../utils";
import {responseError} from "../../helpers/responseHandler";
import {onInputChangeHandler} from "../../helpers";
import {useGetLinksQuery} from "../../redux/services/RoleLinkService";
import LoadingButton from "../../components/LoadingButton";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";

const EditRolePage = () => {

    const navigate = useNavigate();
    const param = useParams();

    const inital = {
        name: '',
        links: []
    };
    const [form, setForm] = useState({...inital});
    const {data : links, error : linksError , isLoading : linkLoading, isSuccess : linkSuccess } = useGetLinksQuery();
    const { data , error, refetch, isLoading, isSuccess } = useViewRoleQuery(param?.id);
    const [editRequest, {isLoading: editLoading}] = useEditRoleMutation();

    const addRoleHandler = (e) => {
        const value = parseInt(e.target.value);
        if (!form?.links?.find((id) => id === value) && e.target.checked) {
            setForm((prevState) => {
                return {
                    ...prevState,
                    links: [...prevState?.links, value]
                }
            });
        } else {
            setForm((prevState) => {
                return {
                    ...prevState,
                    links: [...prevState?.links?.filter((val => val !== value))]
                }
            });
        }
    }

    const groups = () => {
        return links?.response?.data?.filter(
            (value, index, self) => index === self.findIndex((selfIndex) => {
                return value?.parent_slug === selfIndex?.parent_slug
            })
        ) || [];
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        // console.log("event",event);
        await editRequest(form).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    // setForm({...inital});
                    navigate('/roles');
                    // handleClose()
                }
            })
            .catch((err) => {
                // sweetAlert(err?.data?.message, 'error')
                responseError(err);
            })
    }

    console.log(form);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(()=>{
        if(isLoading === false && isSuccess === true){
            if(!data?.response?.data) navigate('/roles');

            setForm((prevState) => ({
                id    : param?.id,
                name  : data?.response?.data?.name || '',
                links : data?.response?.data?.routes?.map((val)=> parseInt(val?.link_id) ) || []
            }));
        }
    },[data])

    return (
        <>
            <PageHeader name="Edit Role"/>

            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <Form onSubmit={onSubmit}>
                            <div className="card custom-card">
                                <div className="card-header w-100">
                                    <div className="row w-100 m-0 justify-content-between">
                                        <div className="col-auto p-0 d-flex gap-1">
                                            <Link
                                                to="/roles"
                                                className="btn btn-dark"
                                            >
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="col-auto p-0 d-flex gap-1">
                                            {/*<button*/}
                                            {/*    type="submit"*/}
                                            {/*    className="btn btn-primary"*/}
                                            {/*>*/}
                                            {/*    Save*/}
                                            {/*</button>*/}
                                            <LoadingButton isLoading={editLoading} name={"Save Changes"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {linkLoading ? (
                                        <>
                                            <PlaceholderLoading />
                                        </>
                                    ) : (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Role Name:</Form.Label>
                                                <input
                                                    className={"form-control"}
                                                    name={"name"}
                                                    type="text"
                                                    value={form?.name}
                                                    onChange={(e) => onInputChangeHandler(e, setForm)}
                                                    required={true}
                                                    placeholder="Enter Role Name"
                                                />
                                            </Form.Group>

                                            <Form.Label>Select Permission:</Form.Label>
                                            <Accordion defaultActiveKey="0">
                                                {
                                                    groups()?.map((val, key) => (
                                                        <React.Fragment key={key}>
                                                            <Accordion.Item eventKey={key}>
                                                                <Accordion.Header>{val?.parent_slug}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {
                                                                        links?.response?.data
                                                                            ?.filter(value => value?.parent_slug === val?.parent_slug)
                                                                            ?.map((childValue, childKey) => (
                                                                                <div key={`${key}${childKey}`} className="mb-3">
                                                                                    <Form.Check // prettier-ignore
                                                                                        type="checkbox"
                                                                                        onClick={addRoleHandler}
                                                                                        value={childValue?.id}
                                                                                        defaultChecked={(form?.links?.find(id => parseInt(id) === parseInt(childValue?.id))) ? true : false}
                                                                                        checked={(form?.links?.find(id => parseInt(id) === parseInt(childValue?.id))) ? true : false}
                                                                                        data-id={(form?.links?.find(id => parseInt(id) === parseInt(childValue?.id))) ? true : false}
                                                                                        id={`default-${childValue?.id}`}
                                                                                        label={`${childValue?.label}`}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Accordion>
                                        </>
                                    )}


                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditRolePage;