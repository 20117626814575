import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import TextEditor from "../../components/TextEditor";

const ActivityModal = ({show, handleClose}) => {
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Task Type:</Form.Label>
                                <Form.Select>
                                    <option value="">Please Select</option>
                                    <option value="">Email</option>
                                    <option value="">Sms</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Send On Day:</Form.Label>
                                <Form.Control type="number" placeholder="Enter Send on Day" />
                            </Form.Group>
                        </div>
                        <div className="col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Subject:</Form.Label>
                                <Form.Control type="text" placeholder="Enter Subject" />
                            </Form.Group>
                        </div>
                        <div className="col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Body Text:</Form.Label>
                                <TextEditor />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ActivityModal;