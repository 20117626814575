import {newsApi} from './api'
import {
    ADD_Teams_ENDPOINT,
    DELETE_Teams_ENDPOINT,
    Edit_Teams_ENDPOINT,
    GET_Teams_ENDPOINT
} from "./apiConstant";

export const TeamsService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getTeams: build.query({
            query: (data) => ({
                url: `${GET_Teams_ENDPOINT}${data?.deferredQuery ? data?.deferredQuery : '' }`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addTeams: build.mutation({
            query: (form) => ({
                url: `${ADD_Teams_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editTeams: build.mutation({
            query: (form) => ({
                url: `${Edit_Teams_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeTeams: build.mutation({
            query: (id) => ({
                url: `${DELETE_Teams_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetTeamsQuery,
    useAddTeamsMutation,
    useEditTeamsMutation,
    useRemoveTeamsMutation
} = TeamsService