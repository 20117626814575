import React, {useState} from 'react';
import DataTable from "react-data-table-component";
import {customTableStyles} from "../../../utils";
import DocumentModal from "./DocumentModal";
import {Button} from "react-bootstrap";

const Documents = () => {
    const [isShow, setIsShow] = useState(false)
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-download-fill"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-edit-line"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line"/>
                            </a>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const data = [
        {
            id: 1,
            name: 'Beetlejuice',
            email: 'sha@gmail.com',
            mobile: '+923133455',
            city: 'karachi',
            country: 'pakistan',
        },
        {
            id: 1,
            name: 'Beetlejuice',
            email: 'sha@gmail.com',
            mobile: '+923133455',
            city: 'karachi',
            country: 'pakistan',
        },
    ]
    return (
        <>

            <div className="card custom-card">
                <div className="card-header w-100">
                    <div className="row w-100 m-0 justify-content-between">
                        <div className="col-auto p-0 d-flex">
                            <Button className="btn btn-primary" onClick={() => setIsShow(true)}>
                                Create Document
                            </Button>
                        </div>
                        <div className="col-lg-3 col-5 p-0">
                            <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line"/>
                                            </span>
                                <input
                                    className="form-control" type="text" placeholder="Search Here..."
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customTableStyles}
                        pagination={true}
                    />
                </div>
            </div>


            <DocumentModal
                show={isShow}
                handleClose={() => setIsShow(false)}
            />
        </>
    );
};

export default Documents;