import React from 'react';
import {Button, Form, Table} from "react-bootstrap";
import {useAddAlertMutation, useGetAlertQuery} from "../../redux/services/SettingAlertService";
import {Controller, useForm} from "react-hook-form";
import {getCurrentDateTime, sweetAlert} from "../../utils";
import LoadingButton from "../../components/LoadingButton";

const Alerts = ({isEdit, setAlertView, setAlertEdit}) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    const deferredQuery = `?sharable_id=${isEdit?.id}&sharable_type=${'App\\Models\\Identity'}`

    const {data: data2, isLoading, isFetching, refetch} = useGetAlertQuery({deferredQuery})

    const [addRequest, {isLoading: addLoading}] = useAddAlertMutation()

    const data = data2?.response?.data
    const alertColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: true,

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const onSubmit = async (data) => {
        data['sharable_id'] = isEdit?.id
        data['sharable_type'] = 'lead'
        await addRequest(data).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    reset()
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter name"
                                        isInvalid={errors.name}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Alert Message:</Form.Label>
                            <Controller
                                name="message"
                                control={control}
                                defaultValue={isEdit?.message || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter alert message"
                                        isInvalid={errors.message}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={isEdit?.description || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        as="textarea" rows={3} placeholder="Enter description"
                                        isInvalid={errors.description}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Alert Type:</Form.Label>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={isEdit?.type || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Select
                                        {...field}
                                        isInvalid={errors.type}
                                    >
                                        <option value="">Please Select</option>
                                        <option value="email">Email</option>
                                        <option value="notification">Notification</option>
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date/Time:</Form.Label>
                            <Controller
                                name="date_time"
                                control={control}
                                defaultValue={isEdit?.date_time || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="datetime-local"
                                        min={getCurrentDateTime()}
                                        isInvalid={errors.date_time}
                                    />
                                )}
                            />
                        </Form.Group>
                        <LoadingButton
                            isLoading={addLoading}
                            name="Add"
                            form="lead-status"
                        />
                    </Form>
                </div>
                <div className="col-md-8">
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Created At</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.map((row, index) => (
                                <tr key={index}>
                                    <td>{row?.name}</td>
                                    <td>{row?.type}</td>
                                    <td>{row?.created_at}</td>
                                    <td>
                                        <Button
                                            aria-label="anchor"
                                            className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                                            onClick={() => {
                                                setAlertEdit(null)
                                                setAlertView(null)
                                                setTimeout(() => setAlertView(row), 0)
                                            }}
                                        >
                                            <i className="ri-eye-fill"/>
                                        </Button>
                                        <button
                                            className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light ms-2"
                                            onClick={() => {
                                                setAlertView(null)
                                                setAlertEdit(null)
                                                setTimeout(() => setAlertEdit(row), 0)
                                            }}
                                        >
                                            <i className="fa-solid fa-arrow-up-right-from-square"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Alerts;