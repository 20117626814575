import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/iconfonts/icons.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app-e29e56ca.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from "./redux";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
