
export const onInputChangeHandler = (e,setState) => {
    let { name , value } = e.target;
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onDirectChangeHandler = (name,value,setState) => {
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onImageChangeHandler = (e,setState) => {
    let { name } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {

        setState((preVal)=>{
            return {
                ...preVal,
                [name] : file
            }
        });

    };
    reader.readAsDataURL(file);
};

export const toFormData = (columns = [],form) => {
    let x = new FormData();
    columns.map((val) => {
        x.append(val, form[val]);
    })
    return x;
}

export const profilePic = (name) => {
    return `https://ui-avatars.com/api/?name=${name}&rounded=true`;
}

export const getNextMonthSameDate = () => {
  const currentDate = new Date(); // Today's date
  const nextMonth = currentDate.getMonth() + 1; // Get the next month
  const year = nextMonth > 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear(); // Handle year change if next month is January

  // Set date to 15th of the next month
  const nextMonthDate = new Date(year, nextMonth % 12, 16);

  // Format the date in "YYYY-MM-DD"
  const formattedDate = nextMonthDate.toISOString().split('T')[0];
  // console.log(formattedDate);
    return formattedDate;
}

export const systemDateChecker = () =>{
    const stringDate = getNextMonthSameDate(); //"2024-10-15";
    const dateToCompare = new Date(stringDate);
    const currentDate = new Date();
    if (currentDate < dateToCompare) {
        return true;
    } else {
        return false;
    }
}
