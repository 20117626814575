import React, { useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useAddAlertMutation, useEditAlertMutation} from "../../redux/services/SettingAlertService";
import {convertToFormData, getCurrentDate, sweetAlert} from "../../utils";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import {useAddLeagueMutation, useEditLeagueMutation} from "../../redux/services/SettingLeagueService";

const AddLeaguesModal = ({ show, handleClose, isEdit }) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    // add
    const [addRequest, {isLoading: addLoading}] = useAddLeagueMutation()
    // Edit
    const [editRequest, {isLoading: editLoading}] = useEditLeagueMutation()

    const onSubmit = async (data) => {
        console.log('as', data)
        if (isEdit) {
            const newForm = convertToFormData({id: isEdit?.id, ...data})
            await editRequest(newForm).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            const newForm = convertToFormData(data)
            await addRequest(newForm).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">{isEdit ? 'Edit' : 'Create'} League</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} id="lead-status">
                        <Form.Group className="mb-3">
                            <Form.Label>League Name:</Form.Label>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={isEdit?.name || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter league name"
                                        isInvalid={errors.name}
                                    />
                                )}
                            />
                        </Form.Group>
                        {/*<Form.Group className="mb-3">
                            <Form.Label>Country:</Form.Label>
                            <Controller
                                name="country"
                                control={control}
                                defaultValue={isEdit?.country || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter country"
                                        isInvalid={errors.country}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Season Start Date:</Form.Label>
                            <Controller
                                name="start_date"
                                control={control}
                                defaultValue={isEdit?.start_date || ''}
                                rules={{
                                    required: true,
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="date"
                                        min={getCurrentDate()}
                                        isInvalid={errors.start_date}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Season End Date:</Form.Label>
                            <Controller
                                name="end_date"
                                control={control}
                                defaultValue={isEdit?.end_date || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="date"
                                        min={getCurrentDate()}
                                        isInvalid={errors.end_date}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>No of Teams:</Form.Label>
                            <Controller
                                name="no_of_teams"
                                control={control}
                                defaultValue={isEdit?.no_of_teams || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="number" placeholder="Enter name"
                                        isInvalid={errors.no_of_teams}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Logo:</Form.Label>
                            <Controller
                                name="file"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => field.onChange(e.target.files[0])}
                                        isInvalid={errors.file}
                                    />
                                )}
                            />
                            {isEdit?.logo && (
                                <img src={isEdit?.file_url} alt="logo" height={70}/>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Website:</Form.Label>
                            <Controller
                                name="website"
                                control={control}
                                defaultValue={isEdit?.website || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="url" placeholder="Enter website URL"
                                        isInvalid={errors.website}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contact Email:</Form.Label>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={isEdit?.email || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="email" placeholder="Enter contact email"
                                        isInvalid={errors.email}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contact Phone:</Form.Label>
                            <Controller
                                name="phone"
                                control={control}
                                defaultValue={isEdit?.phone || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="tel" placeholder="Enter contact phone"
                                        isInvalid={errors.phone}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={isEdit?.description || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        as="textarea" rows={3}
                                        placeholder="Enter description"
                                        isInvalid={errors.description}
                                    />
                                )}
                            />
                        </Form.Group>*/}
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <LoadingButtonWithForm
                        isLoading={editLoading ? editLoading : addLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddLeaguesModal;