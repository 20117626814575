import React from 'react';
import {Button, Form, Offcanvas} from "react-bootstrap";
import MultiSelect from "../../components/MultiSelect";

const ClientFilter = ({show, close}) => {
    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Client Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Status:</Form.Label>
                            <MultiSelect
                                options={
                                    [
                                        { value: 'Pending', label: 'Pending' },
                                        { value: 'InProgress', label: 'InProgress' },
                                        { value: 'Complete', label: 'Complete' }
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Campaigns:</Form.Label>
                            <MultiSelect
                                options={
                                    [
                                        { value: 'Compain 1', label: 'Compain 1' },
                                        { value: 'Compain 2', label: 'Compain 2' },
                                        { value: 'Compain 3', label: 'Compain 3' }
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Gender:</Form.Label>
                            <Form.Select>
                                <option value="">Please Select</option>
                                <option value="">Male</option>
                                <option value="">Female</option>
                                <option value="">Others</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Created At:</Form.Label>
                            <div className="row g-1">
                                <div className="col">
                                    <Form.Control type="date" />
                                </div>
                                <div className="col">
                                    <Form.Control type="date" />
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default ClientFilter;