import React, {useDeferredValue, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useGetSearchQuery} from "../redux/services/SearchService";
import {Link, useNavigate} from "react-router-dom";

const NotesAddModal = ({show, handleClose}) => {
    const navigate = useNavigate()
    const [link, setLink] = useState('')
    const [search, setSearch] = useState('')
    const query = useDeferredValue(search)

    const {data: data2} = useGetSearchQuery(query)
    const data = data2?.response?.data





    const renderList = (item, link) => {
        return (
            <ul key={item?.id} className="list-group">
                <li className="list-group-item mb-1">
                    <Link
                        to={link}
                        state={item}
                        onClick={() => {
                            navigate(`${link}`, {state: item?.id})
                            handleClose()
                        }}
                        rel="noreferrer"
                        className="text-capitalize"
                    >
                        <span className="text-uppercase">
                            {item?.title || item?.name}
                        </span>
                    </Link>
                </li>
            </ul>
        )
    }

    const renderCheckData = (key, value, link = '') => {
        return (
            <>
                {value?.length > 0 && (
                    <div className="w-100 bg-white border border-top-0 rounded-bottom">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a
                                    rel="noreferrer"
                                    className="text-capitalize d-block pb-2"
                                >
                                    Search Result Show on
                                    &nbsp;
                                    <span className="text-uppercase">
                                        {key}
                                    </span>
                                </a>

                                {value?.map((item, index) => (
                                    renderList(item, link)
                                ))}
                            </li>
                        </ul>
                    </div>
                )}
            </>
        )
    }

    const renderData = (key, value) => {
        if (key == 'teams' && value?.length > 0) {
            return renderCheckData(key, value, '/teams')
        } else if (key == 'users' && value?.length > 0) {
            return renderCheckData(key, value, '/users')
        } else if (key == 'identities' && value?.length > 0) {
            return renderCheckData(key, value, '/identifies/list')
        } else if (key == 'leads' && value?.length > 0) {
            return renderCheckData(key, value, '/lead/list')
        } else if (key == 'tasks' && value?.length > 0) {
            return renderCheckData(key, value, '/tasks')
        } else if (key == 'notes' && value?.length > 0) {
            return renderCheckData(key, value, '/notes')
        } else if (key == 'documents' && value?.length > 0) {
            return renderCheckData(key, value, '/documents')
        } else if (key == 'alerts' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/alerts')
        } else if (key == 'tagging' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/tagging')
        } else if (key == 'account_manager' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/account-manager')
        } else if (key == 'ticket_types' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/ticket-page')
        } else if (key == 'ticket_status' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/ticket-status')
        } else if (key == 'lead_status' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/lead-status')
        } else if (key == 'leaguegs' && value?.length > 0) {
            return renderCheckData(key, value, '/setting/leagues')
        } else {
            return renderCheckData(key, value)
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Global Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <button className="btn px-3 rounded-0 border">
                        <i className="fe fe-search" aria-hidden="true"/>
                        </button>
                        <input
                            type="text"
                            className="form-control rounded-0"
                            style={{ height: 50}}
                            id="typehead"
                            placeholder="Search for results..."
                            autoComplete="off"
                            autoFocus={true}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <br/>
                    {search?.length > 1 && (
                        <>
                            {data && Object?.entries(data)?.map(([key, value]) => (
                                renderData(key, value)
                            ))}
                        </>
                    )}

                </Modal.Body>
            </Modal>
        </>
    );
};

export default NotesAddModal;