import React, {useEffect} from 'react';
import {Button, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import MultiSelect from "../../components/MultiSelect";
import {useGetTaggingQuery} from "../../redux/services/SettingTaggingService";
import {
    useAddAssignTagMutation,
    useGetAssignTagQuery,
    useViewAssignTagQuery
} from "../../redux/services/AssignTagService";
import {sweetAlert} from "../../utils";
import {parse} from "uuid";

const AssignTags = ({id}) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    const deferredQuery = `?sharable_id=${id}&sharable_type=${'App\\Models\\Identity'}`

    const {data: taggingData, isFetching: isFetching2} = useGetTaggingQuery({deferredQuery: ''})
    const {data: defaultData, isFetching} = useGetAssignTagQuery({deferredQuery: deferredQuery})
    const [addRequest, {isLoading}] = useAddAssignTagMutation()

    const tagging = taggingData?.response?.data
    const isEdit = defaultData?.response?.data

    console.log('isEdit==', typeof isEdit?.tag_id == "string" ? JSON.parse(isEdit?.tag_id) : isEdit?.tag_id)
    const tag2 = typeof isEdit?.tag_id == "string" ? JSON.parse(isEdit?.tag_id) : isEdit?.tag_id

    const onSubmit = async (data) => {
        const body = {
            tag_id: data?.map((item) => item?.value),
            sharable_id: id,
            sharable_type: 'identity'
        }
        await addRequest(body).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    // useEffect(() => {
        // if(isSuccess && tag2 && tagging) {
        //     setValue('tags', tagging?.map((item, index) => {
        //         return tag2?.includes(item?.id) ? {label: item?.name, value: item?.id} : null
        //     }))
        // }
    // }, [isSuccess, tag2, tagging])



    return (
        <>
            {isFetching || isFetching2 || isLoading ? (
                <Button
                    className="btn-loader justify-content-center border-0 bg-transparent"
                >
                    &nbsp;
                    <span className="loading">
                        <i className="ri-loader-2-fill fs-16 text-dark"></i>
                    </span>
                    &nbsp;
                </Button>
            ) : (
                <Form.Group className="w-100">
                    <Form.Label>Tags</Form.Label>
                    <Controller
                        name="tags"
                        control={control}
                        defaultValue={tagging?.map((item, index) => {
                            return tag2?.includes(item?.id) ? {label: item?.name, value: item?.id} : null
                        })}
                        rules={{
                            required: false
                        }}
                        render={({field}) => (
                            <MultiSelect
                                options={tagging?.map((item) => ({value: item?.id, label: item?.name}))}
                                field={field}
                                onSubmit={onSubmit}
                            />
                        )}
                    />
                </Form.Group>
            )}
        </>
    );
};

export default AssignTags;