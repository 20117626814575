import React, { useDeferredValue, useMemo, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import DataTable from 'react-data-table-component';
import { customTableStyles, fullName, sweetAlert, sweetAlertConfirmation } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ArchiveFilter from "./ArchiveFilter";
import { useGetArchiveQuery, useGetLeadQuery, useRemoveLeadMutation } from "../../redux/services/LeadService";
import LeadFilter from "../LeadListPage/LeadFilter";
import LeadsSide from "../LeadListPage/LeadsViewSIde";
import MenuComponent from "../../components/MenuComponent";
import { useChangeLeadStatusMutation, useGetLeadStatusQuery } from "../../redux/services/SettingLeadStatusService";
import usePermission from "../../hooks/usePermission";


const ArchiveLeadPage = () => {
    const { checkPermission } = usePermission()
    const navigate = useNavigate()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [query, setQuery] = React.useState('')
    const deferredQuery = useDeferredValue(query)

    const { data: data2, isLoading, isFetching, refetch } = useGetArchiveQuery({ deferredQuery })
    const { data: dataStatus, isLoading: isLoadingStatus, refetch: refetchStatus } = useGetLeadStatusQuery()
    // change status
    const [updateStatus, { isLoading: isLoading1 }] = useChangeLeadStatusMutation()
    // delete
    const [removeRequest] = useRemoveLeadMutation()

    const data = data2?.response?.data
    const leadStatus = dataStatus?.response?.data

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }
    const handleEdit = (item) => {
        setIsEdit(item)
        setIsShow(true)
    }
    const handleAdd = () => {
        setIsEdit(null)
        setIsShow(true)
        // reset()
    }

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                setIsView(false)
                                refetch()
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    const columns = [
        {
            name: 'Name ',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Gender ',
            selector: row => row?.gender?.toLowerCase(),
            sortable: true,
        },
        {
            name: 'Assign To',
            selector: row => row?.assign_to?.name,
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <>
                    <MenuComponent
                        data={leadStatus}
                        selectedData={row?.status?.title ? row?.status?.title : 'Select Status'}
                        selectdId={row?.status?.id}
                        color={row?.status?.color}
                        update={updateStatus}
                        title={'Select Lead Status'}
                        isLoading={isLoading1}
                        id={row?.id}
                        updateKey={'status'}
                        refetch={refetch}
                    />
                </>
            ),
            allowOverflow: true,
            sortable: true,
            width: '180px',
        },
        {
            name: 'Added By',
            // selector: row => row.addedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.added_by?.name, row?.added_by?.last_name)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Updated By',
            // selector: row => row.updatedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.updated_by?.name, row?.updated_by?.last_name)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <Button
                                aria-label="anchor"
                                className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                                onClick={() => handleView(row)}
                            >
                                <i className="ri-eye-fill" />
                            </Button>
                            <Link
                                to={`/lead/edit/${row.id}`}
                                className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-edit-line" />
                            </Link>
                            <a
                                aria-label="anchor"
                                onClick={() => handleRemove(row)}
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line" />
                            </a>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const filteredData = useMemo(() => {
        return data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])

    return (
        <>
            <PageHeader name="Archive List" />


            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-auto p-0 d-flex gap-1">
                                        {checkPermission('lead.upload-csv') && (
                                            <Link to="/lead/import" className="btn btn-primary">
                                                Import Lead
                                            </Link>
                                        )}
                                        {checkPermission('lead.store') && (
                                            <Link to="/lead/create" className="btn btn-primary">
                                                Create Lead
                                            </Link>
                                        )}
                                        {checkPermission('lead.get') && (
                                            <Link to="/lead/list" className="btn btn-primary">
                                                Leads
                                            </Link>
                                        )}
                                        {(checkPermission('lead.get-archive') || checkPermission('lead.get')) && (
                                            <Link to="/lead/pipeline" className="btn btn-primary">
                                                Pipeline
                                            </Link>
                                        )}
                                    </div>
                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsFilter(true)}
                                        >
                                            <i className="ri-filter-2-fill" />
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-5 p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary"
                                                id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center"
                                            style={{ height: 100 }}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LeadFilter
                show={isFilter}
                close={() => setIsFilter(false)}
            />

            {isView && (
                <LeadsSide
                    show={isView}
                    close={() => setIsView(false)}
                    isEdit={isEdit}
                    remove={handleRemove}
                />
            )}
        </>
    );
};

export default ArchiveLeadPage;