import React from 'react';
import {Link} from "react-router-dom";

const SignUpPage = () => {
    return (
        <>
            <div className="page login-img d-flex">
                {/* CONTAINER OPEN */}
                <div className="d-none">
                    <div className="text-center">
                        <a href="index.html">
                            <img
                                src="build/assets/images/brand/desktop-dark.png"
                                className="header-brand-img m-0"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row mt-4 justify-content-center mx-0">
                        <div className="col-xl-4 col-lg-6">
                            <div className="card shadow-none">
                                <div className="card-body p-sm-6">
                                    <div className="text-center mb-4">
                                        <h4 className="mb-1">Sign Up</h4>
                                        <p>Sign up to your account to continue.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Full Name<span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className="form-control ms-0"
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Email<span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className="form-control ms-0"
                                                    type="email"
                                                    placeholder="Enter your Email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Create a Password<span className="text-danger ms-1">*</span>
                                                </label>
                                                <div className="input-group has-validation">
                                                    <input
                                                        type="password"
                                                        className="form-control ms-0"
                                                        placeholder="Create a Password"
                                                        id="signup-password"
                                                        required=""
                                                    />
                                                    <button
                                                        className="btn btn-light d-none"
                                                        onClick="createpassword('signup-password',this)"
                                                        type="button"
                                                        id="button-addon2"
                                                    >
                                                        <i className="ri-eye-off-line align-middle" />
                                                    </button>
                                                    <div className="invalid-feedback">
                                                        Please choose a username.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Confirm Password<span className="text-danger ms-1">*</span>
                                                </label>
                                                <div className="input-group has-validation">
                                                    <input
                                                        type="password"
                                                        className="form-control ms-0"
                                                        placeholder="Confirm your Password"
                                                        id="signup-confirmpassword"
                                                        required=""
                                                    />
                                                    <button
                                                        className="btn btn-light d-none"
                                                        onClick="createpassword('signup-confirmpassword',this)"
                                                        type="button"
                                                        id="button-addon21"
                                                    >
                                                        <i className="ri-eye-off-line align-middle" />
                                                    </button>
                                                    <div className="invalid-feedback">
                                                        Please choose a username.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckChecked"
                                                />
                                                <label
                                                    className="form-check-label tx-15"
                                                    htmlFor="flexCheckChecked"
                                                >
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="d-grid mb-3">
                                                <Link to="/" className="btn btn-primary">
                                                    {" "}
                                                    Create an Account
                                                </Link>
                                            </div>
                                            <div className="text-center">
                                                <p className="mb-0 tx-14">
                                                    Already have an account ?
                                                    <Link
                                                        to="/sign-in"
                                                        className="tx-primary ms-1 text-decoration-underline"
                                                    >
                                                        Login
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center mt-3 mb-2 d-none">Signup with</p>
                                    <div className="d-flex justify-content-center d-none">
                                        <div className="btn-list">
                                            <button
                                                className="btn btn-icon bg-primary-transparent rounded-pill border-0"
                                                type="button"
                                            >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook" />
                  </span>
                                            </button>
                                            <button
                                                className="btn btn-icon bg-primary-transparent rounded-pill border-0"
                                                type="button"
                                            >
                  <span className="btn-inner--icon">
                    <i className="fa fa-google" />
                  </span>
                                            </button>
                                            <button
                                                className="btn btn-icon bg-primary-transparent rounded-pill border-0"
                                                type="button"
                                            >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                                            </button>
                                            <button
                                                className="btn btn-icon bg-primary-transparent rounded-pill border-0"
                                                type="button"
                                            >
                  <span className="btn-inner--icon">
                    <i className="fa fa-linkedin" />
                  </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 d-none" />
                    </div>
                </div>
                {/* CONTAINER CLOSED */}
            </div>

        </>
    );
};

export default SignUpPage;