import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";

const NotesModal = ({show, handleClose}) => {
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <textarea
                                className="form-control"
                                name="example-textarea-input"
                                rows={4}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NotesModal;