import React from 'react';
import {Link} from "react-router-dom";
import usePermission from "../../hooks/usePermission";

const SettingButtons = () => {

    const {checkPermission} = usePermission()

    return (
        <div class="card custom-card">
            <div class="card-header w-100">
                <div class="row w-100 m-0 justify-content-center">
                    <div class="col-auto px-1">
                        {checkPermission('alert.get') && (
                            <Link to="/setting/alerts" className="btn btn-primary">
                                Alerts
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                        {checkPermission('tagging.get') && (
                            <Link to="/setting/tagging" className="btn btn-primary">
                                Tagging
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                        {checkPermission('account-manager.get') && (
                            <Link to="/setting/account-manager" className="btn btn-primary">
                                Account Manager
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                        {checkPermission('ticket-type.get') && (
                            <Link to="/setting/ticket-page" className="btn btn-primary">
                                Ticket Type
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                        {checkPermission('ticket-status.get') && (
                            <Link to="/setting/ticket-status" className="btn btn-primary">
                                Ticket Status
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                        {checkPermission('lead-status.get') && (
                            <Link to="/setting/lead-status" className="btn btn-primary">
                                Lead Status
                            </Link>
                        )}
                    </div>
                    <div class="col-auto px-1">
                    {checkPermission('league.get') && (
                        <Link to="/setting/leagues" className="btn btn-primary">
                            League
                        </Link>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingButtons;