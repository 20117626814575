import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";

const TaskModal = ({show, handleClose}) => {
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Subject :</Form.Label>
                                <Form.Control />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Hourly Rate:</Form.Label>
                                <Form.Control />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Start Date:</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Due Date:</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </div>

                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Priority:</Form.Label>
                                <Form.Select>
                                    <option value="" >Please Select</option>
                                    <option value="">Low</option>
                                    <option value="">Medium</option>
                                    <option value="">High</option>
                                    <option value="">Urgent</option>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Status:</Form.Label>
                                <Form.Select>
                                    <option value="" >Please Select</option>
                                    <option value="">Pending</option>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        <div className="col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Task Description:</Form.Label>
                                <textarea
                                    className="form-control"
                                    name="example-textarea-input"
                                    rows={4}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TaskModal;