import React from 'react';

const PageHeader = ({name}) => {
    return (
        <>
            {/* PAGE-HEADER */}
            <div className="page-header d-flex align-items-center justify-content-between border-bottom mb-4">
                <h1 className="page-title">{name}</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">Admin</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {name}
                        </li>
                    </ol>
                </div>
            </div>
            {/* PAGE-HEADER END */}
        </>
    );
};

export default PageHeader;