import {newsApi} from './api'
import {
    ADD_Documents_ENDPOINT,
    DELETE_Documents_ENDPOINT,
    Edit_Documents_ENDPOINT,
    GET_Documents_ENDPOINT
} from "./apiConstant";

export const DocumentsService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getDocuments: build.query({
            query: () => ({
                url: `${GET_Documents_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addDocuments: build.mutation({
            query: (form) => ({
                url: `${ADD_Documents_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editDocuments: build.mutation({
            query: (form) => ({
                url: `${Edit_Documents_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeDocuments: build.mutation({
            query: (id) => ({
                url: `${DELETE_Documents_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetDocumentsQuery,
    useAddDocumentsMutation,
    useEditDocumentsMutation,
    useRemoveDocumentsMutation
} = DocumentsService