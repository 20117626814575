import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import {fullName} from "../../utils";

const ViewModal = ({ show, handleClose, isEdit }) => {

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5 text-center">View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Render row details */}
                    {isEdit?.logo && (
                        <h6 className='text-center'>
                            <img
                                src={isEdit?.file_url}
                                style={{width: 100}} alt=""/>
                        </h6>
                    )}
                    <div class="row">
                        <div class="col-md-12 mb-2">
                            <span style={{fontWeight: 'bold'}}>League Name</span>
                            <p>{isEdit?.name}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Country</span>
                            <p>{isEdit?.country}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>No of Teams:</span>
                            <p>{isEdit?.no_of_teams}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Season Start Date:</span>
                            <p>{isEdit?.start_date}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Season End Date:</span>
                            <p>{isEdit?.end_date}</p>
                        </div>

                        <div class="col-md-12 mb-2">
                            <span style={{fontWeight: 'bold'}}>Website:</span>
                            {isEdit?.website && (
                                <p>
                                    <a href={isEdit?.website}>{isEdit?.website}</a>
                                </p>
                            )}
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Contact Email:</span>
                            <p>{isEdit?.email}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Contact Phone:</span>
                            <p>{isEdit?.phone}</p>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span style={{fontWeight: 'bold'}}>Description:</span>
                            <p>{isEdit?.description}</p>
                        </div>

                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Added By</span>
                            <p>
                                <span className='badge bg-warning'>
                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated By</span>
                            <p>
                                <span className='badge bg-warning'>
                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Created At</span>
                            <p>
                                <span className='badge bg-info'>
                                    {isEdit?.created_at}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated At</span>
                            <p>
                                <span className='badge bg-info'>
                                    {isEdit?.updated_at}
                                </span>
                            </p>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewModal;