import React, { useDeferredValue, useEffect, useMemo, useCallback, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customTableStyles, sweetAlert, sweetAlertConfirmation } from "../../utils";
// import AlertCreateModal from './Create';
// import AlertEditModal from './Edit';
import ViewModal from './ViewModal';
import { Button } from "react-bootstrap";
import TicketFilter from './TicketFIlter';
import {
    useChangeTicketStatusMutation,
    useGetTicketsQuery,
    useRemoveAllTicketsMutation,
    useRemoveTicketsMutation
} from "../../redux/services/TicketsService";
import TicketViewSide from './TicketSideView';
import usePermission from "../../hooks/usePermission";
import MenuComponent from "../../components/MenuComponent";
import { useChangeLeadStatusMutation, useGetLeadStatusQuery } from "../../redux/services/SettingLeadStatusService";
import { useGetTicketStatusQuery } from "../../redux/services/SettingTicketStatusService";

const TicketsPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [query, setQuery] = React.useState('')
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const deferredQuery = useDeferredValue(query)


    const { data: data2, isLoading, isFetching, refetch } = useGetTicketsQuery({ deferredQuery })
    const { data: dataStatus, isLoading: isLoadingStatus, refetch: refetchStatus } = useGetTicketStatusQuery()
    // delete
    const [removeRequest] = useRemoveTicketsMutation()
    const [removesReq] = useRemoveAllTicketsMutation()
    const [updateStatus, { isLoading: isLoading1 }] = useChangeTicketStatusMutation()

    const data = data2?.response?.data
    const ticketStatus = dataStatus?.response?.data

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }
    const handleEdit = (item) => {
        setIsEdit(item)
        setIsShow(true)
    }
    const handleAdd = () => {
        setIsEdit(null)
        setIsShow(true)
        // reset()
    }

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                setIsView(false)
                                refetch()
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    const columns = [
        {
            name: 'Paid ',
            selector: row => {
                return <span className={`badge ${row.paid?.toLowerCase() === "yes" ? 'bg-success' : 'bg-danger'}`}>{row.paid}</span>
            },
            sortable: true,
            grow: 0,
            width: '75px'
        },
        {
            name: 'POD ',
            selector: row => row.po_date,
            sortable: true,
            width: '113px'
        },
        {
            name: 'League  ',
            selector: row => {
                return <>{row.team_relation?.league_relation?.name}</>
            },
            sortable: true,
            width: '92px',
        },
        {
            name: 'Team Name ',
            selector: row => {
                return <>{row.team_relation?.name}</>
            },
            sortable: true,
            width: '200px',
        },
        {
            name: 'Email  ',
            selector: row => row.identity_relation?.email,
            sortable: true,
            width: '210px',
        },
        // {
        //     name: 'Status',
        //     cell: row => (
        //         <>
        //             <MenuComponent
        //                 data={ticketStatus}
        //                 selectedData={row?.ticket_status_relation?.title ? row?.ticket_status_relation?.title : 'Select Status'}
        //                 selectdId={row?.ticket_status_relation?.id}
        //                 color={row?.ticket_status_relation?.color}
        //                 update={updateStatus}
        //                 title={'Select Ticket Status'}
        //                 isLoading={isLoading1}
        //                 id={row?.id}
        //                 updateKey={'status'}
        //                 refetch={refetch}
        //             />
        //         </>
        //     ),
        //     allowOverflow: true,
        //     sortable: true,
        //     width: '130px',
        // },
        {
            name: 'Section',
            selector: row => row.section,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Seat #',
            selector: row => row.seat,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Row ',
            selector: row => row.row,
            sortable: true,
            grow: 0,
        },
        {
            name: 'Cost Per Seat',
            selector: row => parseFloat(row.cost_per_game).toFixed(2),
            sortable: true,
            width: '132px',
        },
        {
            name: 'Total Cost ',
            selector: row => parseFloat(row.ticket_cost).toFixed(2),
            sortable: true,
            width: '110px',
        },
        {
            name: 'Actions',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            {checkPermission('ticket.view') && (
                                <Button
                                    aria-label="View"
                                    onClick={() => handleView(row)}
                                    className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-eye-line" />
                                </Button>
                            )}


                            {checkPermission('ticket.edit') && (
                                <>
                                    <Link
                                        aria-label="Edit"
                                        to={`/edit-ticket/${row?.id}`}
                                        target="_blank"
                                        className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                    >
                                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                    </Link>
                                    <Link
                                        aria-label="Edit"
                                        to={`/edit-ticket/${row?.id}`}
                                        className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                    >
                                        <i className="ri-edit-line" />
                                    </Link>
                                </>
                            )}


                            {checkPermission('ticket.delete') && (
                                <a
                                    aria-label="Delete"
                                    onClick={() => handleRemove(row)}
                                    className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-delete-bin-7-line" />
                                </a>
                            )}

                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '180px'
        },
    ];

    const handleChange = useCallback(({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        setSelectedRows(selectedRows?.map((item) => item?.id))
    }, []);

    const contextActions = React.useMemo(() => {
        const handleDelete = (rows) => {
            console.log('rows', rows)
            sweetAlertConfirmation()
                .then(async (result) => {
                    if (result.isConfirmed) {
                        await removesReq(rows)
                            .unwrap()
                            .then((res) => {
                                if (res?.status) {
                                    sweetAlert(res?.message, 'success')
                                    setIsView(false)
                                    refetch()
                                    setToggleCleared(pre => !pre)
                                }
                            })
                            .catch((err) => {
                                sweetAlert(err?.data?.message, 'error')
                            })
                    }
                })
        };

        return (
            <a
                aria-label="Delete"
                onClick={() => handleDelete(selectedRows)}
                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
            >
                <i className="ri-delete-bin-7-line" />
            </a>
        );
    }, [data, selectedRows, toggleCleared]);

    useEffect(() => {
        if (state) {
            console.log('state', state)
            handleView(state)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return data?.tickets?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])



    return (
        <>
            <PageHeader name="Tickets" />
            <div className="main-container container-fluid">
                {" "}
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0">Total Cost</p>
                                        <span className="fs-5">
                                            {data?.header?.total_cost ? parseFloat(data?.header?.total_cost)?.toFixed(2) : 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-primary-transparent text-primary">
                                            <i className="fe  fs-18">$</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Seats</p>
                                        <span className="fs-5">
                                            {parseInt(data?.header?.no_of_seat) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-secondary-transparent text-secondary">
                                            <i className="fe fe-package fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Accounts</p>
                                        <span className="fs-5">
                                            {parseInt(data?.header?.no_of_account) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-warning-transparent text-warning">
                                            <i className="fe fe-user fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0">

                                    <div className="col-auto p-0 d-flex">
                                        {checkPermission('identity.store') && (
                                            <Link to="/create-ticket" className="btn btn-primary me-2">
                                                Create Ticket
                                            </Link>
                                        )}
                                        {checkPermission('identity.store') && (
                                            <Link to="/import-ticket" className="btn btn-primary">
                                                Import Ticket
                                            </Link>
                                        )}
                                    </div>


                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsFilter(true)}
                                        >
                                            <i className="ri-filter-2-fill" />
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-5 p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary"
                                                id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">


                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center" style={{ height: 100 }}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                    title={<></>}
                                    contextActions={contextActions}
                                    selectableRows
                                    clearSelectedRows={toggleCleared}
                                    onSelectedRowsChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <AlertCreateModal
                show={isShow}
                handleClose={() => setIsShow(false)}
            />
            <AlertEditModal
                show={isEdit}
                handleClose={() => setIsEdit(false)}
            />*/}
            {isView && (
                <TicketViewSide
                    show={isView}
                    close={() => setIsView(false)}
                    isEdit={isEdit}
                    remove={handleRemove}
                    renderStatus={() => {
                        return (
                            <MenuComponent
                                data={ticketStatus}
                                selectedData={isEdit?.ticket_status_relation?.title ? isEdit?.ticket_status_relation?.title : 'Select Status'}
                                selectdId={isEdit?.ticket_status_relation?.id}
                                color={isEdit?.ticket_status_relation?.color}
                                update={updateStatus}
                                title={'Select Ticket Status'}
                                isLoading={isLoading1}
                                id={isEdit?.id}
                                updateKey={'status'}
                                refetch={() => {
                                    refetch()
                                    setIsView(false)
                                }}
                            />
                        )
                    }}
                />
            )}
            <TicketFilter
                show={isFilter}
                close={() => setIsFilter(false)}
                setQuery={setQuery}
            />
        </>
    );
};

export default TicketsPage;