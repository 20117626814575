import React from 'react';
import {Button, Table} from "react-bootstrap";
import {useIdentityHistoryQuery} from "../../redux/services/IdentityService";
import {Link} from "react-router-dom";

const History = ({isEdit}) => {
    const {data: data2} = useIdentityHistoryQuery({id: isEdit?.id});
    const data = data2?.response?.data

    return (
        <>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>User</th>
                    <th>Message</th>
                </tr>
                </thead>
                <tbody>
                {data?.length > 0 ? (
                    data?.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.created_at}</td>
                            <td>{item?.user?.name}</td>
                            <td>{item?.description}</td>

                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={3} className="text-center">No history found</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </>
    );
};

export default History;