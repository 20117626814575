import {newsApi} from './api'
import {
    ADD_AssignTag_ENDPOINT,
    DELETE_AssignTag_ENDPOINT,
    Edit_AssignTag_ENDPOINT,
    GET_AssignTag_ENDPOINT, View_AssignTag_ENDPOINT
} from "./apiConstant";

export const AssignTagService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getAssignTag: build.query({
            query: (data) => ({
                url: `${GET_AssignTag_ENDPOINT}${data?.deferredQuery ? data?.deferredQuery : ''}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        viewAssignTag: build.query({
            query: (data) => ({
                url: `${View_AssignTag_ENDPOINT}?id=${data?.id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addAssignTag: build.mutation({
            query: (form) => ({
                url: `${ADD_AssignTag_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editAssignTag: build.mutation({
            query: (form) => ({
                url: `${Edit_AssignTag_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeAssignTag: build.mutation({
            query: (id) => ({
                url: `${DELETE_AssignTag_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetAssignTagQuery,
    useViewAssignTagQuery,
    useAddAssignTagMutation,
    useEditAssignTagMutation,
    useRemoveAssignTagMutation
} = AssignTagService