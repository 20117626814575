import {newsApi} from './api'
import {
    ADD_Alert_ENDPOINT, ADD_ROLE_ENDPOINT,
    DELETE_Alert_ENDPOINT, DELETE_ROLE_ENDPOINT,
    Edit_Alert_ENDPOINT, EDIT_ROLE_ENDPOINT,
    GET_Alert_ENDPOINT, GET_ROLE_ENDPOINT, VIEW_ROLE_ENDPOINT
} from "./apiConstant";


export const RolesService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getRole: build.query({
            query: () => ({
                url: `${GET_ROLE_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['Profile'],
        }),
        viewRole: build.query({
            query: (id) => ({
                url: `${VIEW_ROLE_ENDPOINT+id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['Profile'],
        }),
        addRole: build.mutation({
            query: (form) => ({
                url: `${ADD_ROLE_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['Profile'],
        }),
        editRole: build.mutation({
            query: (form) => ({
                url: `${EDIT_ROLE_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['Profile'],
        }),
        removeRole: build.mutation({
            query: (id) => ({
                url: `${DELETE_ROLE_ENDPOINT}/${id}`,
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['Profile'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetRoleQuery,
    useViewRoleQuery,
    useAddRoleMutation,
    useEditRoleMutation,
    useRemoveRoleMutation
} = RolesService