import {newsApi} from './api'
import {
    ADD_League_ENDPOINT,
    DELETE_League_ENDPOINT,
    Edit_League_ENDPOINT,
    GET_League_ENDPOINT
} from "./apiConstant";

export const SettingLeague = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getLeague: build.query({
            query: () => ({
                url: `${GET_League_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addLeague: build.mutation({
            query: (form) => ({
                url: `${ADD_League_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    // 'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editLeague: build.mutation({
            query: (form) => ({
                url: `${Edit_League_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    // 'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeLeague: build.mutation({
            query: (id) => ({
                url: `${DELETE_League_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetLeagueQuery,
    useAddLeagueMutation,
    useEditLeagueMutation,
    useRemoveLeagueMutation
} = SettingLeague