import React, { useEffect, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { Button, Form } from "react-bootstrap";
import SearchSelect from '../../components/SearchSelect';
import MultiSelect from '../../components/MultiSelect';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { convertToFormData, fullName, sweetAlert } from "../../utils";
import LoadingButton from "../../components/LoadingButton";
import { useAddLeadMutation, useEditLeadMutation, useViewLeadQuery } from "../../redux/services/LeadService";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";
import { useGetUserQuery } from "../../redux/services/UsersService";
import { useGetLeadStatusQuery } from "../../redux/services/SettingLeadStatusService";
import { useGetTaggingQuery } from "../../redux/services/SettingTaggingService";
import InputMask from "react-input-mask";


const CreateLeadPage = () => {
    const { pathname } = useLocation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);

    let {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm()

    const [addRequest, { isLoading: isLoadingAdd }] = useAddLeadMutation()
    const [editRequest, { isLoading: isLoadingEdit }] = useEditLeadMutation()
    const { data: data2, isLoading } = useViewLeadQuery({ id: id ? id : 0 })

    const { data: usersData } = useGetUserQuery();
    const { data: statusData } = useGetLeadStatusQuery()
    const { data: taggingData } = useGetTaggingQuery()

    const isEdit = data2?.response?.data ? data2?.response?.data : null
    const users = usersData?.response?.data
    const status = statusData?.response?.data
    const tagging = taggingData?.response?.data



    const onUpdate = async (data) => {
        data['assign_to'] = data?.assign_to?.value
        data['status'] = data?.status?.value
        data['tags'] = data?.tags?.length > 0 ? data?.tags?.map((item) => item?.value)?.join(',') : ''
        console.log('data?.status', data?.tags)
        if (id) {
            data['id'] = id
            const newForm = convertToFormData(data)
            await editRequest(newForm)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        navigate(`/lead/list`)
                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            const newForm = convertToFormData(data)
            await addRequest(newForm)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        navigate(`/lead/list`)
                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }

    const stepForm = [
        {
            id: 1,
            component: (
                <StepOne activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 2,
            component: (
                <StepTwo activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 3,
            component: (
                <StepThree activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        }
        // { id: 4, component: <StepOne activeStep={activeStep} /> },
    ];

    const checkHeader = () => {
        if (id) {
            return <PageHeader name="Edit Lead" />
        } else {
            return <PageHeader name="Create Lead" />
        }
    }

    useEffect(() => {
        if (pathname === '/lead/create') {
            reset({
                name: '',
                email: '',
                phone: '',
                gender: '',
                street_address: '',
                appt_number: '',
                city: '',
                state: '',
                zip_code: '',
                metro_area: '',
                assign_to: '',
                status: '',
                tags: '',
                note: '',
            })
            console.log('pathname', pathname)
        }
    }, [pathname]);

    if (isLoading && !isEdit) {
        return (
            <>
                {checkHeader()}
                <PlaceholderLoading />
            </>
        )
    }



    return (
        <>
            {checkHeader()}
            <div>
                {/* <Stepper
                    steps={[{ label: 'Basic Info' }, { label: 'Business Info' }, { label: 'Others Info' }]}
                    styleConfig={{
                        activeBgColor: "rgba(45, 51, 61, 0.6)",
                        activeTextColor: "#fff",
                        inactiveBgColor: "#fff",
                        inactiveTextColor: "#999",
                        completedBgColor: "rgb(45, 51, 61)",
                        completedTextColor: "#fff",
                        size: "2em",
                    }}
                    activeStep={activeStep+1}
                /> */}
                <div className="row justify-content-center">
                    <div className="col-xl-11">
                        <Form onSubmit={handleSubmit(onUpdate)} className="card custom-card">
                            <div className="card-body">
                                <h5 className="text-start">Lead Information</h5>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Name: &nbsp;
                                                {true && <span className="text-danger text-bold">*</span>}
                                            </Form.Label>
                                            <Controller
                                                name="name"
                                                control={control}
                                                defaultValue={isEdit?.name || ''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Name"
                                                        isInvalid={errors.name}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Email: &nbsp;
                                                {true && <span className="text-danger text-bold">*</span>}
                                            </Form.Label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue={isEdit?.email || ''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Email"
                                                        isInvalid={errors.email}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Phone Number: &nbsp;
                                                {true && <span className="text-danger text-bold">*</span>}
                                            </Form.Label>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                defaultValue={isEdit?.phone || ''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <InputMask
                                                            {...field}
                                                            mask="(999) 999-9999"
                                                            placeholder="(123) 555-6789"
                                                            maskChar={null}
                                                            className="form-control"
                                                        // onChange={(e) => console.log('Year:', e.target.value)}
                                                        >
                                                            {(inputProps) => <Form.Control {...inputProps} type="text" isInvalid={errors.phone} />}
                                                        </InputMask>
                                                        {/* <Form.Control
                                                            {...field}
                                                            type="text" placeholder="Enter Phone Number"
                                                            isInvalid={errors.phone}
                                                        /> */}
                                                    </>

                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Gender : &nbsp;
                                                {true && <span className="text-danger text-bold">*</span>}
                                            </Form.Label>
                                            <Controller
                                                name="gender"
                                                control={control}
                                                defaultValue={isEdit?.gender || ''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Select
                                                        {...field}
                                                        isInvalid={errors.gender}
                                                    >
                                                        <option value="">Please Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </Form.Select>
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Addresses:</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address 1" className="mb-1" />
                                            <Form.Control type="text" placeholder="Enter Address 2" className="mb-1" />
                                            <Form.Control type="text" placeholder="Enter Address 3" className="mb-1" />
                                        </Form.Group>
                                    </div> */}
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Street Address:</Form.Label>
                                            <Controller
                                                name="street_address"
                                                control={control}
                                                defaultValue={isEdit?.street_address || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Street Address"
                                                        isInvalid={errors.street_address}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Appt Number:</Form.Label>
                                            <Controller
                                                name="appt_number"
                                                control={control}
                                                defaultValue={isEdit?.appt_number || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Appt Number"
                                                        isInvalid={errors.appt_number}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>City:</Form.Label>
                                            <Controller
                                                name="city"
                                                control={control}
                                                defaultValue={isEdit?.city || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter City"
                                                        isInvalid={errors.city}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>State:</Form.Label>
                                            <Controller
                                                name="state"
                                                control={control}
                                                defaultValue={isEdit?.state || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Select
                                                        {...field}
                                                        type="text" placeholder="Enter State"
                                                        isInvalid={errors.state}
                                                    >
                                                        <option value="">Please Select</option>
                                                        <option value="alabama">Alabama</option>
                                                        <option value="alaska">Alaska</option>
                                                        <option value="arizona">Arizona</option>
                                                        <option value="arkansas">Arkansas</option>
                                                        <option value="california">California</option>
                                                        <option value="colorado">Colorado</option>
                                                        <option value="connecticut">Connecticut</option>
                                                        <option value="delaware">Delaware</option>
                                                        <option value="florida">Florida</option>
                                                        <option value="georgia">Georgia</option>
                                                        <option value="hawaii">Hawaii</option>
                                                        <option value="idaho">Idaho</option>
                                                        <option value="illinois">Illinois</option>
                                                        <option value="indiana">Indiana</option>
                                                        <option value="iowa">Iowa</option>
                                                        <option value="kansas">Kansas</option>
                                                        <option value="kentucky">Kentucky</option>
                                                        <option value="louisiana">Louisiana</option>
                                                        <option value="maine">Maine</option>
                                                        <option value="maryland">Maryland</option>
                                                        <option value="massachusetts">Massachusetts</option>
                                                        <option value="michigan">Michigan</option>
                                                        <option value="minnesota">Minnesota</option>
                                                        <option value="mississippi">Mississippi</option>
                                                        <option value="missouri">Missouri</option>
                                                        <option value="montana">Montana</option>
                                                        <option value="nebraska">Nebraska</option>
                                                        <option value="nevada">Nevada</option>
                                                        <option value="new hampshire">New Hampshire</option>
                                                        <option value="new jersey">New Jersey</option>
                                                        <option value="new mexico">New Mexico</option>
                                                        <option value="new york">New York</option>
                                                        <option value="north carolina">North Carolina</option>
                                                        <option value="north dakota">North Dakota</option>
                                                        <option value="ohio">Ohio</option>
                                                        <option value="oklahoma">Oklahoma</option>
                                                        <option value="oregon">Oregon</option>
                                                        <option value="pennsylvania">Pennsylvania</option>
                                                        <option value="rhode island">Rhode Island</option>
                                                        <option value="south carolina">South Carolina</option>
                                                        <option value="south dakota">South Dakota</option>
                                                        <option value="tennessee">Tennessee</option>
                                                        <option value="texas">Texas</option>
                                                        <option value="utah">Utah</option>
                                                        <option value="vermont">Vermont</option>
                                                        <option value="virginia">Virginia</option>
                                                        <option value="washington">Washington</option>
                                                        <option value="west virginia">West Virginia</option>
                                                        <option value="wisconsin">Wisconsin</option>
                                                        <option value="wyoming">Wyoming</option>
                                                    </Form.Select>
                                                )}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Zip Code:</Form.Label>
                                            <Controller
                                                name="zip_code"
                                                control={control}
                                                defaultValue={isEdit?.zip_code || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Zip Code"
                                                        isInvalid={errors.zip_code}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Metro Area:</Form.Label>
                                            <Controller
                                                name="metro_area"
                                                control={control}
                                                defaultValue={isEdit?.metro_area || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text" placeholder="Enter Metro Area"
                                                        isInvalid={errors.metro_area}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Assign To:</Form.Label>
                                            <Controller
                                                name="assign_to"
                                                control={control}
                                                defaultValue={isEdit?.assign_to ? { value: isEdit?.assign_to?.id, label: fullName(isEdit?.assign_to.name, isEdit?.assign_to?.last_name) } : ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <SearchSelect
                                                        options={users?.map((item) => ({ value: item.id, label: fullName(item.name, item?.last_name) }))}
                                                        field={field}
                                                        placeholder={"Enter Assign User"}
                                                    />
                                                )}
                                            />

                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Status:</Form.Label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue={isEdit?.status ? { value: isEdit?.status?.id, label: isEdit?.status.title } : ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <SearchSelect
                                                        options={status?.map((item) => ({ value: item?.id, label: item?.title }))}
                                                        field={field}
                                                        placeholder={"Enter Status"}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Tags: &nbsp;
                                                {true && <span className="text-danger text-bold">*</span>}
                                            </Form.Label>
                                            <Controller
                                                name="tags"
                                                control={control}
                                                defaultValue={
                                                    isEdit?.tags?.split(',')?.length > 0 ?
                                                        tagging?.filter((item) => isEdit?.tags?.split(',')?.includes(item?.id.toString()))
                                                            ?.map((item) => ({ label: item?.name, value: item?.id })) :
                                                        null
                                                }
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <MultiSelect
                                                        options={tagging?.map((item) => ({ value: item?.id, label: item?.name }))}
                                                        field={field}
                                                    />
                                                )}
                                            />
                                            {errors?.tags && <small className="smal text-danger">This Field Is Required</small>}
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Notes:</Form.Label>
                                            <Controller
                                                name="note"
                                                control={control}
                                                defaultValue={isEdit?.note || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea" placeholder="Enter Notes"
                                                        isInvalid={errors.note}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-lg-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gender:</Form.Label>
                                            <Form.Select>
                                                <option value="">Male</option>
                                                <option value="">Female</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div> */}
                                    <div className="col-lg-12 d-flex mb-3 gap-3 justify-content-end">
                                        <Button
                                            onClick={() => navigate('/lead/list')}
                                            variant="primary"
                                            className="text-white mt-auto"
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            isLoading={isLoadingAdd ? isLoadingAdd : isLoadingEdit}
                                            name="Save"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            {/* {stepForm[activeStep].component} */}
        </>
    );
};

export default CreateLeadPage;