import {newsApi} from './api'
import {
    ADD_Notes_ENDPOINT,
    DELETE_Notes_ENDPOINT,
    Edit_Notes_ENDPOINT,
    GET_Notes_ENDPOINT
} from "./apiConstant";

export const NotesService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getNotes: build.query({
            query: () => ({
                url: `${GET_Notes_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addNotes: build.mutation({
            query: (form) => ({
                url: `${ADD_Notes_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editNotes: build.mutation({
            query: (form) => ({
                url: `${Edit_Notes_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeNotes: build.mutation({
            query: (id) => ({
                url: `${DELETE_Notes_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetNotesQuery,
    useAddNotesMutation,
    useEditNotesMutation,
    useRemoveNotesMutation
} = NotesService