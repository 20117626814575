import React, { useState } from 'react';
import PageHeader from "../../components/PageHeader";
import { Button } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useGetCalenderQuery } from "../../redux/services/CalenderService";
import moment from "moment";
import ViewModal from "./ViewModal";
import usePermission from "../../hooks/usePermission";

const CalendarsPage = () => {
    const { checkPermission } = usePermission()
    const [isEdit, setIsEdit] = useState(null)
    const { data: data2, isLoading, isFetching, refetch } = useGetCalenderQuery()

    let data = data2?.response?.data?.alerts.concat(data2?.response?.data?.tasks)?.map((item) => {
        return {
            title: item?.title ? item?.title : item?.name,
            start: moment(item?.start_date).format('YYYY-MM-DD'),
            end: moment(item?.start_date).format('YYYY-MM-DD'),
            color: '#00A5A3',
            extraData: { // Attach extra data here
                ...item,
            }
        }
    })
    console.log('data22', data)
    const tasks = [
        {
            title: 'Event 1',
            start: '2024-04-25',
            end: '2024-04-26',
            color: '#00A5A3' // Specify the color for Event 1
        },
        {
            title: 'Event 2',
            start: '2024-03-15',
            end: '2024-03-17',
            color: '#00A5A3' // Specify the color for Event 2
        },
        {
            title: 'Event 3',
            start: '2024-03-25',
            end: '2024-03-28',
            color: '#032d4d' // Specify the color for Event 2
        },
        // Add more tasks as needed
    ];

    const handleView = (item) => {
        setIsEdit(item)
    }

    const eventContent = (arg) => {
        return (
            <>
                {true ? (
                    <div
                        className="custom-event px-2"
                        role="button"
                        // style={{ backgroundColor: arg.event.backgroundColor, borderColor: arg.event.borderColor }}
                        onClick={() => {
                            checkPermission('task.calender-view') && handleView(arg.event.extendedProps.extraData)
                        }}
                    >
                        {console.log('arg', arg.event.extendedProps.extraData)}
                        {arg.event.title}
                    </div>
                ) : (
                    <div
                        className="custom-event"
                    // style={{ backgroundColor: arg.event.backgroundColor, borderColor: arg.event.borderColor }}
                    >
                        {arg.event.title}
                    </div>
                )}
            </>

        )
    }

    return (
        <>
            <PageHeader name="Calendars" />
            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex">

                                    </div>
                                    {/* <div className="col-lg-3 col-5 p-0">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <FullCalendar
                                    defaultView="dayGridMonth"
                                    header={{
                                        left: "prev,next",
                                        center: "title",
                                        right: "dayGridMonth,timeGridWeek,timeGridDay"
                                    }}
                                    plugins={[dayGridPlugin]}
                                    events={data}
                                    eventContent={eventContent}
                                    selectable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {isEdit && (
                <ViewModal
                    show={Boolean(isEdit)}
                    handleClose={() => setIsEdit(null)}
                    isEdit={isEdit}
                />
            )}

        </>
    );
};

export default CalendarsPage;