import { newsApi } from './api'
import {
    ADD_Tickets_ENDPOINT, CHANGE_LEAD_STATUS_ENDPOINT, CSV_Lead_ENDPOINT, CSV_Tickets_ENDPOINT,
    DELETE_All_Tickets_ENDPOINT,
    DELETE_Tickets_ENDPOINT,
    Edit_Tickets_ENDPOINT,
    GET_Tickets_ENDPOINT, IMPORT_Lead_ENDPOINT, Import_Tickets_ENDPOINT, Status_Tickets_ENDPOINT, VIEW_Tickets_ENDPOINT,
} from "./apiConstant";

export const TicketsService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        csvTicket: build.mutation({
            query: (form) => ({
                url: `${CSV_Tickets_ENDPOINT}`,
                method: 'POST',
                body: form,

            }),
            invalidatesTags: ['LeadStatus'],
        }),
        importTicket: build.mutation({
            query: (form) => ({
                url: `${Import_Tickets_ENDPOINT}`,
                method: 'POST',
                body: form,
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        changeTicketStatus: build.mutation({
            query: (form) => ({
                url: `${Status_Tickets_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        getTickets: build.query({
            query: (data) => ({
                url: `${GET_Tickets_ENDPOINT}${data?.deferredQuery}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        viewTickets: build.query({
            query: (form) => ({
                url: `${VIEW_Tickets_ENDPOINT}?id=${form?.id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addTickets: build.mutation({
            query: (form) => ({
                url: `${ADD_Tickets_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editTickets: build.mutation({
            query: (form) => ({
                url: `${Edit_Tickets_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeTickets: build.mutation({
            query: (id) => ({
                url: `${DELETE_Tickets_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeAllTickets: build.mutation({
            query: (id) => ({
                url: `${DELETE_All_Tickets_ENDPOINT}`,
                method: 'POST',
                body: { ids: id },
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useCsvTicketMutation,
    useImportTicketMutation,
    useChangeTicketStatusMutation,
    useGetTicketsQuery,
    useViewTicketsQuery,
    useAddTicketsMutation,
    useEditTicketsMutation,
    useRemoveTicketsMutation,
    useRemoveAllTicketsMutation,
} = TicketsService