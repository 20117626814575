import React, { useState } from 'react';
import PageHeader from "../../components/PageHeader";
import { customTableStyles, fullName, sweetAlert, sweetAlertConfirmation } from "../../utils";
import DataTable from "react-data-table-component";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../../components/LoadingButton";
import { useCsvIdentityMutation, useImportIdentityMutation } from "../../redux/services/IdentityService";
import { useNavigate } from "react-router-dom";

const ImportClientPage = () => {
    const navigate = useNavigate()
    let {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm()

    const [data, setData] = useState([])
    const [upload, { isLoading: isLoadingUpload }] = useCsvIdentityMutation()
    const [importLead, { isLoading: isLoadingImport }] = useImportIdentityMutation()

    const onUpload = async (data) => {
        const form = new FormData()
        form.append('file', data.file)
        await upload(form)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    reset()
                    setData(e => [...e, ...res?.response?.data])
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                // err?.data?.errors
                sweetAlert(err?.data?.message, 'error')
            })
    }

    const onCreateLead = () => {
        const form = new FormData()
        data?.length > 0 && data?.map((item, i) => {
            Object.entries(item)?.forEach(([key, value]) => {
                form.append(`${key}[${i}]`, `${value ? value : ''}`)
            })
        })
        importLead(form)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    setData([])
                    navigate('/identifies/list')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                // err?.data?.errors
                sweetAlert(err?.data?.message, 'error')
            })
    }

    const handleRemove = (row) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    setData(data.filter((item) => item !== row))
                }
            })
    }

    const columns = [
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <a
                                onClick={() => handleRemove(row)}
                                aria-label="anchor"
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line" />
                            </a>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: row => fullName(row.name, row.last_name),
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Gender',
            selector: row => row?.gender?.toLowerCase(),
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => row.street_address,
            sortable: true,
        },
        {
            name: 'Metro Area',
            selector: row => row.metro_area,
            sortable: true,
        },

    ];


    return (
        <>
            <PageHeader name="Import Identity" />

            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <Form onSubmit={handleSubmit(onUpload)} className="row align-items-end">
                                <div className="col">
                                    <label className="form-label s-14">Csv File &nbsp;</label>
                                    <Controller
                                        name="file"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field }) => (
                                            <Form.Control
                                                onChange={(e) => field.onChange(e.target.files[0])}
                                                type="file"
                                                accept=".csv, application/vnd.openxmlformats-off icedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                isInvalid={errors.file}
                                            />

                                        )}
                                    />
                                </div>
                                <div className="col-lg-auto col-auto">
                                    <LoadingButton
                                        isLoading={isLoadingUpload}
                                        name="Upload CSV"
                                    />
                                </div>
                            </Form>
                            <div className='mt-2'>
                                <a href="/example2.csv" download target='_blank'>Download Sample File</a>
                            </div>
                        </div>
                    </div>

                    <div className="card custom-card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={data}
                                customStyles={customTableStyles}
                            />
                        </div>
                    </div>

                    {data?.length > 0 && (
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <Button
                                            onClick={() => {
                                                setData([])
                                                navigate('/identifies/list')
                                            }}
                                            variant="dark"
                                            className="text-white w-100 btn-loader justify-content-center"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col">
                                        <Button
                                            onClick={onCreateLead}
                                            variant="primary"
                                            className="text-white w-100 btn-loader justify-content-center"
                                        >
                                            Upload
                                            {isLoadingImport && (
                                                <>
                                                    &nbsp;
                                                    <span className="loading">
                                                        <i className="ri-loader-2-fill fs-16"></i>
                                                    </span>
                                                </>
                                            )}
                                        </Button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ImportClientPage;