import React from 'react';
import {Button} from "react-bootstrap";

const LoadingButton = ({ isLoading, name, submitting = () => {}, info='' }) => {
    return (
        <>
            <Button
                // disabled={isLoading}
                type="submit"
                variant={info ? "info" : "primary"}
                className={`btn-loader justify-content-center`}
                onClick={submitting}
            >
                {" "}
                {name}
                {isLoading && (
                    <>
                        &nbsp;
                        <span className="loading">
                            <i className="ri-loader-2-fill fs-16"></i>
                        </span>
                    </>
                )}
            </Button>
        </>
    );
};

export default LoadingButton;