import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API_BASE_URL} from "./apiConstant";
import {logout} from "../slices/authSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getState().authenticate.token
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const fetchBaseQueryWithReAuth = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        api.dispatch(logout())
    }
    console.log('result===========', result)
    return result
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const newsApi = createApi({
    baseQuery: fetchBaseQueryWithReAuth,
    tagTypes: ['Profile', 'Article'],

    endpoints: () => ({

    }),
})