import React from 'react';
import {Button} from "react-bootstrap";

const LoadingButton = ({ isLoading, name, form }) => {
    return (
        <>
            <Button
                // disabled={isLoading}
                form={form}
                type="submit"
                className="btn-loader justify-content-center"
            >
                {" "}
                {name}
                {isLoading && (
                    <>
                        &nbsp;
                        <span className="loading">
                            <i className="ri-loader-2-fill fs-16" />
                        </span>
                    </>
                )}
            </Button>
        </>
    );
};

export default LoadingButton;