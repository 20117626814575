import React, { useEffect, useState } from 'react';
import { Stepper } from 'react-form-stepper';
import PageHeader from "../../components/PageHeader";
import IdentifyInformationStep from './Steps/IdentifyInformationStep';
import AccountInformationStep from './Steps/AccountInformationStep';
import AddressInformationStep from './Steps/AddressInformation';
import CreditInformationStep from './Steps/CreditInformationStep';
import PasswordInformationStep from './Steps/PasswordInformationStep';
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAddTicketsMutation, useEditTicketsMutation, useViewTicketsQuery } from "../../redux/services/TicketsService";
import { convertToFormData, getCardNumberLength, getCurrentDate, sweetAlert } from "../../utils";
import LoadingButton from "../../components/LoadingButton";
import {
    useAddIdentityMutation,
    useEditIdentityMutation,
    useViewIdentityQuery
} from "../../redux/services/IdentityService";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";
import InputMask from "react-input-mask";

const CreateIdentifyPage = () => {
    const { pathname } = useLocation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);

    let {
        control,
        register,
        watch,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    const [addRequest, { isLoading: isLoadingAdd }] = useAddIdentityMutation()
    const [editRequest, { isLoading: isLoadingEdit }] = useEditIdentityMutation()
    const { data: data2, isLoading } = useViewIdentityQuery({ id: id ? id : 0 })

    let isEdit = data2?.response?.data ? data2?.response?.data : null


    const onUpdate = async (data) => {
        if (id) {
            data['id'] = id
            const newForm = convertToFormData(data)
            await editRequest(newForm)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        if (watch('save_and_new')) {
                            reset()
                        } else {
                            reset()
                            navigate(`/identifies/list`)
                        }

                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            const newForm = convertToFormData(data)
            await addRequest(newForm)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        if (watch('save_and_new')) {
                            reset()
                        } else {
                            reset()
                            navigate(`/identifies/list`)
                        }
                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }

    const stepForm = [
        {
            id: 1,
            component: (
                <IdentifyInformationStep activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 2,
            component: (
                <AccountInformationStep activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 2,
            component: (
                <AddressInformationStep activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 4,
            component: (
                <CreditInformationStep activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 5,
            component: (
                <PasswordInformationStep activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        // { id: 4, component: <StepOne activeStep={activeStep} /> },
    ];


    const checkHeader = () => {
        if (id) {
            return <PageHeader name="Edit Identity" />
        } else {
            return <PageHeader name="Create Identity" />
        }
    }

    useEffect(() => {
        if (pathname === '/identifies/create') {
            reset({
                name: '',
                email: '',
                phone: '',
                gender: '',
                identify_owner: '',
                id_upload: '',
                real_fake: '',
                street_address: '',
                appt_number: '',
                city: '',
                state: '',
                zip_code: '',
                metro_area: '',
                credit_type: '',
                ccv: '',
                exp: '',
                security_code: '',
                ticket_master: '',
                gmail: '',
                axs: '',
                seatgeek: '',
                mlb_ballpark: '',
                paciolan: '',
                other: '',
            })
            isEdit = null
            console.log('pathname', pathname)
        }
    }, [pathname]);

    if (isLoading && !isEdit) {
        return (
            <>
                {checkHeader()}
                <PlaceholderLoading />
            </>
        )
    }

    return (
        <>
            {checkHeader()}
            <div>
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="card custom-card">
                            <Form onSubmit={handleSubmit(onUpdate)} className="card-body">
                                <div className="single-step mt-4">
                                    <h5 className="">Identity Information</h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Name: &nbsp;
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="name"
                                                    control={control}
                                                    defaultValue={isEdit?.name || ''}
                                                    rules={{
                                                        required: true,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Name"
                                                            isInvalid={errors.name}
                                                        />
                                                    )}
                                                />
                                                {errors?.name && <small className="smal text-danger">{errors?.name.message}</small>}
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email: &nbsp;
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue={isEdit?.email || ''}
                                                    rules={{
                                                        required: true,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Email"
                                                            isInvalid={errors.email}
                                                        />
                                                    )}
                                                />
                                                {errors?.email && <small className="smal text-danger">{errors?.email.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Phone Number: &nbsp;
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    defaultValue={isEdit?.phone || ''}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            <InputMask
                                                                {...field}
                                                                mask="(999) 999-9999"
                                                                placeholder="(123) 555-6789"
                                                                maskChar={null}
                                                                className="form-control"
                                                            // onChange={(e) => console.log('Year:', e.target.value)}
                                                            >
                                                                {(inputProps) => <Form.Control {...inputProps} type="text" isInvalid={errors.phone} />}
                                                            </InputMask>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Gender: &nbsp;
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    defaultValue={isEdit?.gender || ''}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Select
                                                            {...field}
                                                            type="text" placeholder="Enter Account No"
                                                            isInvalid={errors.gender}
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </Form.Select>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Account Information</h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Identity Owner:</Form.Label>
                                                <Controller
                                                    name="identify_owner"
                                                    control={control}
                                                    defaultValue={isEdit?.identify_owner || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Identity Owner"
                                                            isInvalid={errors.identify_owner}
                                                        />
                                                    )}
                                                />
                                                {errors?.identify_owner && <small className="smal text-danger">{errors?.identify_owner.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>ID Upload:</Form.Label>
                                                <Controller
                                                    name="id_upload"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            type="file"
                                                            onChange={(e) => field.onChange(e.target.files[0])}
                                                            isInvalid={errors.id_upload}
                                                        />
                                                    )}
                                                />
                                                {isEdit?.id_upload && (
                                                    <img src={isEdit?.logo_url} alt="id_upload_image" className="img-fluid mt-2" />
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Real/Fake:</Form.Label>
                                                <Controller
                                                    name="real_fake"
                                                    control={control}
                                                    defaultValue={isEdit?.real_fake || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 10,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={10}
                                                            type="text" placeholder="Real/Fake"
                                                            isInvalid={errors.real_fake}
                                                        />
                                                    )}
                                                />
                                                {errors?.real_fake && <small className="smal text-danger">{errors?.real_fake.message}</small>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Address Information</h5>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Street Address:</Form.Label>
                                                <Controller
                                                    name="street_address"
                                                    control={control}
                                                    defaultValue={isEdit?.street_address || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Street Address"
                                                            isInvalid={errors.street_address}
                                                        />
                                                    )}
                                                />
                                                {errors?.street_address && <small className="smal text-danger">{errors?.street_address.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Appt Number:</Form.Label>
                                                <Controller
                                                    name="appt_number"
                                                    control={control}
                                                    defaultValue={isEdit?.appt_number || ''}
                                                    rules={{
                                                        required: false,
                                                        value: 64,
                                                        message: 'Text too long'
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Appt Number"
                                                            isInvalid={errors.appt_number}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>City:</Form.Label>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    defaultValue={isEdit?.city || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter City"
                                                            isInvalid={errors.city}
                                                        />
                                                    )}
                                                />
                                                {errors?.city && <small className="smal text-danger">{errors?.city.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>State:</Form.Label>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue={isEdit?.state || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Select
                                                            {...field}
                                                            type="text" placeholder="Enter State"
                                                            isInvalid={errors.state}
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="alabama">Alabama</option>
                                                            <option value="alaska">Alaska</option>
                                                            <option value="arizona">Arizona</option>
                                                            <option value="arkansas">Arkansas</option>
                                                            <option value="california">California</option>
                                                            <option value="colorado">Colorado</option>
                                                            <option value="connecticut">Connecticut</option>
                                                            <option value="delaware">Delaware</option>
                                                            <option value="florida">Florida</option>
                                                            <option value="georgia">Georgia</option>
                                                            <option value="hawaii">Hawaii</option>
                                                            <option value="idaho">Idaho</option>
                                                            <option value="illinois">Illinois</option>
                                                            <option value="indiana">Indiana</option>
                                                            <option value="iowa">Iowa</option>
                                                            <option value="kansas">Kansas</option>
                                                            <option value="kentucky">Kentucky</option>
                                                            <option value="louisiana">Louisiana</option>
                                                            <option value="maine">Maine</option>
                                                            <option value="maryland">Maryland</option>
                                                            <option value="massachusetts">Massachusetts</option>
                                                            <option value="michigan">Michigan</option>
                                                            <option value="minnesota">Minnesota</option>
                                                            <option value="mississippi">Mississippi</option>
                                                            <option value="missouri">Missouri</option>
                                                            <option value="montana">Montana</option>
                                                            <option value="nebraska">Nebraska</option>
                                                            <option value="nevada">Nevada</option>
                                                            <option value="new hampshire">New Hampshire</option>
                                                            <option value="new jersey">New Jersey</option>
                                                            <option value="new mexico">New Mexico</option>
                                                            <option value="new york">New York</option>
                                                            <option value="north carolina">North Carolina</option>
                                                            <option value="north dakota">North Dakota</option>
                                                            <option value="ohio">Ohio</option>
                                                            <option value="oklahoma">Oklahoma</option>
                                                            <option value="oregon">Oregon</option>
                                                            <option value="pennsylvania">Pennsylvania</option>
                                                            <option value="rhode island">Rhode Island</option>
                                                            <option value="south carolina">South Carolina</option>
                                                            <option value="south dakota">South Dakota</option>
                                                            <option value="tennessee">Tennessee</option>
                                                            <option value="texas">Texas</option>
                                                            <option value="utah">Utah</option>
                                                            <option value="vermont">Vermont</option>
                                                            <option value="virginia">Virginia</option>
                                                            <option value="washington">Washington</option>
                                                            <option value="west virginia">West Virginia</option>
                                                            <option value="wisconsin">Wisconsin</option>
                                                            <option value="wyoming">Wyoming</option>
                                                        </Form.Select>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Zip Code:</Form.Label>
                                                <Controller
                                                    name="zip_code"
                                                    control={control}
                                                    defaultValue={isEdit?.zip_code || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={10}
                                                            type="text" placeholder="Enter Zip Code"
                                                            isInvalid={errors.zip_code}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Metro Area:</Form.Label>
                                                <Controller
                                                    name="metro_area"
                                                    control={control}
                                                    defaultValue={isEdit?.metro_area || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Enter Metro Area"
                                                            isInvalid={errors.metro_area}
                                                        />
                                                    )}
                                                />
                                                {errors?.metro_area && <small className="smal text-danger">{errors?.metro_area.message}</small>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Credit Information</h5>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Credit Type:</Form.Label>
                                                <Controller
                                                    name="credit_type"
                                                    control={control}
                                                    defaultValue={isEdit?.credit_type || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Select
                                                            {...field}
                                                            isInvalid={errors.credit_type}
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="visa">Visa</option>
                                                            <option value="mastercard">MasterCard</option>
                                                            <option value="amex">American Express</option>
                                                            <option value="discover">Discover</option>
                                                            <option value="dinersclub">Diners Club</option>
                                                            <option value="jcb">JCB</option>
                                                            <option value="unionpay">UnionPay</option>
                                                            <option value="maestro">Maestro</option>
                                                            <option value="rupay">RuPay</option>
                                                            <option value="mir">Mir</option>
                                                            <option value="elo">Elo</option>
                                                            <option value="hipercard">Hipercard</option>
                                                        </Form.Select>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Card Number &nbsp;
                                                    {errors.ccv && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="ccv"
                                                    control={control}
                                                    defaultValue={isEdit?.ccv || ''}
                                                    rules={{
                                                        required: false,
                                                        minLength: {
                                                            value: getCardNumberLength(watch('credit_type')),
                                                            message: `Card number should not be less than ${getCardNumberLength(watch('credit_type'))} digits.`
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Card number must only contain digits',
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                placeholder="1234567890123456"
                                                                isInvalid={errors.ccv}
                                                                maxLength={getCardNumberLength(watch('credit_type'))}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.ccv && errors.ccv.message}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>EXP:</Form.Label>
                                                <Controller
                                                    name="exp"
                                                    control={control}
                                                    defaultValue={isEdit?.exp || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="month"
                                                            placeholder="12/26"
                                                            min={getCurrentDate()}
                                                            isInvalid={errors.exp}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    PIN: &nbsp;
                                                    {errors.security_code && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="security_code"
                                                    control={control}
                                                    defaultValue={isEdit?.security_code || ''}
                                                    rules={{
                                                        required: false,
                                                        minLength: {
                                                            value: 4,
                                                            message: 'Security code should not be less than 4 digits.'
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Security code must only contain digits',
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            maxLength={4}
                                                            placeholder="1234"
                                                            isInvalid={errors.security_code}
                                                        />
                                                    )}
                                                />
                                                {errors?.security_code && (
                                                    <small className="d-block text-danger">
                                                        {errors?.security_code?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    CVV: &nbsp;
                                                    {errors.security_code && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="cvv2"
                                                    control={control}
                                                    defaultValue={isEdit?.cvv2 || ''}
                                                    rules={{
                                                        required: false,
                                                        minLength: {
                                                            value: 3,
                                                            message: 'Security code should not be less than 3 digits.'
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Security code must only contain digits',
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            maxLength={3}
                                                            placeholder="123"
                                                            isInvalid={errors.cvv2}
                                                        />
                                                    )}
                                                />
                                                {errors?.cvv2 && (
                                                    <small className="d-block text-danger">
                                                        {errors?.cvv2?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Password Information</h5>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Ticket Master:</Form.Label>
                                                <Controller
                                                    name="ticket_master"
                                                    control={control}
                                                    defaultValue={isEdit?.ticket_master || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Password123"
                                                            isInvalid={errors.ticket_master}
                                                        />
                                                    )}
                                                />
                                                {errors?.ticket_master && <small className="smal text-danger">{errors?.ticket_master.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Gmail:</Form.Label>
                                                <Controller
                                                    name="gmail"
                                                    control={control}
                                                    defaultValue={isEdit?.gmail || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text" placeholder="Gmail"
                                                            isInvalid={errors.gmail}
                                                        />
                                                    )}
                                                />
                                                {errors?.gmail && <small className="smal text-danger">{errors?.gmail.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>AXS:</Form.Label>
                                                <Controller
                                                    name="axs"
                                                    control={control}
                                                    defaultValue={isEdit?.axs || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text"
                                                            isInvalid={errors.axs}
                                                        />
                                                    )}
                                                />
                                                {errors?.axs && <small className="smal text-danger">{errors?.axs.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Seatgeek:</Form.Label>
                                                <Controller
                                                    name="seatgeek"
                                                    control={control}
                                                    defaultValue={isEdit?.seatgeek || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter Account No"
                                                            isInvalid={errors.seatgeek}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>MLB Ballpark:</Form.Label>
                                                <Controller
                                                    name="mlb_ballpark"
                                                    control={control}
                                                    defaultValue={isEdit?.mlb_ballpark || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text"
                                                            isInvalid={errors.mlb_ballpark}
                                                        />
                                                    )}
                                                />
                                                {errors?.mlb_ballpark && <small className="smal text-danger">{errors?.mlb_ballpark.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Paciolan:</Form.Label>
                                                <Controller
                                                    name="paciolan"
                                                    control={control}
                                                    defaultValue={isEdit?.paciolan || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text"
                                                            isInvalid={errors.paciolan}
                                                        />
                                                    )}
                                                />
                                                {errors?.paciolan && <small className="smal text-danger">{errors?.paciolan.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Other:</Form.Label>
                                                <Controller
                                                    name="other"
                                                    control={control}
                                                    defaultValue={isEdit?.other || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 64,
                                                            message: 'Text too long'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            maxLength={64}
                                                            type="text"
                                                            isInvalid={errors.other}
                                                        />
                                                    )}
                                                />
                                                {errors?.other && <small className="smal text-danger">{errors?.other.message}</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-3 d-flex mb-3">
                                                    <Button
                                                        onClick={() => navigate('/identifies/list')}
                                                        variant="primary"
                                                        className="text-white mt-auto w-100"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="col-lg-3 d-grid mb-3 ">
                                                    <LoadingButton
                                                        isLoading={isLoadingAdd ? isLoadingAdd : isLoadingEdit}
                                                        name="Save"
                                                        submitting={() => setValue('save_and_new', false)}
                                                    />
                                                </div>
                                                {!id && (
                                                    <div className="col-lg-3 d-grid mb-3 ">
                                                        <LoadingButton
                                                            isLoading={isLoadingAdd ? isLoadingAdd : isLoadingEdit}
                                                            name="Save & New"
                                                            submitting={() => setValue('save_and_new', true)}
                                                            info="btn-info"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                {/* <Stepper
                    steps={[{ label: 'Identify Information' }, { label: 'Account Information' }, { label: 'Address Information' }, { label: 'Credit Information' }, { label: 'Password Information' }]}
                    styleConfig={{
                        activeBgColor: "rgba(45, 51, 61, 0.6)",
                        activeTextColor: "#fff",
                        inactiveBgColor: "#fff",
                        inactiveTextColor: "#999",
                        completedBgColor: "rgb(45, 51, 61)",
                        completedTextColor: "#fff",
                        size: "2em",
                    }}
                    activeStep={activeStep+1}
                /> */}
            </div>

            {/* {stepForm[activeStep].component} */}

        </>
    );
};

export default CreateIdentifyPage;