import React from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'

const TextEditor = () => {
    return (
        <>
            <CKEditor

                editor={ClassicEditor}
                config={{
                    height: 300
                }}

                // data={watch('description') || ''}

                onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor)
                }}
                onChange={(event, editor) => {
                    const data = editor.getData()
                    // setValue('description', data)
                    // console.log({ event, editor, data })
                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor)
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor)
                }}
            />
        </>
    );
};

export default TextEditor;