import React, {useEffect} from 'react';
import {Link, Navigate, NavLink, Outlet} from "react-router-dom";

const Group2 = () => {

    return (
        <>
            <div className="page-header d-flex align-items-center justify-content-between border-bottom mb-4">
                <h1 className="page-title">Profile</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </div>
            </div>
            <div className="main-container container-fluid">
                {/* ROW-1 */}
                <div className="row">
                    <div className="col-xxl-3 col-lg-6 mail-navigation">
                        <div className="w-100">
                            <div className="card p-1">
                                <div className="inbox-menu mt-4">
                                    <div className="list-group list-group-transparent mb-0 mail-inbox">
                                        <NavLink
                                            to="/client/group/profile"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-user-2-fill" />
                                            </span>
                                            &nbsp;
                                            Profile
                                            {" "}
                                            <span className="ms-auto badge bg-primary bradius">14</span>
                                        </NavLink>
                                        <NavLink
                                            to="/client/group/notes"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-sticky-note-2-fill" />
                                            </span>
                                            &nbsp;
                                            Notes <span className="ms-auto badge bg-secondary bradius">14</span>
                                        </NavLink>

                                        <NavLink
                                            to="/client/group/campaign"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-volume-up-fill" />
                                            </span>
                                            &nbsp;
                                            Campaign <span className="ms-auto badge bg-secondary bradius">14</span>
                                        </NavLink>

                                        <NavLink
                                            to="/client/group/documents"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-file-list-2-fill" />
                                            </span>
                                            &nbsp;
                                            Documents <span className="ms-auto badge bg-secondary bradius">14</span>
                                        </NavLink>
                                        <NavLink
                                            to="/client/group/logs"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-code-fill" />
                                            </span>
                                            &nbsp;
                                            Logs <span className="ms-auto badge bg-secondary bradius">14</span>
                                        </NavLink>
                                        <NavLink
                                            to="/client/group/order"
                                            className="mail-type list-group-item d-flex align-items-center mx-1 text-primary"
                                        >
                                            <span className="icons">
                                              <i className="ri-list-ordered" />
                                            </span>
                                            &nbsp;
                                            Order
                                        </NavLink>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-lg-7 col-md-7">
                        <Outlet/>
                    </div>
                </div>
                {/* ROW-1 CLOSED */}
            </div>

        </>
    );
};

export default Group2;