import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";

const CampaignModal = ({show, handleClose}) => {
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Campaign:</Form.Label>
                            <Form.Select>
                                <option value="">Please Select</option>
                                <option value="">Campaign 2</option>
                                <option value="">Campaign 3</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Date:</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>How to Apply:</Form.Label>
                            <Form.Select>
                                <option value="">Please Select</option>
                                <option value="">This should be the ONLY campaign - remove any other campaigns applied ti this lead</option>
                                <option value="">Keep existing campaigns and apply this campaign in addition</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Campaign:</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Which Step to Begin On:</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CampaignModal;