import React from 'react';
import {Button, Form, Offcanvas} from "react-bootstrap";
import MultiSelect from "../../components/MultiSelect";
import {Controller, useForm} from "react-hook-form";
import {useGetTeamsQuery} from "../../redux/services/TeamsService";
import SearchSelect from "../../components/SearchSelect";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
const LeadFilter = ({show, close, setQuery, status}) => {

    let {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const onsubmit = (data) => {
        console.log(data)
        let filter = '?'
        if(data?.status) {
            filter += `status=${data?.status?.value}`
        }
        if(data?.gender) {
            filter += `&gender=${data?.gender}`
        }
        setQuery(filter)

        close()
    }

    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Lead Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit(onsubmit)} id="filter">
                        <Form.Group className="mb-3">
                            <Form.Label>Status:</Form.Label>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <SearchSelect
                                        options={status}
                                        field={field}
                                        placeholder={"Search by Team"}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Gender:</Form.Label>
                            <Controller
                                name="gender"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Select {...field}>
                                        <option value="">Please Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Others</option>
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header>
                    <div className="col-6">
                        <Button
                            onClick={() => reset({
                                status:'',
                                gender:'',
                            })}
                            variant="primary"
                            className="text-white w-100 fw-bold"
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="col-6 d-grid">
                        <LoadingButtonWithForm
                            form="filter"
                            isLoading={false}
                            name="Filter"
                        />
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default LeadFilter;