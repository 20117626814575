import {newsApi} from './api'
import {
    ADD_Ticket_STATUS_ENDPOINT,
    DELETE_Ticket_STATUS_ENDPOINT,
    Edit_Ticket_STATUS_ENDPOINT,
    GET_Ticket_STATUS_ENDPOINT
} from "./apiConstant";

export const SettingTicketStatus = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getTicketStatus: build.query({
            query: () => ({
                url: `${GET_Ticket_STATUS_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addTicketStatus: build.mutation({
            query: (form) => ({
                url: `${ADD_Ticket_STATUS_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editTicketStatus: build.mutation({
            query: (form) => ({
                url: `${Edit_Ticket_STATUS_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeTicketStatus: build.mutation({
            query: (id) => ({
                url: `${DELETE_Ticket_STATUS_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetTicketStatusQuery,
    useAddTicketStatusMutation,
    useEditTicketStatusMutation,
    useRemoveTicketStatusMutation
} = SettingTicketStatus