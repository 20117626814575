import React from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import SearchSelect from '../../components/SearchSelect';
import {Controller, useForm} from "react-hook-form";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import {useGetLeagueQuery} from "../../redux/services/SettingLeagueService";
const TeamFilter = ({ show, close, setQuery}) => {
    let {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()
    const {data: leagueData} = useGetLeagueQuery()
    const league = leagueData?.response?.data

    const onsubmit = (data) => {
        console.log(data)
        let filter = '?'
        if(data?.league) {
            filter += `league=${data?.league?.value}`
        }
        if (data?.team_name) {
            filter += `&team_name=${data?.team_name}`
        }
        if(data?.psl_paid_off) {
            filter += `&psl_paid_off=${data?.psl_paid_off}`
        }
        setQuery(filter)
        close()
    }

    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Team Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit(onsubmit)} id="filter">
                        <Form.Group className="mb-3">
                            <Form.Label>League:</Form.Label>
                            <Controller
                                name="league"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <SearchSelect
                                        options={league?.map((item) => ({value: item?.id, label: item?.name}))}
                                        field={field}
                                        placeholder={"Search by League"}
                                    />
                                )}
                            />

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Team Name:</Form.Label>
                            <Controller
                                name="team_name"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Team Name" />
                                )}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                            <Form.Label>PSL Paid Off:</Form.Label>
                            <Form.Control type="text" placeholder="Search by PSL Paid Off" />
                        </Form.Group>

                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header>
                    <div className="col-6">
                        <Button
                            onClick={() => reset({
                                league: '',
                                team_name: '',
                                psl_paid_off: ''
                            })}
                            variant="primary"
                            className="text-white w-100 fw-bold"
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="col-6 d-grid">
                        <LoadingButtonWithForm
                            form="filter"
                            isLoading={false}
                            name="Filter"
                        />
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default TeamFilter;