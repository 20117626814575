import {newsApi} from './api'
import {
    ADD_LEAD_STATUS_ENDPOINT, CHANGE_LEAD_STATUS_ENDPOINT,
    DELETE_LEAD_STATUS_ENDPOINT,
    Edit_LEAD_STATUS_ENDPOINT,
    GET_LEAD_STATUS_ENDPOINT
} from "./apiConstant";

export const SettingLeadStatus = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getLeadStatus: build.query({
            query: () => ({
                url: `${GET_LEAD_STATUS_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        getPipelineStatus: build.query({
            query: () => ({
                url: `${GET_LEAD_STATUS_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            }),
            transformResponse: (response) => {
                const pipeline = {};
                response?.response?.data.forEach((item) => {
                    const id = item.id;
                    pipeline[id] = { ...item };
                });
                return pipeline; // Return transformed data
            },
            providesTags: ['LeadStatus'],
        }),
        changeLeadStatus: build.mutation({
            query: (form) => ({
                url: `${CHANGE_LEAD_STATUS_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        addLeadStatus: build.mutation({
            query: (form) => ({
                url: `${ADD_LEAD_STATUS_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editLeadStatus: build.mutation({
            query: (form) => ({
                url: `${Edit_LEAD_STATUS_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeLeadStatus: build.mutation({
            query: (id) => ({
                url: `${DELETE_LEAD_STATUS_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetLeadStatusQuery,
    useGetPipelineStatusQuery,
    useChangeLeadStatusMutation,
    useAddLeadStatusMutation,
    useEditLeadStatusMutation,
    useRemoveLeadStatusMutation
} = SettingLeadStatus