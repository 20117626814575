// Desc: API Base URL process.env.REACT_APP_API_BASE_URL
export const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

// Desc: API Endpoints
export const LOGIN_ENDPOINT = `login`;
export const FORGOT_ENDPOINT = `forgot-password`;
export const VERIFY_ENDPOINT = `verify-otp`;
export const RESET_ENDPOINT = `reset-password`;

// Profile
export const GET_DASHBOARD_ENDPOINT = `user/dashboard`;
export const GET_NOTIFICATION_ENDPOINT = `notification`;
export const Profile_INFO_ENDPOINT = `user/info`;
export const Profile_UPDATE_ENDPOINT = `user/update`;
export const CHANGE_PASSWORD_ENDPOINT = `user/change-password`;

// setting Lead Status
export const GET_LEAD_STATUS_ENDPOINT = `lead-status/get`;
export const ADD_LEAD_STATUS_ENDPOINT = `lead-status/add`;
export const CHANGE_LEAD_STATUS_ENDPOINT = `lead/convert-archive`;
export const Edit_LEAD_STATUS_ENDPOINT = `lead-status/edit`;
export const DELETE_LEAD_STATUS_ENDPOINT = `lead-status/delete`;

// setting Ticket Status
export const GET_Ticket_STATUS_ENDPOINT = `ticket-status/get`;
export const ADD_Ticket_STATUS_ENDPOINT = `ticket-status/add`;
export const Edit_Ticket_STATUS_ENDPOINT = `ticket-status/edit`;
export const DELETE_Ticket_STATUS_ENDPOINT = `ticket-status/delete`;

// setting Ticket Type
export const GET_Ticket_Type_ENDPOINT = `ticket-type/get`;
export const ADD_Ticket_Type_ENDPOINT = `ticket-type/add`;
export const Edit_Ticket_Type_ENDPOINT = `ticket-type/edit`;
export const DELETE_Ticket_Type_ENDPOINT = `ticket-type/delete`;

// setting Tagging
export const GET_Tagging_ENDPOINT = `tagging/get`;
export const ADD_Tagging_ENDPOINT = `tagging/add`;
export const Edit_Tagging_ENDPOINT = `tagging/edit`;
export const DELETE_Tagging_ENDPOINT = `tagging/delete`;


// setting AccountManager
export const GET_AccountManger_ENDPOINT = `account-manager/get`;
export const ADD_AccountManger_ENDPOINT = `account-manager/add`;
export const Edit_AccountManger_ENDPOINT = `account-manager/edit`;
export const DELETE_AccountManger_ENDPOINT = `account-manager/delete`;

// setting Alert
export const GET_Alert_ENDPOINT = `alert/get`;
export const ADD_Alert_ENDPOINT = `alert/add`;
export const Edit_Alert_ENDPOINT = `alert/edit`;
export const DELETE_Alert_ENDPOINT = `alert/delete`;

// setting League
export const GET_League_ENDPOINT = `league/get`;
export const ADD_League_ENDPOINT = `league/add`;
export const Edit_League_ENDPOINT = `league/edit`;
export const DELETE_League_ENDPOINT = `league/delete`;


// Tasks
export const GET_Tasks_ENDPOINT = `task/get`;
export const ADD_Tasks_ENDPOINT = `task/add`;
export const Edit_Tasks_ENDPOINT = `task/edit`;
export const DELETE_Tasks_ENDPOINT = `task/delete`;

// Tickets
export const GET_Tickets_ENDPOINT = `ticket/get`;
export const VIEW_Tickets_ENDPOINT = `ticket/view`;
export const ADD_Tickets_ENDPOINT = `ticket/add`;
export const Edit_Tickets_ENDPOINT = `ticket/edit`;
export const DELETE_Tickets_ENDPOINT = `ticket/delete`;
export const DELETE_All_Tickets_ENDPOINT = `ticket/delete-multiple`;
export const Import_Tickets_ENDPOINT = `ticket/import`;
export const CSV_Tickets_ENDPOINT = `ticket/upload-csv`;
export const Status_Tickets_ENDPOINT = `ticket/update-status`;


// Identity
export const CSV_Identity_ENDPOINT = `identity/upload-csv`;
export const IMPORT_Identity_ENDPOINT = `identity/import`;
export const GET_Identity_ENDPOINT = `identity/get`;
export const VIEW_Identity_ENDPOINT = `identity/view`;
export const Identity_HISTORY_ENDPOINT = `identity/get-histories`;
export const ADD_Identity_ENDPOINT = `identity/add`;
export const Edit_Identity_ENDPOINT = `identity/edit`;
export const DELETE_Identity_ENDPOINT = `identity/delete`;
export const DELETE_Identities_ENDPOINT = `identity/delete-multiple`;

// Lead
export const CSV_Lead_ENDPOINT = `lead/upload-csv`;
export const IMPORT_Lead_ENDPOINT = `lead/import`;
export const GET_Lead_ENDPOINT = `lead/get`;
export const GET_Archive_ENDPOINT = `lead/get-archive`;
export const VIEW_Lead_ENDPOINT = `lead/view`;
export const ADD_Lead_ENDPOINT = `lead/add`;
export const CONVERT_Lead_ENDPOINT = `lead/convert-identity`;
export const Edit_Lead_ENDPOINT = `lead/edit`;
export const DELETE_Lead_ENDPOINT = `lead/delete`;

// Teams
export const GET_Teams_ENDPOINT = `team/get`;
export const ADD_Teams_ENDPOINT = `team/add`;
export const Edit_Teams_ENDPOINT = `team/edit`;
export const DELETE_Teams_ENDPOINT = `team/delete`;

// Notes
export const GET_Notes_ENDPOINT = `note/get`;
export const ADD_Notes_ENDPOINT = `note/add`;
export const Edit_Notes_ENDPOINT = `note/edit`;
export const DELETE_Notes_ENDPOINT = `note/delete`;

// calender
export const GET_Calender_ENDPOINT = `task/get-calender`;

// Documents
export const GET_Documents_ENDPOINT = `documents/get`;
export const ADD_Documents_ENDPOINT = `documents/add`;
export const Edit_Documents_ENDPOINT = `documents/edit`;
export const DELETE_Documents_ENDPOINT = `documents/delete`;

// Documents
export const GET_AssignTag_ENDPOINT = `assign-tag/get`;
export const View_AssignTag_ENDPOINT = `assign-tag/view`;
export const ADD_AssignTag_ENDPOINT = `assign-tag/add`;
export const Edit_AssignTag_ENDPOINT = `assign-tag/edit`;
export const DELETE_AssignTag_ENDPOINT = `assign-tag/delete`;

// Activity Logs
export const GET_ActivityLog_ENDPOINT = `logs/get-logs`;
export const ADD_ActivityLog_ENDPOINT = `ActivityLog/add`;
export const Edit_ActivityLog_ENDPOINT = `ActivityLog/edit`;
export const DELETE_ActivityLog_ENDPOINT = `ActivityLog/delete`;

export const PROFILE_ENDPOINT = `profile`;
export const UPDATE_PROFILE_ENDPOINT = `update-profile`;
export const CHANGE_PROFILE_ENDPOINT = `change-password`;
export const ARTICLE_CATEGORIES_ENDPOINT = `articles/categories`;
export const ARTICLE_SOURCES_ENDPOINT = `articles/sources`;
export const ARTICLE_AUTHORS_ENDPOINT = `articles/authors`;
export const ARTICLES_ENDPOINT = `articles`;
export const UPDATE_PREFERENCES_ENDPOINT = `update-preferences`;

// search
export const GET_Search_ENDPOINT = `team/global-search`

// Users & Roles
export const ROLE_LINKS_ENDPOINT = `roles/links`;
export const ADD_ROLE_ENDPOINT = `roles/add`;
export const EDIT_ROLE_ENDPOINT = `roles/update`;
export const DELETE_ROLE_ENDPOINT = `roles/delete`;
export const GET_ROLE_ENDPOINT = `roles/index`;
export const VIEW_ROLE_ENDPOINT = `roles/view/`;


// Users
export const GET_USER_ENDPOINT = `employees`;
export const ADD_USER_ENDPOINT = `employees/add`;
export const EDIT_USER_ENDPOINT = `employees/edit/`;
export const VIEW_USER_ENDPOINT = `employees/view/`;
export const DELETE_USER_ENDPOINT = `employees/delete`;
