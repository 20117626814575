import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {useChangePasswordMutation} from "../../redux/services/ProfileService";
import {sweetAlert} from "../../utils";
import {Form} from "react-bootstrap";
import LoadingButton from "../../components/LoadingButton";

const ChangePassword = () => {
    let {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [onPostRequest, { isLoading }] = useChangePasswordMutation()

    const onSubmit = async (newData) => {
        await onPostRequest(newData)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="">
                        <div className="p-5">
                            <div className="mb-4 main-content-label">
                                Change Password
                            </div>
                            <div className="form-horizontal">
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Current Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Controller
                                                name="current_password"
                                                control={control}
                                                defaultValue={''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="******"
                                                        isInvalid={errors.current_password}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">New Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Controller
                                                name="password"
                                                control={control}
                                                defaultValue={''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="******"
                                                        isInvalid={errors.password}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Confirm Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Controller
                                                name="confirm_password"
                                                control={control}
                                                defaultValue={''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="******"
                                                        isInvalid={errors.confirm_password}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn ripple btn-light w-sm me-2"
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                isLoading={isLoading}
                                name="Save"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;