import React, { useEffect, useState } from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import TagsInput from '../../components/TagsInput';
import { Tab, Nav, Table, ListGroup } from 'react-bootstrap';
import SearchSelect from '../../components/SearchSelect';
import { Badge } from 'react-bootstrap';
import { fullName } from "../../utils";
import { Link } from "react-router-dom";
import { useGetTaggingQuery } from "../../redux/services/SettingTaggingService";
import { Controller, useForm } from "react-hook-form";
import { useEditIdentityMutation } from "../../redux/services/IdentityService";
import { useEditAssignTagMutation } from "../../redux/services/AssignTagService";
import Alerts from "./Alerts";
import AssignTags from "./AssignTags";
import History from "./History";


const IdentifiesView = ({ show, close, isEdit, remove }) => {
    const [activeTab, setActiveTab] = useState('alerts');


    return (
        <>
            <Offcanvas show={show} className="canvas-lg bg-light" onHide={close} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        Identity Details
                    </Offcanvas.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={close}><i className="ri-close-line"></i></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card border shadow-sm">
                                <div className="card-header">
                                    <h4>ID#{isEdit?.id?.toString()?.padStart(4, '0')}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-cen3er">Identity Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Name:</label>
                                                            <p>{isEdit?.name || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Email:</label>
                                                            <p>{isEdit?.email || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Phone Number:</label>
                                                            <p>{isEdit?.phone || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Gender:</label>
                                                            <p className='text-capitalize'>{isEdit?.gender?.toLowerCase() || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-left">Account Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">ID Upload:</label>
                                                            {isEdit?.id_upload && (
                                                                <img src={isEdit?.logo_url} alt="id-upload" height={60} />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Identity Owner: :</label>
                                                            <p>{isEdit?.identify_owner || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Real/Fake:</label>
                                                            <p className='text-capitalize'>{isEdit?.real_fake?.toLowerCase() || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-left">Address Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Street Address:</label>
                                                            <p>{isEdit?.street_address || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Appt Number:</label>
                                                            <p>{isEdit?.appt_number || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">City:</label>
                                                            <p>{isEdit?.city || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">State:</label>
                                                            <p>{isEdit?.state || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Zip Code:</label>
                                                            <p>{isEdit?.zip_code || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Metro Area:</label>
                                                            <p>{isEdit?.metro_area || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-data mt-4">
                                                <h5 className="text-left">Password Information</h5>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Ticket Master:</label>
                                                            <p>{isEdit?.ticket_master || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Gmail:</label>
                                                            <p>{isEdit?.gmail || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">AXS:</label>
                                                            <p>{isEdit?.axs || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Seatgeek:</label>
                                                            <p>{isEdit?.seatgeek || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">MLB Ballpark:</label>
                                                            <p>{isEdit?.mlb_ballpark || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Paciolan:</label>
                                                            <p>{isEdit?.paciolan || '-'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-2">
                                                            <label className="form-label">Other:</label>
                                                            <p>{isEdit?.other || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-flex gap-2 mt-3">
                                                <Link to={`/identifies/edit/${isEdit.id}`} type="button" className="text-white px-5  btn btn-primary" data-dashlane-label="true" data-dashlane-rid="8e865cb1e4d1fe99" data-form-type="">
                                                    Edit
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="text-white px-5 btn btn-danger" data-dashlane-label="true" data-dashlane-rid="389c8d26ebcd8d16" data-form-type=""
                                                    onClick={() => remove(isEdit)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="alerts">Alerts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="history">History</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className="card">
                                    <div className="card-body">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="alerts">
                                                <Alerts isEdit={isEdit} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="history">
                                                <History isEdit={isEdit} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                        <div className="col-md-3">
                            <div className="side-bar">
                                <div className="card border shadow-sm">
                                    <div className="card-header bg-info">
                                        <span className='text-white'><span className=" font-weight-bold">Last Activity:</span> {isEdit?.last_activity}</span>
                                    </div>
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Created At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.created_at}
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Updated At:</span>
                                            <span className=" font-weight-semibold font-13">
                                                {isEdit?.updated_at}
                                            </span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between  pt-4">
                                            <span className=" font-weight-bold pt-2">
                                                Credit Card Information:
                                            </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Credit Type:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.credit_type || '-'}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Card#:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.ccv || '-'}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Exp:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.exp || '-'}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">CVV:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.ccv2 || '-'}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Security Code:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.security_code || '-'}</span>
                                        </li>
                                        {/*<li className="list-group-item d-flex justify-content-between">
                                            <span className=" text-muted">Zip:</span>
                                            <span className=" font-weight-semibold font-13">{isEdit?.zip_code || '-'}</span>
                                        </li>*/}
                                        <li className="list-group-item d-flex justify-content-between">
                                            <AssignTags id={isEdit?.id} />
                                        </li>

                                        <li className="list-group-item  pt-5">
                                            <span className=" font-weight-bold d-block mb-2">Created By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`} className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item ">
                                            <span className=" font-weight-bold d-block mb-2">Updated By:</span>

                                            <div className="d-flex gap-2 align-items-center">
                                                <img src={`https://i2.wp.com/ui-avatars.com/api//${fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}/45?ssl=1`} className='user-image' alt="" />
                                                <span className=" font-weight-semibold font-13">
                                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
                {/* <Offcanvas.Header>
                    <div className="col-6">
                        <Button variant="primary" className="text-white w-100 fw-bold">
                            Reset
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="primary" onClick={close} className="text-white w-100 fw-bold">
                            Filter
                        </Button>
                    </div>
                </Offcanvas.Header> */}
            </Offcanvas >
        </>
    );
};

export default IdentifiesView;