import React from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import {Controller, useForm} from "react-hook-form";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";

const IdentityFilter = ({ show, close, setQuery }) => {
    let {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const onsubmit = (data) => {
        console.log(data)
        let filter = '?'
        if(data?.name) {
            filter += `name=${data?.name}`
        }
        if(data?.email) {
            filter += `&email=${data?.email}`
        }
        if(data?.phone) {
            filter += `&phone=${data?.phone}`
        }
        if(data?.address) {
            filter += `&address=${data?.address}`
        }
        if(data?.city) {
            filter += `&city=${data?.city}`
        }
        if(data?.state) {
            filter += `&state=${data?.state}`
        }
        if(data?.zip_code) {
            filter += `&zip_code=${data?.zip_code}`
        }
        if(data?.metro_area) {
            filter += `&metro_area=${data?.metro_area}`
        }
        if(data?.real_fake) {
            filter += `&real_fake=${data?.real_fake}`
        }
        setQuery(filter)

        close()
    }

    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Identity Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit(onsubmit)} id="filter">
                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Name" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email:</Form.Label>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="email" placeholder="Search by Email" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                            <Form.Label>Account History:</Form.Label>
                            <Form.Control type="text" placeholder="Search by Account History" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number:</Form.Label>
                            <Controller
                                name="phone"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Phone Number" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Address:</Form.Label>
                            <Controller
                                name="address"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Address" />
                                )}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>City:</Form.Label>
                            <Controller
                                name="city"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by City" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>State:</Form.Label>
                            <Controller
                                name="state"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by State" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Zip Code:</Form.Label>
                            <Controller
                                name="zip_code"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Zip Code" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Metro Area:</Form.Label>
                            <Controller
                                name="metro_area"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Metro Area" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Real / Fake:</Form.Label>
                            <Controller
                                name="real_fake"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Real / Fake" />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header>
                    <div className="col-6">
                        <Button
                            onClick={() => reset({
                                name: '',
                                email: '',
                                phone: '',
                                address: '',
                                city: '',
                                state: '',
                                zip_code: '',
                                metro_area: '',
                                real_fake: '',
                            })}
                            variant="primary"
                            className="text-white w-100 fw-bold"
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="col-6 d-grid">
                        <LoadingButtonWithForm
                            form="filter"
                            isLoading={false}
                            name="Filter"
                        />
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default IdentityFilter;