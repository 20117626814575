import {newsApi} from './api'
import {
    GET_Calender_ENDPOINT
} from "./apiConstant";

export const CalenderService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getCalender: build.query({
            query: () => ({
                url: `${GET_Calender_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetCalenderQuery,
} = CalenderService