import {newsApi} from './api'
import {
    ADD_Alert_ENDPOINT,
    DELETE_Alert_ENDPOINT,
    Edit_Alert_ENDPOINT,
    GET_Alert_ENDPOINT
} from "./apiConstant";

export const SettingAlert = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getAlert: build.query({
            query: (data) => ({
                url: `${GET_Alert_ENDPOINT}${data?.deferredQuery ? data?.deferredQuery : ''}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addAlert: build.mutation({
            query: (form) => ({
                url: `${ADD_Alert_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editAlert: build.mutation({
            query: (form) => ({
                url: `${Edit_Alert_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeAlert: build.mutation({
            query: (id) => ({
                url: `${DELETE_Alert_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetAlertQuery,
    useAddAlertMutation,
    useEditAlertMutation,
    useRemoveAlertMutation
} = SettingAlert