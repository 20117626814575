import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const PlaceholderLoading = () => {
    return (
        <>
            <div className="p-4">
                <SkeletonTheme baseColor="#ddd" highlightColor="#f5f5f5">
                    <p>
                        <Skeleton count={5}  height={20} borderRadius={0} style={{ marginBottom: 10}}/>
                    </p>
                </SkeletonTheme>
            </div>
        </>
    );
};

export default PlaceholderLoading;