import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import {fullName} from "../../utils";

const ViewModal = ({ show, handleClose, isEdit }) => {

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5 text-center">View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Render row details */}
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Name</span>
                            <p class="">{isEdit?.name || '-'}</p>
                        </div>
                        {/*<div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Email</span>
                            <p class="">{isEdit?.email || '-'}</p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Password</span>
                            <p class="">{isEdit?.password || '-'}</p>
                        </div>*/}
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Link</span>
                            <p class="">
                                {isEdit?.link ? (
                                    <a href={isEdit?.link} className="text-break">
                                        {isEdit?.link}
                                    </a>
                                )  : '-'}
                            </p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Added By</span>
                            <p class="">
                                <span class="badge bg-warning">
                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated By</span>
                            <p class="">
                                <span class="badge bg-warning">
                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Created At</span>
                            <p class="">
                                <span class="badge bg-info">
                                    {isEdit?.created_at}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated At</span>
                            <p class="">
                                <span class="badge bg-info">
                                    {isEdit?.updated_at}
                                </span>
                            </p>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewModal;