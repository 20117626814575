import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useForm, Controller } from 'react-hook-form'
import {Form} from "react-bootstrap";
import {useLoginMutation} from "../../redux/services/authService";
import {sweetAlert} from "../../utils";
import LoadingButton from "../../components/LoadingButton";
import logo from "../../assets/images/logo5.png";


const SignInPage = () => {
    const navigate = useNavigate()
    let {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [loginRequest, { isLoading }] = useLoginMutation()

    const onLogin = async (data) => {
        await loginRequest(data)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    navigate('/')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    return (
        <>
            <div className="page login-img d-flex">
                {/* CONTAINER OPEN */}
                <div className="d-none">
                    <div className="text-center">
                        <a href="index.html">
                            <img
                                src="build/assets/images/brand/desktop-dark.png"
                                className="header-brand-img"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row justify-content-center mt-4 mx-0">
                        <div className="col-xl-4 col-lg-6">
                            <div className="card shadow-none">
                                <div className="card-body p-sm-6">
                                    <div className="header-logo text-center mb-4">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="desktop-logo"
                                            style={{ height: 82, objectFit: 'contain', objectPosition: 'center' }}
                                        />
                                        {/*<h3 className='text-center text-white fw-bold'>Logo</h3>*/}
                                    </div>
                                    <div className="text-center mb-4">
                                        <h4 className="mb-1">Sign In</h4>
                                        <p>Sign in to your account to continue.</p>
                                    </div>
                                    <Form onSubmit={handleSubmit(onLogin)} className="row">
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Email
                                                </label>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            className="form-control ms-0"
                                                            type="email"
                                                            placeholder="Enter your Email"
                                                            isInvalid={errors.email}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Password
                                                </label>
                                                <div>
                                                    <Controller
                                                        name="password"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: true
                                                        }}
                                                        render={({ field }) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="Password"
                                                                isInvalid={errors.password}
                                                            />
                                                        )}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="d-flex mb-3">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue=""
                                                        id="flexCheckDefault"
                                                    />
                                                    <label
                                                        className="form-check-label tx-15"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>
                                                <div className="ms-auto">
                                                    <Link
                                                        to="/forgot-password"
                                                        className="tx-primary ms-1 tx-13"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="d-grid mb-3">
                                                <LoadingButton
                                                    isLoading={isLoading}
                                                    name={'Login'}
                                                />
                                            </div>
                                            <div className="text-center d-none">
                                                <p className="mb-0 tx-14">
                                                    Don't have an account yet?
                                                    <Link
                                                        to="/sign-up"
                                                        className="tx-primary ms-1 text-decoration-underline"
                                                    >
                                                        Sign Up
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CONTAINER CLOSED */}
            </div>

        </>
    );
};

export default SignInPage;