import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAddAlertMutation, useEditAlertMutation } from "../../redux/services/SettingAlertService";
import { sweetAlert } from "../../utils";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import { useAddNotesMutation, useEditNotesMutation } from "../../redux/services/NotesService";

const NotesAddModal = ({ show, handleClose, isEdit }) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm()
    // add
    const [addRequest, { isLoading: addLoading }] = useAddNotesMutation()
    // Edit
    const [editRequest, { isLoading: editLoading }] = useEditNotesMutation()

    const onSubmit = async (data) => {
        if (isEdit) {
            await editRequest({
                id: isEdit?.id,
                ...data,
            }).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            await addRequest(data).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">{isEdit ? 'Edit' : 'Create'} Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} id="lead-status">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Title:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={isEdit?.title || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter title"
                                        isInvalid={errors.title}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>
                                Description:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={isEdit?.description || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        as="textarea" rows={4}
                                        placeholder="Enter description"
                                        isInvalid={errors.description}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <LoadingButtonWithForm
                        isLoading={editLoading ? editLoading : addLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NotesAddModal;