import React from 'react';
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {systemDateChecker} from "../helpers";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useSelector((state) => state.authenticate)
    const check = systemDateChecker();
    if(check === false) return <Navigate to="/sign-in" replace={true} />;
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/sign-in" replace={true} />
};

export default PrivateRoute;