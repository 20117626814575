import React, { KeyboardEventHandler } from 'react';

import CreatableSelect from 'react-select/creatable';

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

const TagsInput = ({field}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState(field.value || []);

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                field.onChange([...value, createOption(inputValue)])
                setInputValue('');
                event.preventDefault();
        }
    };

    return (
        <CreatableSelect
            {...field}
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => setValue(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={value}
        />
    );
};

export default TagsInput;