import React, {useState} from 'react';
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {customTableStyles} from "../../../utils";
import InvoiceModal from "./InvoiceModal";
import {Button} from "react-bootstrap";

const Invoice = () => {
    const [isShow, setIsShow] = useState(false)


    const columns = [
        {
            name: 'Invoice #',
            selector: row => row.id,
            sortable: true,
            width: '180px',
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Total Tax',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <span className="badge bg-primary-transparent ">{row.status}</span>,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-eye-fill"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-edit-line"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line"/>
                            </a>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const data = [
        {
            id: 1234,
            first_name: 'Beetlejuice',
            last_name: 'juice',
            email: 'sha@gmail.com',
            mobile: '+923133455',
            status: 'pending',
            amount: `$1233`,
            createdAt: '03-12-2023'
        },
        {
            id: 3431,
            first_name: 'Beetle',
            last_name: 'juice',
            email: 'sha@gmail.com',
            mobile: '+923133455',
            status: 'pending',
            amount: `$1233`,
            createdAt: '03-12-2023'
        },
    ]
    return (
        <>
            <div className="card custom-card">
                <div className="card-header w-100">
                    <div className="row w-100 m-0 justify-content-between">
                        <div className="col-auto p-0 d-flex gap-1">
                            <Button
                                className="btn btn-primary"
                                onClick={() => setIsShow(true)}
                            >
                                Create Invoice
                            </Button>
                        </div>
                        
                        <div className="col-lg-3 col-5 p-0">
                            <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line"/>
                                            </span>
                                <input
                                    className="form-control" type="text" placeholder="Search Here..."
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customTableStyles}
                        pagination={true}
                    />
                </div>
            </div>

            <InvoiceModal
                show={isShow}
                handleClose={() => setIsShow(false)}
            />
        </>
    );
};

export default Invoice;