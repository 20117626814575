import {newsApi} from './api'
import {
    ADD_Identity_ENDPOINT, CSV_Identity_ENDPOINT, DELETE_Identities_ENDPOINT,
    DELETE_Identity_ENDPOINT,
    Edit_Identity_ENDPOINT,
    GET_Identity_ENDPOINT, Identity_HISTORY_ENDPOINT, IMPORT_Identity_ENDPOINT, VIEW_Identity_ENDPOINT
} from "./apiConstant";

export const IdentityService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        csvIdentity: build.mutation({
            query: (form) => ({
                url: `${CSV_Identity_ENDPOINT}`,
                method: 'POST',
                body: form,

            }),
            invalidatesTags: ['LeadStatus'],
        }),
        importIdentity: build.mutation({
            query: (form) => ({
                url: `${IMPORT_Identity_ENDPOINT}`,
                method: 'POST',
                body: form,

            }),
            invalidatesTags: ['LeadStatus'],
        }),
        getIdentity: build.query({
            query: (data) => ({
                url: `${GET_Identity_ENDPOINT}${data?.deferredQuery}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        viewIdentity: build.query({
            query: (form) => ({
                url: `${VIEW_Identity_ENDPOINT}?id=${form?.id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        identityHistory: build.query({
            query: (form) => ({
                url: `${Identity_HISTORY_ENDPOINT}?identity_id=${form?.id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addIdentity: build.mutation({
            query: (form) => ({
                url: `${ADD_Identity_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editIdentity: build.mutation({
            query: (form) => ({
                url: `${Edit_Identity_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeIdentity: build.mutation({
            query: (id) => ({
                url: `${DELETE_Identity_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeIdentities: build.mutation({
            query: (id) => ({
                url: `${DELETE_Identities_ENDPOINT}`,
                method: 'POST',
                body: {ids: id},
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useCsvIdentityMutation,
    useImportIdentityMutation,
    useGetIdentityQuery,
    useViewIdentityQuery,
    useIdentityHistoryQuery,
    useAddIdentityMutation,
    useEditIdentityMutation,
    useRemoveIdentityMutation,
    useRemoveIdentitiesMutation
} = IdentityService