import React, {useEffect, useMemo, useState} from 'react';
import PageHeader from "../../components/PageHeader";
import {Link, Route, useLocation} from "react-router-dom";
import DataTable from "react-data-table-component";
import {customTableStyles, deleteConfirmation} from "../../utils";
import {useGetRoleQuery, useRemoveRoleMutation} from "../../redux/services/RolesService";
import usePermission from "../../hooks/usePermission";

const RolesPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()
    const [filterText, setFilterText] = useState('')
    const { data , error, isLoading, isFetching, isSuccess } = useGetRoleQuery();
    const [ removeRequest ] = useRemoveRoleMutation();
    // console.log(data , error, isLoading, isSuccess);

    const columns = [
        {
            name: 'Role Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Added By',
            // selector: row => ,
            sortable: true,
            cell: (row) => (
                <span className='badge bg-info'>{row.added_by?.name || 'none'}</span>
            ),
        },
        {
            name: 'Updated By',
            // selector: row => row.updatedBy,
            cell: (row) => (
                <span className='badge bg-info'>{row.updated_by?.name || 'none'}</span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            {/*<a*/}
                            {/*    aria-label="anchor"*/}
                            {/*    href="javascript:void(0);"*/}
                            {/*    className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"*/}
                            {/*>*/}
                            {/*    <i className="ri-eye-fill"/>*/}
                            {/*</a>*/}
                            {checkPermission('roles.edit') && (
                                <Link
                                    to={`/edit-role/${row?.id}`}
                                    className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-edit-line"/>
                                </Link>
                            )}
                            {checkPermission('roles.delete') && (
                                <button type={"button"} onClick={() => deleteConfirmation(() => removeRequest(row?.id))}
                                        className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-delete-bin-7-line"/>
                                </button>
                            )}
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        if (state) {
            console.log('state', state)
            setFilterText(state?.name)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return data?.response?.data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data?.response?.data, filterText])

    // console.log("roles",data);

    return (
        <>
            <PageHeader name="Roles"/>


            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex gap-1">
                                        {checkPermission('roles.add') && (
                                            <Link
                                                to="/create-role"
                                                className="btn btn-primary"
                                            >
                                                Create Roles
                                            </Link>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-5 p-0">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line"/>
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center"
                                              style={{height: 100}}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RolesPage;