import React, { useState } from 'react';
import Select from 'react-select';

const SearchSelect = ({ options, placeholder, field }) => {
    const [selectedOption, setSelectedOption] = useState(field?.value || null);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        field.onChange(selectedOption)
    };

    return (
        <>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                name="colors"
                className="basic-multi-select"
                classNamePrefix="select"
                {...field}
            />
        </>
    );
};

export default SearchSelect;
