import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";

const InvoiceModal = ({show, handleClose}) => {
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row">
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Invoice Number:</Form.Label>
                                <Form.Control />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Invoice Date:</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Due Date:</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </div>

                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Currency:</Form.Label>
                                <Form.Select>
                                    <option value="" >Please Select</option>
                                    <option value="">USD S</option>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Sales Agent:</Form.Label>
                                <Form.Select>
                                    <option value="" >Please Select</option>
                                    <option value="">Agent 1</option>
                                    <option value="">Agent 2</option>
                                    <option value="">Agent 3</option>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        <div className="col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Discount Type :</Form.Label>
                                <Form.Select>
                                    <option value="" >Please Select</option>
                                    <option value="">No Discount</option>
                                    <option value="">Before Tax</option>
                                    <option value="">After Tax</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Admin Note:</Form.Label>
                                <textarea
                                    className="form-control"
                                    name="example-textarea-input"
                                    rows={4}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InvoiceModal;