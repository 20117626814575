import React, {useState} from 'react';
import Select from 'react-select';

const MultiSelect = ({options, field = '', onSubmit = () => {} }) => {
    const option = useState([
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ] || options)

    return (
        <>
            <Select
                {...field}
                // defaultValue={field.value}
                isMulti
                name="colors"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(value) => {
                    field.onChange(value)
                    onSubmit(value)
                }}
            />
        </>
    );
};

export default MultiSelect;