import React, {useEffect} from 'react';
import {Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useLoginMutation} from "../../redux/services/authService";
import {sweetAlert} from "../../utils";
import {useEditProfileMutation, useGetProfileQuery} from "../../redux/services/ProfileService";
import LoadingButton from "../../components/LoadingButton";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";

const EditProfile = ({data, isFetching}) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm()

    const [editProfileRequest, { isLoading }] = useEditProfileMutation()

    const onUpdate = async (newData) => {
        newData['email'] = data.email
        await editProfileRequest(newData)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    useEffect(() => {
        setValue('first_name', data?.name || '')
        setValue('last_name', data?.last_name || '')
        setValue('phone', data?.phone || '')
        setValue('gender', data?.gender || '')
    }, []);


    if(isLoading && !data){
        return (
            <>
                <PlaceholderLoading />
            </>
        )
    }



    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <Form onSubmit={handleSubmit(onUpdate)} className="">
                        <div className="p-5">
                            <div className="form-horizontal">
                                <div className="mb-4 main-content-label">
                                    Personal Information
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">First Name</label>
                                        </div>
                                        <div className="col-md-10">
                                            <Controller
                                                name="first_name"
                                                control={control}
                                                defaultValue={data?.name || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        isInvalid={errors.first_name}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Last Name</label>
                                        </div>
                                        <div className="col-md-10">
                                            <Controller
                                                name="last_name"
                                                control={control}
                                                defaultValue={data?.last_name || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        isInvalid={errors.last_name}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4 main-content-label">
                                    Contact Info
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">
                                                Email<i>(required)</i>
                                            </label>
                                        </div>
                                        <div className="col-md-10">
                                            <Form.Control
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                defaultValue={data?.email || ''}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Phone</label>
                                        </div>
                                        <div className="col-md-10">
                                            <Controller
                                                name="phone"
                                                control={control}
                                                defaultValue={data?.phone || ''}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field }) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        isInvalid={errors.phone}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Gender</label>
                                        </div>
                                        <div className="col-md-10">
                                            <Controller
                                                name="gender"
                                                control={control}
                                                defaultValue={data?.gender || ''}
                                                rules={{
                                                    required: false
                                                }}
                                                render={({ field }) => (
                                                    <Form.Select {...field}>
                                                        <option value="">Please Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </Form.Select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn ripple btn-light w-sm me-2"
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                isLoading={isLoading}
                                name="Save"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default EditProfile;