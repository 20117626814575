import React from 'react';
import {useSelector} from "react-redux";

const UsePermission = () => {
    const roles = useSelector(state => state?.authenticate?.roles)
    const parent = useSelector(state => state?.authenticate?.user?.parent)


    const checkPermission = (permission) => {
        console.log('checkPermission===', parent, roles?.find((val) => val?.links?.back_route))
        console.log('check', !!(parent == "0" || roles?.find((val) => val?.links?.back_route === permission)))
        return !!(parent == "0" || roles?.find((val) => val?.links?.back_route === permission));
    }
    return {
        checkPermission
    }
};

export default UsePermission;