import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import { Link, Route, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customTableStyles, sweetAlert, sweetAlertConfirmation } from "../../utils";
import TeamCreateModal from './CreateModal';
import TeamEditModal from './EditModal';
import { Button } from "react-bootstrap";
import TeamFilter from './TeamFilters';

import { useGetAlertQuery, useRemoveAlertMutation } from "../../redux/services/SettingAlertService";
import { useGetTeamsQuery, useRemoveTeamsMutation } from "../../redux/services/TeamsService";

import TeamViewSide from './TeamSideView';
import usePermission from "../../hooks/usePermission";

const TeamsPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [query, setQuery] = React.useState('')
    const deferredQuery = useDeferredValue(query)

    const { data: data2, isLoading, isFetching, refetch } = useGetTeamsQuery({ deferredQuery })
    // delete
    const [removeRequest] = useRemoveTeamsMutation()

    const data = data2?.response?.data

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }
    const handleEdit = (item) => {
        setIsEdit(item)
        setIsShow(true)
    }
    const handleAdd = () => {
        setIsEdit(null)
        setIsShow(true)
        // reset()
    }

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                refetch()
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    // =======
    //     const [isView, setIsView] = useState(false)
    // >>>>>>> 3a973bd79b3067364c1a03c2c240ee37d9234f87
    const columns = [
        {
            name: 'ID#',
            selector: row => row.id,
            sortable: true,
            width: '70px',
        },
        {
            name: 'League',
            selector: row => row?.league_relation?.name,
            sortable: true,
        },
        {
            name: 'Team',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: '# of Seats',
            selector: row => row.no_of_seat,
            sortable: true,
        },
        {
            name: 'Total Cost',
            selector: row => parseFloat(row.total_cost).toFixed(2),
            sortable: true,
        },
        {
            name: 'PSL Total',
            selector: row => row.psl_paid_of,
            sortable: true,
        },
        {
            name: 'Total Accounts',
            selector: row => parseFloat(row.total_amount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            {checkPermission('team.view') && (
                                <button
                                    aria-label="anchor"
                                    onClick={() => handleView(row)}
                                    className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-eye-fill" />
                                </button>
                            )}

                            {checkPermission('team.edit') && (
                                <button
                                    aria-label="anchor"
                                    onClick={() => handleEdit(row)}
                                    className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-edit-line" />
                                </button>
                            )}

                            {checkPermission('team.delete') && (
                                <a
                                    aria-label="anchor"
                                    onClick={() => handleRemove(row)}
                                    className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                                >
                                    <i className="ri-delete-bin-7-line" />
                                </a>
                            )}

                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        if (state) {
            console.log('state', state)
            handleView(state)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return data?.teams?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])

    return (
        <>
            <PageHeader name="Teams" />


            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0">Total Cost</p>
                                        <span className="fs-5">
                                            {data?.header?.total_cost ? parseFloat(data?.header?.total_cost).toFixed(2) : 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-primary-transparent text-primary">
                                            <i className="fe  fs-18" >$</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Seats</p>
                                        <span className="fs-5">
                                            {parseInt(data?.header?.no_of_seat) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-secondary-transparent text-secondary">
                                            <i className="fe fe-package fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Accounts</p>
                                        <span className="fs-5">
                                            {parseInt(data?.header?.no_of_account) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-warning-transparent text-warning">
                                            <i className="fe fe-user fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 ">
                                    <div className="col-auto p-0 d-flex">
                                        {checkPermission('team.store') && (
                                            <Button className="btn btn-primary" onClick={handleAdd}>
                                                Create Team
                                            </Button>
                                        )}
                                    </div>
                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsFilter(true)}
                                        >
                                            <i className="ri-filter-2-fill" />
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-5 p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">

                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center" style={{ height: 100 }}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShow && (
                <TeamCreateModal
                    show={isShow}
                    handleClose={() => setIsShow(false)}
                    isEdit={isEdit}
                />
            )}
            {/*<TeamEditModal
                show={isEdit}
                handleClose={() => setIsEdit(false)}
            />*/}

            {isView && (
                <TeamViewSide
                    show={isView}
                    close={() => setIsView(false)}
                    isEdit={isEdit}
                />
            )}

            <TeamFilter
                show={isFilter}
                close={() => setIsFilter(false)}
                setQuery={setQuery}
            />
        </>
    );
};

export default TeamsPage;