import React, {useEffect, useMemo, useState} from 'react';
import PageHeader from "../../components/PageHeader";
import {Link, useLocation} from "react-router-dom";
import DataTable from "react-data-table-component";
import {customTableStyles, fullName, sweetAlert, sweetAlertConfirmation} from "../../utils";
import { Button } from "react-bootstrap";
import EditLeaguesModal from './edit';
import AddLeaguesModal from './Add';
import ViewModal from './View';
import {useGetAlertQuery, useRemoveAlertMutation} from "../../redux/services/SettingAlertService";
import {useGetLeagueQuery, useRemoveLeagueMutation} from "../../redux/services/SettingLeagueService";
import usePermission from "../../hooks/usePermission";
import SettingButtons from "../../components/Group/SettingButtons";
const LeaguesPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [filterText, setFilterText] = useState('')

    const {data: data2, isLoading, isFetching, refetch} = useGetLeagueQuery()
    // delete
    const [removeRequest] = useRemoveLeagueMutation()

    const data = data2?.response?.data

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }
    const handleEdit = (item) => {
        setIsEdit(item)
        setIsShow(true)
    }
    const handleAdd = () => {
        setIsEdit(null)
        setIsShow(true)
        // reset()
    }

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                refetch()
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    const columns = [
        /*{
            name: 'Logo',
            cell: (row) => row?.logo && <img src={row.file_url} alt="League Logo" style={{ width: '50px', height: '50px' }} />,
        },*/
        {
            name: 'League Name',
            selector: row => row.name,
            sortable: true,
        },
        /*{
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },*/

        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Added By',
            // selector: row => row.addedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.added_by?.name, row?.added_by?.last_name)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        },
        {
            name: 'Updated By',
            // selector: row => row.updatedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.updated_by?.name, row?.updated_by?.last_name)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <div className="hstack gap-1 fs-1">
                                {/*{checkPermission('league.view') && (
                                    <Button
                                        aria-label="Edit"
                                        onClick={() => handleView(row)}
                                        className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                    >
                                        <i className="ri-eye-line" />
                                    </Button>
                                )}*/}
                                {checkPermission('league.edit') && (
                                    <Button
                                        aria-label="Edit"
                                        onClick={() => handleEdit(row)}
                                        className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                    >
                                        <i className="ri-edit-line" />
                                    </Button>
                                )}
                                {checkPermission('league.delete') && (
                                    <a
                                        aria-label="Delete"
                                        onClick={() => handleRemove(row)}
                                        className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                                    >
                                        <i className="ri-delete-bin-7-line"/>
                                    </a>
                                )}
                            </div>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        if (state) {
            console.log('state', state)
            handleView(state)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])

    return (
        <>
            <PageHeader name="League" />
            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <SettingButtons />
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex">
                                        {checkPermission('league.store') && (
                                            <Button className="btn btn-primary" onClick={handleAdd}>
                                                Create League
                                            </Button>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-5 p-0">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center" style={{height: 100}}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>

                        {isShow && (
                            <AddLeaguesModal
                                show={isShow}
                                handleClose={() => setIsShow(false)}
                                isEdit={isEdit}
                            />
                        )}
                        {/*<EditLeaguesModal
                            show={isEdit}
                            handleClose={() => setIsEdit(false)}
                        />*/}
                        {isView && (
                            <ViewModal
                                show={isView}
                                handleClose={() => setIsView(false)}
                                isEdit={isEdit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeaguesPage;