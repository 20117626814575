import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import {Accordion, Form} from "react-bootstrap";
import {onInputChangeHandler} from "../../helpers";
import {useGetLinksQuery} from "../../redux/services/RoleLinkService";
import {useAddRoleMutation} from "../../redux/services/RolesService";
import {sweetAlert} from "../../utils";
import {responseError} from "../../helpers/responseHandler";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";

const CreateRolePage = () => {

    const navigate = useNavigate();
    const {data, error, isLoading, isSuccess} = useGetLinksQuery();
    const [addRequest, {isLoading: addLoading}] = useAddRoleMutation();
    const inital = {
        name: '',
        links: []
    };
    const [form, setForm] = useState({...inital});

    const groups = () => {
        return data?.response?.data?.filter(
            (value, index, self) => index === self.findIndex((selfIndex) => {
                return value?.parent_slug === selfIndex?.parent_slug
            })
        ) || [];
    }

    const addRoleHandler = (e) => {
        if (!form?.links?.find((id) => id === e.target.value) && e.target.checked) {
            setForm((prevState) => {
                return {
                    ...prevState,
                    links: [...prevState?.links, e.target.value]
                }
            });
        } else {
            setForm((prevState) => {
                return {
                    ...prevState,
                    links: [...prevState?.links?.filter((val => val !== e.target.value))]
                }
            });
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        await addRequest(form).unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    setForm({...inital});
                    navigate('/roles');

                }
            })
            .catch((err) => {
                responseError(err);
            })
    }

    return (
        <>
            <PageHeader name="Create Role"/>

            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex gap-1">
                                        <Link
                                            to="/roles"
                                            className="btn btn-dark"
                                        >
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="col-auto p-0 d-flex gap-1">
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                onSubmit(e)
                                                // document.querySelector('#form').submit();
                                            }} className="btn btn-primary form-control">
                                            Save
                                            {addLoading && (
                                                <>
                                                    &nbsp;
                                                    <span className="loading">
                                                        <i className="ri-loader-2-fill fs-16"/>
                                                    </span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {isLoading ? (
                                    <>
                                        <PlaceholderLoading/>
                                    </>
                                ) : (
                                    <>
                                        {/*<Form>*/}
                                        <Form.Group className="mb-3">
                                            <Form.Label>Role Name:</Form.Label>
                                            <input
                                                className={"form-control"}
                                                name={"name"}
                                                type="text"
                                                value={form?.name}
                                                onChange={(e) => onInputChangeHandler(e, setForm)}
                                                required={true}
                                                placeholder="Enter Role Name"
                                            />
                                        </Form.Group>
                                        {/*</Form>*/}

                                        <Form.Label>Select Permission:</Form.Label>

                                        <Accordion defaultActiveKey="0">
                                            {
                                                groups()?.map((val, key) => (
                                                    <React.Fragment key={key}>
                                                        <Accordion.Item eventKey={key}>
                                                            <Accordion.Header>{val?.parent_slug}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {
                                                                    data?.response?.data
                                                                        ?.filter(value => value?.parent_slug === val?.parent_slug)
                                                                        ?.map((childValue, childKey) => (
                                                                            <div key={`${key}${childKey}`} className="mb-3">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    onClick={addRoleHandler}
                                                                                    value={childValue?.id}
                                                                                    defaultChecked={(form?.links?.find(id => id === childValue?.id)) ? true : false}
                                                                                    id={`default-${childValue?.id}`}
                                                                                    label={`${childValue?.label}`}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </Accordion>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateRolePage;