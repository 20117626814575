import React, { useEffect, useMemo, useState } from 'react';
import PageHeader from "../../components/PageHeader";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { customTableStyles, sweetAlert, sweetAlertConfirmation } from "../../utils";
import UsersModal from "./UsersModal";
import UserEditModal from './EditModal';
import { useGetRoleQuery } from "../../redux/services/RolesService";
import { useGetUserQuery, useRemoveUserMutation } from "../../redux/services/UsersService";
import usePermission from "../../hooks/usePermission";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const UsersPage = () => {
    const { checkPermission } = usePermission()
    const { state } = useLocation()
    const user = useSelector(state => state?.authenticate?.user)
    const [filterText, setFilterText] = useState('')
    const { data: roles, error: roleError, isLoading: isLoadingRole, isSuccess: isSuccessRole } = useGetRoleQuery();
    const { data: users, error, isLoading, isFetching, isSuccess } = useGetUserQuery();
    // delete
    const [removeRequest] = useRemoveUserMutation()
    const [isShow, setIsShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editUserData, setEditUserData] = useState(null);

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row?.name + " " + row?.last_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Gender',
            selector: row => <span className='text-capitalize'>{row?.gender?.toLowerCase()}</span>,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Role',
            selector: row => (parseInt(row.parent) === 0) ? "Admin" : row?.roles?.role?.name,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row?.created_at,
            sortable: true,
        },
        {
            name: 'Added By',
            sortable: true,
            cell: (row) => (
                <span
                    className='badge bg-info'>{(row.added_by) ? row.added_by?.name + " " + row.added_by?.last_name : ''}</span>
            ),
        },
        {
            name: 'Updated At',
            selector: row => row?.updated_at,
            sortable: true,
        },
        {
            name: 'Updated By',
            cell: (row) => (
                <span className='badge bg-info'>
                    {(row.updated_by) ? row.updated_by?.name + " " + row.updated_by?.last_name : ''}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            {/* <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-eye-fill"/>
                            </a> */}
                            {row?.id == user?.id ? '' : (
                                <>
                                    {checkPermission('employees.edit') && (
                                        <Button
                                            aria-label="Edit"
                                            onClick={() => {
                                                setIsEdit(true);
                                                setEditUserData(row);
                                            }}
                                            className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                                        >
                                            <i className="ri-edit-line" />
                                        </Button>
                                    )}
                                    {checkPermission('employees.delete') && (
                                        <a
                                            aria-label="anchor"
                                            onClick={() => handleRemove(row)}
                                            className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                                        >
                                            <i className="ri-delete-bin-7-line" />
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        if (state) {
            console.log('state', state)
            setFilterText(state?.name)
        }
    }, [state]);

    const filteredData = useMemo(() => {
        return users?.response?.data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [users?.response?.data, filterText])



    return (
        <>
            <PageHeader name="Users" />


            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex gap-1">
                                        {checkPermission('employees.add') && (
                                            <button type={"button"} className="btn btn-primary"
                                                onClick={() => setIsShow(true)}>
                                                Create User
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-5 p-0">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary"
                                                id="basic-addon1">
                                                <i className="ri-search-line" />
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData || []}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center"
                                            style={{ height: 100 }}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <UsersModal
                show={isShow}
                roles={roles?.response?.data}
                handleClose={() => setIsShow(false)}
            />

            <UserEditModal
                show={isEdit}
                data={editUserData}
                setData={setEditUserData}
                roles={roles?.response?.data}
                handleClose={() => setIsEdit(false)}
            />
        </>
    );
};

export default UsersPage;