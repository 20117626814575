import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import Info from "./Info";
import {useGetProfileQuery} from "../../redux/services/ProfileService";
import {Nav, Tab} from "react-bootstrap";

const Index = () => {
    const [tab, setTab] = useState('1')
    const { data, isLoading, refetch } = useGetProfileQuery()

    const profile = data?.response?.data

    useEffect(() => {
        refetch()
    }, []);

    return (
        <>
            <PageHeader name="Profile"/>

            <div className="row justify-content-center">
                <div className="col-xxl-9 col-xl-8 col-lg-7 col-md-7">
                    <div className="card">
                        <div className="card-header">
                            <Nav variant="pills" className="nav nav-pills gap-2">
                                <Nav.Item onClick={() => setTab('1')}>
                                    <Nav.Link className={`${tab === '1' ? 'active' : ''}`}>About</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setTab('2')}>
                                    <Nav.Link className={`${tab === '2' ? 'active' : ''}`}>Edit Profile</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setTab('3')}>
                                    <Nav.Link className={`${tab === '3' ? 'active' : ''}`}>Change Password</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="card-body p-0">
                            <Tab.Content>
                                {tab === '1' && (
                                    <Info data={profile} isFetching={isLoading}/>
                                )}
                                {tab === '2' && (
                                    <EditProfile data={profile} isFetching={isLoading}/>
                                )}
                                {tab === '3' && (
                                    <ChangePassword/>
                                )}
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Index;