import { newsApi } from './api'
import {
    CHANGE_PASSWORD_ENDPOINT,
    GET_DASHBOARD_ENDPOINT,
    GET_NOTIFICATION_ENDPOINT,
    Profile_INFO_ENDPOINT,
    Profile_UPDATE_ENDPOINT
} from "./apiConstant";

export const ProfileService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getDashboard: build.query({
            query: () => ({
                url: `${GET_DASHBOARD_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['Profile'],
        }),
        getNotification: build.query({
            query: () => ({
                url: `${GET_NOTIFICATION_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['Profile'],
        }),
        getProfile: build.query({
            query: () => ({
                url: `${Profile_INFO_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['Profile'],
        }),
        editProfile: build.mutation({
            query: (form) => ({
                url: `${Profile_UPDATE_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['Profile'],
        }),
        changePassword: build.mutation({
            query: (form) => ({
                url: `${CHANGE_PASSWORD_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['Profile'],
        }),
    }),
    overrideExisting: false,
})

export const { useGetDashboardQuery, useGetNotificationQuery, useGetProfileQuery, useEditProfileMutation, useChangePasswordMutation } = ProfileService