import React from 'react';
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";

const Info = ({ data, isFetching }) => {

    if (isFetching) {
        return (
            <>
                <PlaceholderLoading />
            </>
        )
    }

    return (
        <>
            <div className="table-responsive p-5">
                <h5 className="mb-3">Personal Info</h5>
                <div className="row">
                    <div className="col-xl-8 ms-3">
                        <div className="row row-sm">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">First Name : </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15">{data?.name || '-'}</span>
                            </div>
                        </div>
                        <div className="row row-sm mt-3">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">Last Name : </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15">{data?.last_name || '-'}</span>
                            </div>
                        </div>
                        <div className="row row-sm mt-3">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">Email : </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15 text-primary">
                                    {data?.email || '-'}
                                </span>
                            </div>
                        </div>
                        <div className="row row-sm mt-3">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">Gender : </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15">{data?.gender || '-'}</span>
                            </div>
                        </div>
                        <div className="row row-sm mt-3">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">Phone : </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15 text-primary">
                                    {data?.phone || '-'}
                                </span>
                            </div>
                        </div>
                        <div className="row row-sm mt-3">
                            <div className="col-md-3">
                                <span className="fw-semibold fs-14">Role: </span>
                            </div>
                            <div className="col-md-9">
                                <span className="fs-15 text-primary">
                                    {data?.parent == '0' ? 'admin' : data?.roles?.role?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Info;