import React, {useEffect, useState} from 'react'
import {MenuButton} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import {sweetAlert} from "../utils";
import {Button, Modal} from "react-bootstrap";
import LoadingButtonWithForm from "./LoadingButtonWithForm";
import {useForm} from "react-hook-form";

// eslint-disable-next-line react/prop-types
const MenuComponent = ({
                           data,
                           selectedData,
                           title,
                           isLoading,
                           selectdId,
                           color,
                           update,
                           id,
                           updateKey,
                           refetch,
                           fetch = null
                       }) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    const [values, setValues] = useState(selectedData || '')
    const [visible, setVisible] = useState(false)

    const onUpdate = async (newId) => {
        const data = {}
        // eslint-disable-next-line react/prop-types
        data['id'] = id
        data[`${updateKey}`] = newId?.id

        await update(data)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    setVisible(false)
                    sweetAlert(res?.message, 'success')
                    refetch()
                    fetch && fetch(id)
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    useEffect(() => {
        setValues(selectedData)
    }, [selectedData])

    useEffect(() => {
        setValues(selectedData)
    }, [])

    useEffect(() => {
        if (selectdId) {
            setValue('id', selectdId)
        }
    }, [selectdId])

    return (
        <>

            <MenuButton
                onClick={() => setVisible(true)}
                className={`btn btn-sm text-white s-12 rounded-pill px-3`}
                style={{backgroundColor: color ? color : 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {selectedData}
            </MenuButton>

            <Modal show={visible} onHide={() => setVisible(false)}>
                <Modal.Header className="border-0">
                    <Modal.Title className="h6 pb-0">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onUpdate)} id="lead-status" className="list-group">
                        {data?.map((item) => (
                            <div className={`list-group-item ${selectdId} ${item?.id == watch('id') ? 'active' : ''}`}
                                 role="button"
                                 key={item?.id}
                                // value={item?.id}
                                 onClick={() => {
                                     setValues(item?.title)
                                     setValue('id', item?.id)
                                 }}
                            >
                                {item?.id == watch('id') && (
                                    <span className="me-2">
                                        <i className={`fa fa-circle s-18`}/>
                                    </span>
                                )}
                                {item?.title}
                                {item?.email && <> <br/> {item?.email} </>}
                            </div>
                        ))}
                    </form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="dark" onClick={() => setVisible(false)}>
                        Cancel
                    </Button>
                    <LoadingButtonWithForm
                        isLoading={isLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default MenuComponent
