import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useForgotMutation} from "../../redux/services/authService";
import {sweetAlert} from "../../utils";
import LoadingButton from "../../components/LoadingButton";

const ResetPage = () => {
    const navigate = useNavigate()
    let {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [forgotRequest, { isLoading }] = useForgotMutation()

    const onForgot = async (data) => {
        await forgotRequest(data)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                }
                if (res?.errors) {
                    Object.entries(res?.errors)?.forEach(([key, value]) => {
                        sweetAlert(value[0], 'error')
                    })
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
            })
    }

    return (
        <>
            <div className="page login-img d-flex">
                {/* CONTAINER OPEN */}
                <div className="d-none">
                    <div className="text-center">
                        <a href="index.html">
                            <img
                                src="build/assets/images/brand/desktop-dark.png"
                                className="header-brand-img m-0"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row justify-content-center mt-4 mx-0">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                            <div className="card shadow-none">
                                <div className="card-body p-sm-6">
                                    <div className="text-center mb-4">
                                        <h4 className="mb-1">Reset Password?</h4>
                                    </div>
                                    <Form onSubmit={handleSubmit(onForgot)} className="row">
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    New Password
                                                </label>
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            className="form-control ms-0"
                                                            type="password"
                                                            placeholder="Enter your Email"
                                                            isInvalid={errors.otp}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mb-3">
                                                <label className="mb-2 fw-500">
                                                    Confirm Password
                                                </label>
                                                <Controller
                                                    name="confirm_password"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            className="form-control ms-0"
                                                            type="password"
                                                            placeholder="Enter your Email"
                                                            isInvalid={errors.otp}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="d-grid mb-3">
                                                <LoadingButton
                                                    isLoading={isLoading}
                                                    name={'Submit'}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <p className="mb-0 tx-14">
                                                    Remembered your password?
                                                    <Link
                                                        to="/sign-in"
                                                        className="tx-primary ms-1 text-decoration-underline"
                                                    >
                                                        Sign In
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CONTAINER CLOSED */}
            </div>

        </>
    );
};

export default ResetPage;