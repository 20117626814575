import {newsApi} from './api'
import {
    ROLE_LINKS_ENDPOINT
} from "./apiConstant";

export const RoleLinkService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getLinks: build.query({
            query: () => ({
                url: `${ROLE_LINKS_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: [],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetLinksQuery,
} = RoleLinkService