import React, {useMemo, useState} from 'react';
import PageHeader from "../../components/PageHeader";
import DataTable from 'react-data-table-component';
import {customTableStyles, sweetAlert, sweetAlertConfirmation} from "../../utils";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import AtivityLogsFilter from "./AtivityLogsFilter";
import {useGetNotesQuery, useRemoveNotesMutation} from "../../redux/services/NotesService";
import {useGetActivityLogQuery} from "../../redux/services/ActivityLogService";


const ActivityLogsPage = () => {
    const navigate = useNavigate()
    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    const [filterText, setFilterText] = useState('')

    const {data: data2, isLoading, isFetching, refetch} = useGetActivityLogQuery()

    const data = data2?.response?.data

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'User',
            selector: row => row?.user?.name,
            sortable: true,
        },
        {
            name: 'Log Message',
            selector: row => row.description || '-',
            sortable: true,
            width: '400px',
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
    ];

    const filteredData = useMemo(() => {
        return data?.filter((item) => {
            return Object?.keys(item)?.some((key) => {
                return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(filterText?.toLowerCase())
            })
        })
    }, [data, filterText])

    return (
        <>
            <PageHeader name="Activity Logs" />


            <div className="main-container container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 ">
                                    <div className="col-auto p-0 ms-1 d-none">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsShow(true)}
                                        >
                                            <i className="ri-filter-2-fill"/>
                                            &nbsp;
                                            Filter
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-auto p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line"/>
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                    progressPending={isFetching || isLoading}
                                    progressComponent={<>
                                        <span className="loading d-flex align-items-center justify-content-center" style={{height: 100}}>
                                            <i className="ri-loader-2-fill fs-25 rotation"></i>
                                        </span>
                                    </>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AtivityLogsFilter
                show={isShow}
                close={() => setIsShow(false)}
            />
        </>
    );
};

export default ActivityLogsPage;