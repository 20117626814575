import React, { useEffect, useMemo, useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from './StepFour';
import StepFive from './StepFive';
import { Button, Form } from "react-bootstrap";
import SearchSelect from '../../../components/SearchSelect';
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { sweetAlert } from "../../../utils";
import {
    useAddTicketsMutation,
    useEditTicketsMutation,
    useViewTicketsQuery
} from "../../../redux/services/TicketsService";
import LoadingButton from "../../../components/LoadingButton";
import { useGetTeamsQuery } from "../../../redux/services/TeamsService";
import { useGetIdentityQuery } from "../../../redux/services/IdentityService";
import { useGetTicketTypeQuery } from "../../../redux/services/SettingTicketTypeService";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useGetLeadStatusQuery } from "../../../redux/services/SettingLeadStatusService";
import { useGetTicketStatusQuery } from "../../../redux/services/SettingTicketStatusService";
import { useGetLeagueQuery } from '../../../redux/services/SettingLeagueService';


const CreateTicketPage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const [hasParking, setHasParking] = useState(false);

    let {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm()
    const [addRequest, { isLoading: isLoadingAdd }] = useAddTicketsMutation()
    const [editRequest, { isLoading: isLoadingEdit }] = useEditTicketsMutation()
    const { data: data2, isLoading } = useViewTicketsQuery({ id: id ? id : 0 })

    const isEdit = data2?.response?.data ? data2?.response?.data : null
    const { data: teamData } = useGetTeamsQuery({ deferredQuery: '' })
    const { data: identityData } = useGetIdentityQuery({ deferredQuery: '' })
    const { data: ticketData } = useGetTicketTypeQuery()
    const { data: statusData } = useGetTicketStatusQuery()
    const { data: leagueData } = useGetLeagueQuery()

    const team = teamData?.response?.data
    const identity = identityData?.response?.data
    const ticketType = ticketData?.response?.data
    const league = leagueData?.response?.data
    const status = statusData?.response?.data

    const handleParkingChange = (e) => {
        setHasParking(e.target.checked);
    };

    const onUpdate = async (data) => {
        data['ticket_type'] = data?.ticket_type?.value
        data['team'] = data?.team?.value
        // data['status'] = data?.status?.value
        data['identify'] = data?.identify?.value
        console.log('ticketCon', data)
        if (id) {
            data['id'] = id
            await editRequest(data)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        if (watch('save_and_new')) {
                            reset()
                        } else {
                            reset()
                            navigate(`/tickets`)
                        }
                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            await addRequest(data)
                .unwrap()
                .then((res) => {
                    if (res?.status) {
                        if (watch('save_and_new')) {
                            reset()
                        } else {
                            reset()
                            navigate(`/tickets`)
                        }
                        sweetAlert(res?.message, 'success')
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }

    const stepForm = [
        {
            id: 1,
            component: (
                <StepOne activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 2,
            component: (
                <StepTwo activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 3,
            component: (
                <StepThree activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 4,
            component: (
                <StepFour activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        },
        {
            id: 5,
            component: (
                <StepFive activeStep={activeStep} setActiveStep={setActiveStep} />
            ),
        }
        // { id: 4, component: <StepOne activeStep={activeStep} /> },
    ];

    const checkHeader = () => {
        if (id) {
            return <PageHeader name="Edit Ticket" />
        } else {
            return <PageHeader name="Create Ticket" />
        }
    }

    useEffect(() => {
        let ticket_cost = (watch('ticket_cost') === "") ? '' : parseInt(watch('ticket_cost'));
        let psl_cost = (watch('psl_cost') === "") ? '' : parseInt(watch('psl_cost'));
        let donation = (watch('donation') === "") ? '' : parseInt(watch('donation'));
        let no_of_game = (watch('no_of_game') === "") ? '' : parseInt(watch('no_of_game'));
        let no_of_seat = (watch('no_of_seat') === "") ? '' : parseInt(watch('no_of_seat'));
        let psl_paid = (watch('psl_paid') === "") ? '' : parseInt(watch('psl_paid'));

        no_of_game = (no_of_game == 0) ? 1 : no_of_game;
        no_of_seat = (no_of_seat == 0) ? 1 : no_of_seat;

        if (psl_cost == 0 && donation == 0) {
            const data = (ticket_cost / no_of_game) / no_of_seat;
            console.log('ghjg', data)
            setValue('cost_per_game', data);
        }
        else if (psl_cost == 0 && donation > 0) {
            const data = (ticket_cost * donation) / (no_of_game * no_of_seat)
            console.log('ghjg', data)
            setValue('cost_per_game', data)
        }
        else if (psl_paid == 1 && donation >= 0) {
            const data = (ticket_cost + psl_cost) / (no_of_game * no_of_seat)
            console.log('ghjg', data)
            setValue('cost_per_game', data)
        }
        else setValue('cost_per_game', 0)
    }, [
        watch('psl_cost'), watch('donation'),
        watch('psl_paid'), watch('ticket_cost'),
        watch('no_of_game'), watch('no_of_seat'),
    ]);

    useEffect(() => {
        let parking_cost = (watch('parking_cost') === "") ? '' : parseInt(watch('parking_cost'));
        let no_of_parking_passes = (watch('no_of_parking_passes') === "") ? '' : parseInt(watch('no_of_parking_passes'));
        let no_of_game = (watch('no_of_game') === "") ? '' : parseInt(watch('no_of_game'));


        if (parking_cost && no_of_parking_passes && no_of_game) {
            const data = parking_cost / (no_of_parking_passes * no_of_game)
            console.log('parking_cost_per_game', data)
            setValue('parking_cost_per_game', data)
        }
    }, [
        watch('parking_cost'),
        watch('no_of_parking_passes'),
        watch('no_of_game'),
    ]);

    if (isLoading && !isEdit) {
        return <div>Loading...</div>
    }

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };



    return (
        <>
            {/* <div className="single-step mt-4"></div> */}
            {checkHeader()}
            <div>
                <div className="row justify-content-center">
                    <div className="col-xl-11">
                        <div className="card custom-card">
                            <Form onSubmit={handleSubmit(onUpdate)} className="card-body">
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Team Info</h5>
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    League:
                                                </Form.Label>
                                                <Controller
                                                    name="league"
                                                    control={control}
                                                    defaultValue={isEdit?.team_relation ? { value: isEdit?.team_relation?.id, label: isEdit?.team_relation?.name } : ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <SearchSelect
                                                            options={league?.map((item) => ({ value: item.id, label: item.name }))}
                                                            field={field}
                                                            placeholder={"Select League"}
                                                        />
                                                    )}
                                                />

                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Team:
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>

                                                <Controller
                                                    name="team"
                                                    control={control}
                                                    defaultValue={isEdit?.team_relation ? { value: isEdit?.team_relation?.id, label: isEdit?.team_relation?.name } : ''}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <SearchSelect
                                                            options={team?.teams?.filter((item) => item.league == watch('league')?.value)?.map((item) => ({ value: item.id, label: item.name }))}
                                                            field={field}
                                                            placeholder={"Select Team"}
                                                        />
                                                    )}
                                                />
                                                {errors?.team && <small className="text-danger small">This Field Is Required</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Identity:
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="identify"
                                                    control={control}
                                                    defaultValue={isEdit?.identity_relation ? { value: isEdit?.identity_relation?.id, label: isEdit?.identity_relation?.name } : ''}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <SearchSelect
                                                            options={identity?.map((item) => ({ value: item.id, label: item.name }))}
                                                            field={field}
                                                            placeholder={"Select Identity"}
                                                        />
                                                    )}
                                                />
                                                {errors?.identify && <small className="text-danger small">This Field Is Required</small>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Ticket Type:
                                                    {true && <span className="text-danger text-bold">*</span>}
                                                </Form.Label>
                                                <Controller
                                                    name="ticket_type"
                                                    control={control}
                                                    defaultValue={isEdit?.ticket_type_relation ? { value: isEdit?.ticket_type_relation?.id, label: isEdit?.ticket_type_relation?.title } : ''}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field }) => (
                                                        <SearchSelect
                                                            options={ticketType?.map((item) => ({ value: item.id, label: item.title }))}
                                                            field={field}
                                                            placeholder={"Select Ticket Type"}
                                                        />
                                                    )}
                                                />
                                                {errors?.ticket_type && <small className="text-danger small">This Field Is Required</small>}
                                            </Form.Group>
                                        </div>
                                        {(watch('identify') || isEdit?.ticket_type_relation?.id) && (
                                            <div className="col-lg-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Real/Fake:</Form.Label>
                                                    <Controller
                                                        name="real_fake"
                                                        control={control}
                                                        defaultValue={isEdit?.real_fake || ''}
                                                        rules={{
                                                            required: false
                                                        }}
                                                        render={({ field }) => (
                                                            <Form.Select {...field}>
                                                                <option value="">Please Select</option>
                                                                <option value="fake">Fake</option>
                                                                <option value="real">Real</option>
                                                            </Form.Select>
                                                        )}
                                                    />
                                                    {errors?.real_fake && <small className="text-danger small">This Field Is Required</small>}
                                                </Form.Group>
                                            </div>
                                        )}

                                        {(watch('real_fake') == 'fake' || isEdit?.real_fake == 'fake') && (
                                            <>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Spoof Address:</Form.Label>
                                                        <Controller
                                                            name="spoof_address"
                                                            control={control}
                                                            defaultValue={isEdit?.spoof_address || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control  {...field} />
                                                            )}
                                                        />
                                                        {errors?.spoof_address && <small className="text-danger small">This Field Is Required</small>}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Spoof Number:</Form.Label>
                                                        <Controller
                                                            name="spoof_number"
                                                            control={control}
                                                            defaultValue={isEdit?.spoof_number || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control {...field} />
                                                            )}
                                                        />
                                                        {errors?.spoof_number && <small className="text-danger small">This Field Is Required</small>}
                                                    </Form.Group>
                                                </div>
                                            </>
                                        )}


                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Ticket Information</h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Account #</Form.Label>
                                                <Controller
                                                    name="account"
                                                    control={control}
                                                    defaultValue={isEdit?.account || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter Account No"
                                                            isInvalid={errors.account}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Paid:</Form.Label>
                                                <Controller
                                                    name="paid"
                                                    control={control}
                                                    defaultValue={isEdit?.paid || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            <Form.Check
                                                                {...field}
                                                                inline
                                                                type="radio"
                                                                label="Yes"
                                                                onChange={() => field.onChange('yes')}
                                                                checked={field.value == 'yes'}
                                                                id={`disabled-default-radio`}
                                                                value={'yes'}
                                                                className='pt-2 ps-3'
                                                            />
                                                            <Form.Check
                                                                {...field}
                                                                inline
                                                                type="radio"
                                                                label="No"
                                                                onChange={() => field.onChange('no')}
                                                                checked={field.value == 'no'}
                                                                id={`disabled-default-radio2`}
                                                                value={'no'}
                                                                className='pt-2 ps-3'
                                                            />
                                                        </>
                                                    )}
                                                />

                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Paid Date:</Form.Label>
                                                <Controller
                                                    name="paid_date"
                                                    control={control}
                                                    defaultValue={isEdit?.paid_date || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="date" placeholder="Enter Paid Date"
                                                            isInvalid={errors.paid_date}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>PO Date:</Form.Label>
                                                <Controller
                                                    name="po_date"
                                                    control={control}
                                                    defaultValue={isEdit?.po_date || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="date" placeholder="Enter PO Date"
                                                            isInvalid={errors.po_date}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Season Ticket Year:</Form.Label>
                                                <Controller
                                                    name="season_ticket_year"
                                                    control={control}
                                                    defaultValue={isEdit?.season_ticket_year || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <>
                                                            <DatePicker
                                                                selected={field.value ? moment(field.value, 'YYYY').toDate() : null}
                                                                onChange={(date) => field.onChange(moment(date).format('YYYY'))}
                                                                renderCustomHeader={({ date, changeYear }) => (
                                                                    <div className="d-flex px-1 justify-content-between align-items-center">
                                                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => changeYear(date.getFullYear() - 1)}>Previous</button>
                                                                        <span>{date.getFullYear()}</span>
                                                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => changeYear(date.getFullYear() + 1)}>Next</button>
                                                                    </div>
                                                                )}
                                                                renderYearContent={renderYearContent}
                                                                showYearPicker
                                                                dateFormat="yyyy"
                                                                className="form-control"
                                                                style={{ width: '100%' }}
                                                            />
                                                            {/*<DatePicker*/}
                                                            {/*    selected={field.value ? moment(field.value, 'YYYY') : null}*/}
                                                            {/*    onChange={(date) => field.onChange(date)}*/}
                                                            {/*    renderYearContent={renderYearContent}*/}
                                                            {/*    showYearPicker*/}
                                                            {/*    dateFormat="yyyy"*/}
                                                            {/*    className="form-control"*/}
                                                            {/*    style={{width: '100%'}}*/}
                                                            {/*/>*/}
                                                            {/*<InputMask
                                                                {...field}
                                                                mask="9999"
                                                                placeholder="YYYY"
                                                                maskChar={null}
                                                                className="form-control"
                                                                // onChange={(e) => console.log('Year:', e.target.value)}
                                                            >
                                                                {(inputProps) => <Form.Control {...inputProps} type="number" min="1900" max="2099" step="1" id="year" isInvalid={errors.season_ticket_year} />}
                                                            </InputMask>*/}
                                                        </>
                                                        // <Form.Control
                                                        //     {...field}
                                                        //     onChange={(e) => {
                                                        //         field.onChange(e.target.value)
                                                        //         setValue('po_date', '')
                                                        //         setValue('paid_date', '')
                                                        //     }}
                                                        //     type="number" min="1900" max="2099" step="1"
                                                        //     placeholder="Enter Season Ticket year"
                                                        //     isInvalid={errors.season_ticket_year}
                                                        // />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Seating Info</h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Section:</Form.Label>
                                                <Controller
                                                    name="section"
                                                    control={control}
                                                    defaultValue={isEdit?.section || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 16,
                                                            message: 'This field should not exceed 16 digits.'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            placeholder="Enter Section"
                                                            isInvalid={errors.section}
                                                        />
                                                    )}
                                                />
                                                {errors?.section && (
                                                    <small className="d-block text-danger">
                                                        {errors?.section?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Row:</Form.Label>
                                                <Controller
                                                    name="row"
                                                    control={control}
                                                    defaultValue={isEdit?.row || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 16,
                                                            message: 'This field should not exceed 16 digits.'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text" placeholder="Enter Row"
                                                            isInvalid={errors.row}
                                                        />
                                                    )}
                                                />
                                                {errors?.row && (
                                                    <small className="d-block text-danger">
                                                        {errors?.row?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Seat #:</Form.Label>
                                                <Controller
                                                    name="seat"
                                                    control={control}
                                                    defaultValue={isEdit?.seat || ''}
                                                    rules={{
                                                        required: false,
                                                        maxLength: {
                                                            value: 16,
                                                            message: 'This field should not exceed 16 digits.'
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text" placeholder="Enter Seat #"
                                                            isInvalid={errors.seat}
                                                        />
                                                    )}
                                                />
                                                {errors?.seat && (
                                                    <small className="d-block text-danger">
                                                        {errors?.seat?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label># of Seats:</Form.Label>
                                                <Controller
                                                    name="no_of_seat"
                                                    control={control}
                                                    defaultValue={isEdit?.no_of_seat || ''}
                                                    rules={{
                                                        required: false,
                                                        min: {
                                                            value: 0,
                                                            message: 'This field cannot be less than 0.'
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter # of Seats"
                                                            isInvalid={errors.no_of_seat}
                                                        />
                                                    )}
                                                />
                                                {errors?.no_of_seat && (
                                                    <small className="d-block text-danger">
                                                        {errors?.no_of_seat?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label># of Games:</Form.Label>
                                                <Controller
                                                    name="no_of_game"
                                                    control={control}
                                                    defaultValue={isEdit?.no_of_game || ''}
                                                    rules={{
                                                        required: false,
                                                        min: {
                                                            value: 0,
                                                            message: 'This field cannot be less than 0.'
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter  # of Games"
                                                            className="mb-1"
                                                            isInvalid={errors.no_of_game}
                                                        />
                                                    )}
                                                />
                                                {errors?.no_of_game && (
                                                    <small className="d-block text-danger">
                                                        {errors?.no_of_game?.message}
                                                    </small>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Parking Info</h5>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3 d-flex align-items-center gap-3">
                                                <Form.Label>Parking?</Form.Label>
                                                <Controller
                                                    name="parking"
                                                    control={control}
                                                    defaultValue={isEdit?.parking ? Boolean(Number(isEdit?.parking)) : ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="checkbox"
                                                            label=""
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                            checked={field.value}
                                                            value={field.value}
                                                            className='pt-2 ps-3'
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/*{console.log('parking', Boolean(isEdit?.parking))}*/}
                                        {(watch('parking') == undefined ? Boolean(isEdit?.parking) : watch('parking')) && (
                                            <>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Parking Location:</Form.Label>
                                                        <Controller
                                                            name="parking_location"
                                                            control={control}
                                                            defaultValue={isEdit?.parking_location || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text" placeholder="Enter Parking Location"
                                                                    isInvalid={errors.parking_location}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label># of Parking Passes:</Form.Label>
                                                        <Controller
                                                            name="no_of_parking_passes"
                                                            control={control}
                                                            defaultValue={isEdit?.no_of_parking_passes || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="number" placeholder="Enter # of Parking Passes"
                                                                    isInvalid={errors.no_of_parking_passes}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Parking Costs:</Form.Label>
                                                        <Controller
                                                            name="parking_cost"
                                                            control={control}
                                                            defaultValue={isEdit?.parking_cost || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="number" placeholder="Enter Parking Costs"
                                                                    isInvalid={errors.parking_cost}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Parking PO:</Form.Label>
                                                        <Controller
                                                            name="parking_po"
                                                            control={control}
                                                            defaultValue={isEdit?.parking_po || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text" placeholder="Enter Parking PO"
                                                                    className="mb-1"
                                                                    isInvalid={errors.parking_po}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Parking PO Date:</Form.Label>
                                                        <Controller
                                                            name="parking_po_date"
                                                            control={control}
                                                            defaultValue={isEdit?.parking_po_date || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="date" placeholder="Enter Parking PO Date"
                                                                    className="mb-1"
                                                                    isInvalid={errors.parking_po_date}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Parking Cost Per Game:</Form.Label>
                                                        <Controller
                                                            name="parking_cost_per_game"
                                                            control={control}
                                                            defaultValue={isEdit?.parking_cost_per_game || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text"
                                                                    className="mb-1 text-bold"
                                                                    style={{ fontWeight: '600' }}
                                                                    isInvalid={errors.parking_cost_per_game}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="single-step mt-4">
                                    <h5 className="text-start">Seating Info</h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Cost per Seat:</Form.Label>
                                                {/* {console.log('a', isEdit?.cost_per_game || ''), Cost per Seat:} */}
                                                <Controller
                                                    name="cost_per_game"
                                                    control={control}
                                                    defaultValue={''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number"
                                                            step="0.01"
                                                            style={{ fontWeight: '600' }}
                                                            isInvalid={errors.cost_per_game}
                                                            disabled
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Ticket Cost:</Form.Label>
                                                <Controller
                                                    name="ticket_cost"
                                                    control={control}
                                                    defaultValue={isEdit?.ticket_cost || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter Ticket Cost"
                                                            isInvalid={errors.ticket_cost}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Donation:</Form.Label>
                                                <Controller
                                                    name="donation"
                                                    control={control}
                                                    defaultValue={isEdit?.donation || ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number" placeholder="Enter Donation"
                                                            isInvalid={errors.donation}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        {/*<div className="col-lg-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Status:</Form.Label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    defaultValue={isEdit?.status ? {
                                                        value: isEdit?.ticket_status_relation?.id,
                                                        label: isEdit?.ticket_status_relation.title
                                                    } : ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({field}) => (
                                                        <SearchSelect
                                                            options={status?.map((item) => ({
                                                                value: item?.id,
                                                                label: item?.title
                                                            }))}
                                                            field={field}
                                                            placeholder={"Enter Status"}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>*/}
                                        <div className="col-lg-12">
                                            <Form.Group className="mb-3 d-flex align-items-center gap-3">
                                                <Form.Label>PSL?</Form.Label>
                                                <Controller
                                                    name="psl"
                                                    control={control}
                                                    defaultValue={isEdit?.psl ? Boolean(isEdit?.psl) : ''}
                                                    rules={{
                                                        required: false
                                                    }}
                                                    render={({ field }) => (
                                                        <Form.Check
                                                            {...field}
                                                            type="checkbox"
                                                            label=""
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                            checked={field.value}
                                                            value={field.value}
                                                            className='pt-2 ps-3'
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>


                                        {(watch('psl') == undefined ? Boolean(isEdit?.psl) : watch('psl')) && (
                                            <>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>PSL Cost:</Form.Label>
                                                        <Controller
                                                            name="psl_cost"
                                                            control={control}
                                                            defaultValue={isEdit?.psl_cost || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="number" placeholder="Enter PSL Cost"
                                                                    isInvalid={errors.psl_cost}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="pslPaid">PSL Paid:</Form.Label>
                                                        <Controller
                                                            name="psl_paid"
                                                            control={control}
                                                            defaultValue={isEdit?.psl_paid || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Select
                                                                    {...field}
                                                                    isInvalid={errors.psl_paid}
                                                                >
                                                                    <option value="">Please Select</option>
                                                                    <option value="1">Yes</option>
                                                                    <option value="0">No</option>
                                                                </Form.Select>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>PSL Paid Date:</Form.Label>
                                                        <Controller
                                                            name="psl_paid_date"
                                                            control={control}
                                                            defaultValue={isEdit?.psl_paid_date || ''}
                                                            rules={{
                                                                required: false
                                                            }}
                                                            render={({ field }) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="date" placeholder="Enter PSL Paid Date"
                                                                    isInvalid={errors.psl_paid_date}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-lg-12 mt-3">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-3 d-flex mb-3">
                                                    <Button
                                                        variant="danger"
                                                        className="text-white mt-auto w-100"
                                                        onClick={() => navigate('/tickets')}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="col-lg-3 d-grid mb-3 ">
                                                    <LoadingButton
                                                        isLoading={isLoadingAdd ? isLoadingAdd : isLoadingEdit}
                                                        name="Save"
                                                        submitting={() => setValue('save_and_new', false)}
                                                    />
                                                </div>
                                                {!id && (
                                                    <div className="col-lg-3 d-grid mb-3 ">
                                                        <LoadingButton
                                                            isLoading={isLoadingAdd ? isLoadingAdd : isLoadingEdit}
                                                            name="Save & New"
                                                            submitting={() => setValue('save_and_new', true)}
                                                            info="btn-info"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step mt-4"></div>
                            </Form>
                        </div>
                    </div>
                </div>


                {/* <Stepper
                    steps={[{ label: 'Team Info' }, { label: 'Ticket Information' }, { label: 'Seating Info' },{ label: 'Seating Info' }, { label: 'Parking Info' },{ label: 'Ticket Cost' }]}
                    styleConfig={{
                        activeBgColor: "rgba(45, 51, 61, 0.6)",
                        activeTextColor: "#fff",
                        inactiveBgColor: "#fff",
                        inactiveTextColor: "#999",
                        completedBgColor: "rgb(45, 51, 61)",
                        completedTextColor: "#fff",
                        size: "2em",
                    }}
                    activeStep={activeStep+1}
                /> */}
            </div>

            {/* {stepForm[activeStep].component} */}
        </>
    );
};

export default CreateTicketPage;