import React from 'react';
import PageHeader from "../../components/PageHeader";
import SettingButtons from "../../components/Group/SettingButtons";

const Settings = () => {
    return (
        <>
            <PageHeader name="Settings"/>
            <div className="main-container container-fluid">
                <SettingButtons />
            </div>
        </>
    );
};

export default Settings;