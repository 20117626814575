import React, {Fragment} from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import {fullName} from "../../utils";
import {useGetTaggingQuery} from "../../redux/services/SettingTaggingService";

const ViewModal = ({ show, handleClose, isEdit }) => {
    const {data: taggingData} = useGetTaggingQuery()

    const tagging = taggingData?.response?.data

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5 text-center">View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <span style={{fontWeight: 'bold'}}>Title</span>
                            <p>{(isEdit?.title ? isEdit?.title : isEdit?.name) || '-'}</p>
                        </div>
                        <div className="col-md-12 mb-2">
                            <span style={{fontWeight: 'bold'}}>Description:</span>
                            <p>{isEdit?.description || '-'}</p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Assigned To:</span>
                            <p>{isEdit?.assign_to?.name || '-'}</p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Priority:</span>
                            <p>
                                <span className='badge bg-success'>
                                    {isEdit?.task_priority || '-'}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Due Date:</span>
                            <p><span className='badge bg-info'>{isEdit?.due_date || '-'}</span></p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Tags</span>
                            <p className='d-flex flex-row flex-wrap gap-2'>
                                {isEdit?.tags?.split(',')?.length > 0 && tagging?.map((item, index) => {
                                    if (isEdit?.tags?.split(',')?.includes(item?.id.toString())) {
                                        return (
                                            <Fragment key={index}>
                                                <span key={index} className='badge bg-warning d-inline-flex'>
                                                    {item?.name}
                                                </span>
                                                <br/>
                                            </Fragment>
                                        )
                                    }
                                })}
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Added By</span>
                            <p>
                                <span className='badge bg-warning'>
                                    {fullName(isEdit?.added_by?.name, isEdit?.added_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated By</span>
                            <p>
                                <span className='badge bg-warning'>
                                    {fullName(isEdit?.updated_by?.name, isEdit?.updated_by?.last_name)}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Created At</span>
                            <p>
                                <span className='badge bg-info'>
                                    {isEdit?.created_at}
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6 mb-2">
                            <span style={{fontWeight: 'bold'}}>Updated At</span>
                            <p>
                                <span className='badge bg-info'>
                                    {isEdit?.updated_at}
                                </span>
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewModal;