import {newsApi} from './api'
import {
    ADD_Ticket_Type_ENDPOINT,
    DELETE_Ticket_Type_ENDPOINT,
    Edit_Ticket_Type_ENDPOINT,
    GET_Ticket_Type_ENDPOINT
} from "./apiConstant";

export const SettingTicketType = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getTicketType: build.query({
            query: () => ({
                url: `${GET_Ticket_Type_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addTicketType: build.mutation({
            query: (form) => ({
                url: `${ADD_Ticket_Type_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editTicketType: build.mutation({
            query: (form) => ({
                url: `${Edit_Ticket_Type_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeTicketType: build.mutation({
            query: (id) => ({
                url: `${DELETE_Ticket_Type_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetTicketTypeQuery,
    useAddTicketTypeMutation,
    useEditTicketTypeMutation,
    useRemoveTicketTypeMutation
} = SettingTicketType