import React from 'react';
import Swal from 'sweetalert2';

export const sweetAlert = async (title, icon, timer = 2000) => {
    Swal.fire({
        title: title,
        icon: icon,
        showConfirmButton: false,
        backdrop: true,
        // position: 'top-right',
        timer: timer
    });
};

export const deleteConfirmation = (request) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
        if (result.isConfirmed) {
            showLoader();
            request()
                .then(() => {
                    Swal.fire(
                        'Deleted!',
                        'Your record has been deleted.',
                        'success'
                    );
                    hideLoader();
                })
                .catch(() => hideLoader());
            // setTimeout(() => {
            //     Swal.fire(
            //         'Deleted!',
            //         'Your record has been deleted.',
            //         'success'
            //     );
            //     hideLoader();
            // }, 2000);
        }
    });
}

function showLoader() {
    Swal.fire({
        title: 'Deleting this record please wait ...',
        html: '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
    });
}

function hideLoader() {
    Swal.close();
}


export const badRequestConfirmation = (errors) => {
    let text = '<ul style="list-style-type: circle">';
    errors?.map((val, key) => {
        text += "<li>" + val?.message + '</li>';
        return val;
    })
    text += '</ul>'
    console.log(text);
    return Swal.fire({
        title: 'Validation Error',
        html: text,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#d6306a',
        confirmButtonText: 'Retry'
    });
}

export const authorizationConfirmation = () => {
    return Swal.fire({
        title: 'Session Expired',
        text: 'Your token is expired. Try again to login',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#d63057',
        cancelButtonColor: '#8a8b8d',
        confirmButtonText: 'Try-Again to login',
        cancelButtonText: "Close"
    });
}

export const error500 = (message) => {
    return Swal.fire({
        title: '500 Internal Error',
        text: message,
        icon: 'error',
        showConfirmButton : false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Close'
    });
}

export const sweetAlertConfirmation = async () => {
    return Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete this.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, click it!'
    });
};

export const sweetAlertSuccess = async () => {
    return Swal.fire({
        title: 'Form Submitted',
        text: "Form Submited succesfully",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, click it!'
    });
};


export const fullName = (first, last) => {
    if (first || last) {
        const firstName = first ? first : ''
        const lastName = last ? last : ''
        return firstName + ' ' + lastName
    }
}

export const getCardNumberLength = (creditType) => {
    switch (creditType) {
        case 'amex':
            return 15; // American Express
        default:
            return 16; // Default to 16 digits for other card types
    }
};

export const convertToFormData = (data = {}) => {
    let x = new FormData();
    Object?.entries(data)?.map(([key, val]) => {
        x.append(key, val)
    })
    return x
}

export function getCurrentDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
}

export function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const customTableStyles = {
    header: {
        style: {minHeight: '56px'},
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#ddd',
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: 'rgba(0,0,0,.12)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: 'rgba(0,0,0,.12)',
            },
        },
    },
}