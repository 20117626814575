import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {sweetAlert} from "../../utils";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import {useAddTaggingMutation, useEditTaggingMutation} from "../../redux/services/SettingTaggingService";

const TaggingCreateModal = ({show, handleClose, isEdit}) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm()
    // add
    const [addRequest, {isLoading: addLoading}] = useAddTaggingMutation()
    // Edit
    const [editRequest, {isLoading: editLoading}] = useEditTaggingMutation()

    const onSubmit = async (data) => {
        if (isEdit) {
            await editRequest({
                id: isEdit?.id,
                ...data,
            }).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            await addRequest(data).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">{isEdit ? 'Edit' : 'Create'} Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} id="lead-status">
                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={isEdit?.name || ''}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <Form.Control
                                        {...field}
                                        type="text"
                                        placeholder="Enter name"
                                        isInvalid={errors.name}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <LoadingButtonWithForm
                        isLoading={editLoading ? editLoading : addLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TaggingCreateModal;