import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PageHeader from "../../components/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
import {
    useChangeLeadStatusMutation,
    useGetPipelineStatusQuery
} from "../../redux/services/SettingLeadStatusService";
import { sweetAlert, sweetAlertConfirmation } from "../../utils";
import { useGetLeadQuery, useRemoveLeadMutation } from "../../redux/services/LeadService";
import LeadsSide from "../LeadListPage/LeadsViewSIde";
import PlaceholderLoading from "../../components/Group/PlaceholderLoading";


const itemsFromBackend = [
    {
        id: uuid(),
        content: {
            lastContact: '11 months ago',
            created: '11 months ago',
            file: 2,
            notes: 3,
            source: 'google',
        },
    },
    {
        id: uuid(),
        content: {
            lastContact: '11 months ago',
            created: '11 months ago',
            file: 2,
            notes: 3,
            source: 'google',
        },
    },
    {
        id: uuid(),
        content: {
            lastContact: '11 months ago',
            created: '11 months ago',
            file: 2,
            notes: 3,
            source: 'google',
        },
    },
    {
        id: uuid(),
        content: {
            lastContact: '11 months ago',
            created: '11 months ago',
            file: 2,
            notes: 3,
            source: 'google',
        },
    },
    {
        id: uuid(),
        content: {
            lastContact: '11 months ago',
            created: '11 months ago',
            file: 2,
            notes: 3,
            source: 'google',
        },
    },
]

const columnsFromBackend = {
    [uuid()]: {
        name: 'New Leads',
        items: itemsFromBackend,
    },
    [uuid()]: {
        name: 'Contacta Made',
        items: [],
    },
    [uuid()]: {
        name: 'Meeting Set',
        items: [],
    },
    [uuid()]: {
        name: 'New Client',
        items: [],
    },
    [uuid()]: {
        name: 'Under Contract',
        items: [],
    },
    [uuid()]: {
        name: 'Inspection',
        items: [],
    },
    [uuid()]: {
        name: 'CTC',
        items: [],
    },
    [uuid()]: {
        name: 'Closed',
        items: [],
    },
}

const PipelineLeadPage = () => {
    const navigate = useNavigate()
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false)
    // useGetLeadQuery({deferredQuery: ''})
    const { data: data2, refetch, isLoading, isFetching } = useGetPipelineStatusQuery()
    // let pipeline = {};
    //
    // data2?.response?.data.map((item, index) => {
    //     const id = item?.id;
    //     pipeline[id] = {
    //         ...item,
    //     };
    // })
    // const pipeline = data2?.response?.data

    const [columns, setColumns] = useState(data2 || null);

    console.log('res', columns, data2)
    const [updateStatus] = useChangeLeadStatusMutation()
    const [removeRequest] = useRemoveLeadMutation()

    useEffect(() => {
        if (data2) {
            setColumns(data2)
        }
    }, [data2]);

    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return
        const { source, destination } = result

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId]
            const destColumn = columns[destination.droppableId]
            const sourceItems = [...sourceColumn.leads]
            const destItems = [...destColumn.leads]
            const [removed] = sourceItems.splice(source.index, 1)
            destItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    leads: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    leads: destItems,
                },
            })
            updatePipeline(result, columns, setColumns);
        } else {
            const column = columns[source.droppableId]
            const copiedItems = [...column.leads]
            const [removed] = copiedItems.splice(source.index, 1)
            copiedItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    leads: copiedItems,
                },
            })
        }
    }

    const updatePipeline = async (result, columns, setColumns) => {
        const { source, destination } = result;
        let pipelineId = destination.droppableId;
        let leadId = result.draggableId;
        console.log("des", destination, "res", result, "col", columns);
        const data = {}
        // eslint-disable-next-line react/prop-types
        data['id'] = leadId
        data[`status`] = pipelineId

        await updateStatus(data)
            .unwrap()
            .then((res) => {
                if (res?.status) {
                    sweetAlert(res?.message, 'success')
                    refetch()
                }
            })
            .catch((err) => {
                sweetAlert(err?.data?.message, 'error')
                const column = columns[source.droppableId];
                const copiedItems = [...column.leads];
                const [removed] = copiedItems.splice(source.index, 1);
                copiedItems.splice(destination.index, 0, removed);
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...column,
                        leads: copiedItems,
                    },
                });
            })
    };

    const handleRemove = async (item) => {
        sweetAlertConfirmation()
            .then(async (result) => {
                if (result.isConfirmed) {
                    await removeRequest(item?.id)
                        .unwrap()
                        .then((res) => {
                            if (res?.status) {
                                sweetAlert(res?.message, 'success')
                                navigate('/lead/list')
                                refetch()
                                setIsView(false)
                            }
                        })
                        .catch((err) => {
                            sweetAlert(err?.data?.message, 'error')
                        })
                }
            })
    }

    useEffect(() => {
        refetch()
    }, []);

    if ((isFetching && isLoading) || columns == null) {
        return (
            <>
                <PageHeader name="Lead Pipeline" />
                <PlaceholderLoading />
            </>
        )
    }

    return (
        <>

            <PageHeader name="Lead Pipeline" />

            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                {columns && (
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header w-100">
                                    <div className="row w-100 m-0 justify-content-between">
                                        <div className="col-auto p-0 d-flex gap-1">
                                            <h3>Pipeline</h3>
                                        </div>
                                        <div className="col-auto p-0">
                                            <Link
                                                to="/lead/import"
                                                color="primary"
                                                className="btn -btn-primary text-white"
                                            // onClick={() => setVisible(true)}
                                            >
                                                Create Lead
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="row flex-nowrap" key={1}>
                                            <DragDropContext
                                                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                                                {Object?.entries(columns)?.map(([columnId, column], index) => {
                                                    return (
                                                        <>
                                                            <div key={index + 1} className="col-md-4">
                                                                <div className="rounded-0 h-100 shadow-none">
                                                                    <div className="w-100 border-bottom p-3"
                                                                        style={{ backgroundColor: column?.color ? column?.color : '#000' }}>
                                                                        <p className="text-white text-capitalize text-center fw-600 s-16 m-0">
                                                                            {column?.title}
                                                                        </p>
                                                                    </div>
                                                                    <div className="" style={{
                                                                        backgroundColor: '#f8f8f8',
                                                                        height: 500,
                                                                        overflowY: 'auto'
                                                                    }}>
                                                                        <Droppable droppableId={columnId}
                                                                            key={columnId}>
                                                                            {(provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        className="p-3 h-100"
                                                                                        {...provided.droppableProps}
                                                                                        ref={provided.innerRef}
                                                                                    >
                                                                                        {column?.leads?.map((item, index) => {
                                                                                            return (
                                                                                                <Draggable
                                                                                                    key={item?.id}
                                                                                                    draggableId={`${item?.id}`}
                                                                                                    index={index}>
                                                                                                    {(provided, snapshot) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className="mb-3"
                                                                                                                    ref={provided.innerRef}
                                                                                                                    {...provided.draggableProps}
                                                                                                                    {...provided.dragHandleProps}
                                                                                                                    style={{
                                                                                                                        userSelect: 'none',
                                                                                                                        // padding: 16,
                                                                                                                        margin: '0 0 8px 0',
                                                                                                                        minHeight: '50px',
                                                                                                                        // backgroundColor: snapshot.isDragging
                                                                                                                        //     ? "#263B4A"
                                                                                                                        //     : "#456C86",
                                                                                                                        color: 'white',
                                                                                                                        ...provided.draggableProps.style,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className="p-2 bg-white rounded-0 shadow"
                                                                                                                        style={{ borderLeft: `5px solid ${column?.color ? column?.color : '#000'}` }}>
                                                                                                                        <div
                                                                                                                            className="row">
                                                                                                                            <div
                                                                                                                                className="col-12">
                                                                                                                                <div
                                                                                                                                    className="row align-items-end mb-2">
                                                                                                                                    <div
                                                                                                                                        className="col">
                                                                                                                                        {true ? (
                                                                                                                                            <a
                                                                                                                                                type="button"
                                                                                                                                                onClick={() => handleView(item)}
                                                                                                                                                className="s-14 fw-800 mb-3 h6 text-decoration-none"
                                                                                                                                                style={{ color: column?.color ? column?.color : '#000' }}>
                                                                                                                                                # {item?.id} - {' '}
                                                                                                                                                {item?.name}
                                                                                                                                            </a>
                                                                                                                                        ) : (
                                                                                                                                            <a className="s-14 fw-800 mb-3 h6 text-decoration-none"
                                                                                                                                                style={{ color: column?.color ? column?.color : '#000' }}>
                                                                                                                                                # {item?.id} - {' '}
                                                                                                                                                {item?.name}
                                                                                                                                            </a>
                                                                                                                                        )}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <p className="s-14 text-dark d-flex align-items-center">
                                                                                                                                    <i className="ti ti-mail fs-18 me-2 op-7 d-inline-block" />
                                                                                                                                    Email: &nbsp;
                                                                                                                                    <span
                                                                                                                                        style={{ color: '#000' }}>
                                                                                                                                        {item?.email}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                                <p className="s-14 text-dark d-flex align-items-center ">
                                                                                                                                    <i className="ti ti-user fs-18 me-2 op-7 d-inline-block" />
                                                                                                                                    Person
                                                                                                                                    Name: &nbsp;
                                                                                                                                    <span
                                                                                                                                        style={{ color: '#000' }}>
                                                                                                                                        {item?.name}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                                <p className="s-14 text-dark d-flex align-items-center mb-0 d-none">
                                                                                                                                    <i className="ti ti-user-check fs-18 me-2 op-7 d-inline-block" />
                                                                                                                                    Assign
                                                                                                                                    To: &nbsp;
                                                                                                                                    <span
                                                                                                                                        style={{ color: '#000' }}>
                                                                                                                                        Mehtab
                                                                                                                                        {item?.assign_to?.name}
                                                                                                                                    </span>
                                                                                                                                </p>
                                                                                                                            </div>

                                                                                                                            <div
                                                                                                                                className="col-12 text-end mt-2">

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    }}
                                                                                                </Draggable>
                                                                                            )
                                                                                        })}
                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Droppable>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </DragDropContext>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>


            {isView && (
                <LeadsSide
                    show={isView}
                    close={() => setIsView(false)}
                    isEdit={isEdit}
                    remove={handleRemove}
                />
            )}
        </>
    );
};

export default PipelineLeadPage;