import React, { useState } from 'react'
import UsePermission from '../../hooks/usePermission'
import { useGetCalenderQuery } from '../../redux/services/CalenderService'
import moment from 'moment'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const CalendarComponent = () => {
    const { checkPermission } = UsePermission()
    const [isEdit, setIsEdit] = useState(null)
    const { data: data2, isLoading, isFetching, refetch } = useGetCalenderQuery()

    let data = data2?.response?.data?.alerts.concat(data2?.response?.data?.tasks)?.map((item) => {
        return {
            title: item?.title ? item?.title : item?.name,
            start: moment(item?.start_date).format('YYYY-MM-DD'),
            end: moment(item?.start_date).format('YYYY-MM-DD'),
            color: '#00A5A3',
            extraData: { // Attach extra data here
                ...item,
            }
        }
    })
    console.log('data22', data)
    const tasks = [
        {
            title: 'Event 1',
            start: '2024-04-25',
            end: '2024-04-26',
            color: '#00A5A3' // Specify the color for Event 1
        },
        {
            title: 'Event 2',
            start: '2024-03-15',
            end: '2024-03-17',
            color: '#00A5A3' // Specify the color for Event 2
        },
        {
            title: 'Event 3',
            start: '2024-03-25',
            end: '2024-03-28',
            color: '#032d4d' // Specify the color for Event 2
        },
        // Add more tasks as needed
    ];

    const handleView = (item) => {
        setIsEdit(item)
    }

    const eventContent = (arg) => {
        return (
            <>
                {true ? (
                    <div
                        className="custom-event px-2"
                        role="button"
                        // style={{ backgroundColor: arg.event.backgroundColor, borderColor: arg.event.borderColor }}
                        onClick={() => {
                            checkPermission('task.calender-view') && handleView(arg.event.extendedProps.extraData)
                        }}
                    >
                        {console.log('arg', arg.event.extendedProps.extraData)}
                        {arg.event.title}
                    </div>
                ) : (
                    <div
                        className="custom-event"
                    // style={{ backgroundColor: arg.event.backgroundColor, borderColor: arg.event.borderColor }}
                    >
                        {arg.event.title}
                    </div>
                )}
            </>

        )
    }
    return (
        <>
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between border-bottom">
                    <h6 className="card-title">Calendar </h6>
                </div>
                <div className="card-body">
                    <FullCalendar
                        defaultView="dayGridMonth"
                        header={{
                            left: "prev,next",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                        }}
                        plugins={[dayGridPlugin]}
                        events={data}
                        eventContent={eventContent}
                        selectable={true}
                    />
                </div>
            </div>

        </>
    )
}

export default CalendarComponent