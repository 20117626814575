import React from 'react';
import PageHeader from "../../components/PageHeader";
import {customTableStyles} from "../../utils";
import DataTable from "react-data-table-component";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const NewCampaignPage = () => {
    const navigate = useNavigate()
    return (
        <>
            <PageHeader name="New Campaign" />

            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <Form className="row align-items-end">
                                <Form.Group className="mb-3">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Name" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Description" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>

                    <div className="card custom-card">
                        <div className="card-body">
                            <Row>
                                <Col>
                                    <Button
                                        variant="danger"
                                        className="text-white w-100"
                                        onClick={() => navigate('/marketing/campaign-list')}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant="primary" className="text-white w-100">
                                        Save
                                    </Button>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewCampaignPage;