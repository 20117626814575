import {newsApi} from './api'
import {
    GET_Search_ENDPOINT
} from "./apiConstant";

export const SearchService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getSearch: build.query({
            query: (deferredQuery) => ({
                url: `${GET_Search_ENDPOINT}?search=${deferredQuery}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetSearchQuery,
} = SearchService