import React from 'react'
import DataTable from 'react-data-table-component'
import { customTableStyles, fullName } from '../../utils'
import { useGetTasksQuery } from '../../redux/services/TasksService'

const TaskComponent = () => {
    const { data: data2, isLoading, isFetching, refetch } = useGetTasksQuery()
    const data = data2?.response?.data

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Assign To',
            selector: row => row.assign_to?.name,
            sortable: true,
        },
        {
            name: 'Due Date',
            selector: row => row.due_date,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Added By',
            // selector: row => row.addedBy,
            cell: (row) => (
                <span className='badge bg-info'>{fullName(row?.added_by?.name, row?.added_by?.last_name)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        },
        // {
        //     name: 'Updated By',
        //     // selector: row => row.updatedBy,
        //     cell: (row) => (
        //         <span className='badge bg-info'>{fullName(row?.updated_by?.name, row?.updated_by?.last_name)}</span>
        //     ),
        //     sortable: true,
        // },

    ];


    return (
        <>
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between border-bottom">
                    <h6 className="card-title">Recent Task </h6>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data?.slice(0, 5)}
                        customStyles={customTableStyles}
                        pagination={false}
                        progressPending={isFetching || isLoading}
                        progressComponent={<>
                            <span className="loading d-flex align-items-center justify-content-center" style={{ height: 100 }}>
                                <i className="ri-loader-2-fill fs-25 rotation"></i>
                            </span>
                        </>}
                    />
                </div>
            </div>
        </>
    )
}

export default TaskComponent