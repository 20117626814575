import {newsApi} from './api'
import {
    ADD_ActivityLog_ENDPOINT,
    DELETE_ActivityLog_ENDPOINT,
    Edit_ActivityLog_ENDPOINT,
    GET_ActivityLog_ENDPOINT
} from "./apiConstant";

export const ActivityLogService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getActivityLog: build.query({
            query: () => ({
                url: `${GET_ActivityLog_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addActivityLog: build.mutation({
            query: (form) => ({
                url: `${ADD_ActivityLog_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editActivityLog: build.mutation({
            query: (form) => ({
                url: `${Edit_ActivityLog_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeActivityLog: build.mutation({
            query: (id) => ({
                url: `${DELETE_ActivityLog_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetActivityLogQuery,
    useAddActivityLogMutation,
    useEditActivityLogMutation,
    useRemoveActivityLogMutation
} = ActivityLogService