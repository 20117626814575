import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import TextEditor from "../../components/TextEditor";

const CampaignTemplateViewModal = ({show, handleClose}) => {
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">View Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="d-inline-block">
                                Name:
                            </Form.Label>
                            &nbsp;
                            <span className="badge bg-primary-transparent s-14">
                                Muhammad Shahab
                            </span>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="d-inline-block">
                                Subject:
                            </Form.Label>
                            &nbsp;
                            <span className="badge bg-primary-transparent s-14">
                                React Js Developer
                            </span>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad atque, consequatur cum dolorum exercitationem facilis hic labore nostrum nulla odio, saepe. Asperiores assumenda debitis eos magni modi. Expedita, quam.
                            </p>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CampaignTemplateViewModal;