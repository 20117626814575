import {newsApi} from './api'
import {
    ADD_Lead_ENDPOINT, CONVERT_Lead_ENDPOINT, CSV_Lead_ENDPOINT,
    DELETE_Lead_ENDPOINT,
    Edit_Lead_ENDPOINT, GET_Archive_ENDPOINT,
    GET_Lead_ENDPOINT, IMPORT_Lead_ENDPOINT, VIEW_Lead_ENDPOINT
} from "./apiConstant";

export const LeadService = newsApi.injectEndpoints({
    endpoints: (build) => ({
        csvLead: build.mutation({
            query: (form) => ({
                url: `${CSV_Lead_ENDPOINT}`,
                method: 'POST',
                body: form,

            }),
            invalidatesTags: ['LeadStatus'],
        }),
        importLead: build.mutation({
            query: (form) => ({
                url: `${IMPORT_Lead_ENDPOINT}`,
                method: 'POST',
                body: form,

            }),
            invalidatesTags: ['LeadStatus'],
        }),
        getArchive: build.query({
            query: (data) => ({
                url: `${GET_Archive_ENDPOINT}${data?.deferredQuery}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            providesTags: ['LeadStatus'],
        }),
        convertToIdentity: build.mutation({
            query: (form) => ({
                url: `${CONVERT_Lead_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        getLead: build.query({
            query: (data) => ({
                url: `${GET_Lead_ENDPOINT}${data?.deferredQuery}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        viewLead: build.query({
            query: (form) => ({
                url: `${VIEW_Lead_ENDPOINT}?id=${form?.id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addLead: build.mutation({
            query: (form) => ({
                url: `${ADD_Lead_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editLead: build.mutation({
            query: (form) => ({
                url: `${Edit_Lead_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeLead: build.mutation({
            query: (id) => ({
                url: `${DELETE_Lead_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useCsvLeadMutation,
    useImportLeadMutation,
    useGetArchiveQuery,
    useConvertToIdentityMutation,
    useGetLeadQuery,
    useViewLeadQuery,
    useAddLeadMutation,
    useEditLeadMutation,
    useRemoveLeadMutation
} = LeadService