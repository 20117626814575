import {newsApi} from './api'
import {
    ADD_Tagging_ENDPOINT,
    DELETE_Tagging_ENDPOINT,
    Edit_Tagging_ENDPOINT,
    GET_Tagging_ENDPOINT
} from "./apiConstant";

export const SettingTagging = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getTagging: build.query({
            query: (data) => ({
                url: `${GET_Tagging_ENDPOINT}${data?.deferredQuery ? data?.deferredQuery : ''}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addTagging: build.mutation({
            query: (form) => ({
                url: `${ADD_Tagging_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editTagging: build.mutation({
            query: (form) => ({
                url: `${Edit_Tagging_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeTagging: build.mutation({
            query: (id) => ({
                url: `${DELETE_Tagging_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetTaggingQuery,
    useAddTaggingMutation,
    useEditTaggingMutation,
    useRemoveTaggingMutation
} = SettingTagging