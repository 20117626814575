import React from 'react';
import { Form, Modal } from "react-bootstrap";
import TagsInput from '../../components/TagsInput';
import { Controller, useForm } from "react-hook-form";
import { getCurrentDate, sweetAlert } from "../../utils";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import { useAddTasksMutation, useEditTasksMutation } from "../../redux/services/TasksService";
import { useGetUserQuery } from "../../redux/services/UsersService";
import { useGetTaggingQuery } from "../../redux/services/SettingTaggingService";
import MultiSelect from "../../components/MultiSelect";

const TaskCreateModal = ({ show, handleClose, isEdit }) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm()
    // add
    const [addRequest, { isLoading: addLoading }] = useAddTasksMutation()
    // Edit
    const [editRequest, { isLoading: editLoading }] = useEditTasksMutation()
    const { data: usersData } = useGetUserQuery();
    const { data: taggingData } = useGetTaggingQuery()

    const users = usersData?.response?.data
    const tagging = taggingData?.response?.data

    const onSubmit = async (data) => {
        data['tags'] = data?.tags?.map((item) => (item?.value))?.join(',')
        if (isEdit) {
            await editRequest({
                id: isEdit?.id,
                ...data,
            }).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            await addRequest(data).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">Create Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} id="lead-status">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Title:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={isEdit?.title || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter Title"
                                        isInvalid={errors.title}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Description
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={isEdit?.description || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        as="textarea" rows={3}
                                        placeholder="Enter Description"
                                        isInvalid={errors.description}
                                        style={{ resize: 'none' }}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Assigned To:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="assign_to"
                                control={control}
                                defaultValue={isEdit?.assign_to?.id || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Select
                                        {...field}
                                        isInvalid={errors.assign_to}
                                    >
                                        <option value="">Please Select</option>
                                        {users?.map((item) => (
                                            <option value={item?.id}>{item?.name}{' '}{item?.last_name}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Priority:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="task_priority"
                                control={control}
                                defaultValue={isEdit?.task_priority || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Select
                                        {...field}
                                        isInvalid={errors.task_priority}
                                    >
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Due Date:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="due_date"
                                control={control}
                                defaultValue={isEdit?.due_date || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        type="date" placeholder="Enter alert message"
                                        min={getCurrentDate()}
                                        isInvalid={errors.due_date}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Tags
                            </Form.Label>
                            {/*<Controller
                                name="tags"
                                control={control}
                                defaultValue={isEdit?.tags?.split(',')?.map((item) => ({label: item, value: item})) || []}
                                rules={{
                                    required: true
                                }}
                                render={({field}) => (
                                    <div>
                                        <TagsInput field={field}/>
                                    </div>
                                )}
                            />*/}
                            <Controller
                                name="tags"
                                control={control}
                                defaultValue={tagging?.map((item, index) => {
                                    return isEdit?.tags?.split(',')?.includes(item?.id.toString()) ? { label: item?.name, value: item?.id } : null
                                })}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <MultiSelect
                                        options={tagging?.map((item) => ({ value: item?.id, label: item?.name }))}
                                        field={field}
                                    />
                                )}
                            />
                            {errors?.tags && <small className="smal text-danger">This Field Is Required</small>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <LoadingButtonWithForm
                        isLoading={editLoading ? editLoading : addLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TaskCreateModal;