import React from 'react';
import { Button, Form, Offcanvas } from "react-bootstrap";
import MultiSelect from '../../components/MultiSelect';
import SearchSelect from '../../components/SearchSelect';
import {Controller, useForm} from "react-hook-form";
import {useGetTeamsQuery} from "../../redux/services/TeamsService";
import {useGetIdentityQuery} from "../../redux/services/IdentityService";
import {useGetTicketTypeQuery} from "../../redux/services/SettingTicketTypeService";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";

const TicketFilter = ({ show, close, setQuery }) => {
    let {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const {data: teamData} = useGetTeamsQuery()

    const team = teamData?.response?.data

    const onsubmit = (data) => {
        console.log(data)
        let filter = '?'
        if(data?.team) {
            filter += `team=${data?.team?.value}`
        }
        if(data?.season_ticket_year) {
            filter += `&season_ticket_year=${data?.season_ticket_year}`
        }
        if(data?.pod) {
            filter += `&pod=${data?.pod}`
        }
        if(data?.paid) {
            filter += `&paid=${data?.paid}`
        }
        setQuery(filter)

        close()
    }
    return (
        <>
            <Offcanvas show={show} onHide={close} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Ticket Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit(onsubmit)} id="filter">
                        <Form.Group className="mb-3 d-none">
                            <Form.Label>League:</Form.Label>
                            {/*<SearchSelect*/}
                            {/*    options={team?.map((item) => ({value: item.id, label: item.name}))}*/}
                            {/*    placeholder={"Search by Lead"}*/}
                            {/*/>*/}

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Team:</Form.Label>
                            <Controller
                                name="team"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <SearchSelect
                                        options={team?.teams?.map((item) => ({value: item.id, label: item.name}))}
                                        field={field}
                                        placeholder={"Search by Team"}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" placeholder="Search by Name" />
                        </Form.Group>
                        <Form.Group className="mb-3 d-none">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="text" placeholder="Search by Email" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Season Ticket Year:</Form.Label>
                            <Controller
                                name="season_ticket_year"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by Season Ticket Year" />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>POD:</Form.Label>
                            <Controller
                                name="pod"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control {...field} type="text" placeholder="Search by POD" />
                                )}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Paid:</Form.Label>
                            <Controller
                                name="paid"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Select {...field}>
                                        <option value="paid">Paid</option>
                                        <option value="unpaid">Unpaid</option>
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
                <Offcanvas.Header>
                    <div className="col-6">
                        <Button
                            onClick={() => reset({
                                team:'',
                                season_ticket_year:'',
                                pod: '',
                                paid:'',
                            })}
                            variant="primary"
                            className="text-white w-100 fw-bold"
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="col-6 d-grid">
                        <LoadingButtonWithForm
                            form="filter"
                            isLoading={false}
                            name="Filter"
                        />
                    </div>
                </Offcanvas.Header>
            </Offcanvas>
        </>
    );
};

export default TicketFilter;