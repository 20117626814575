import React, {useState} from 'react';
import PageHeader from "../../components/PageHeader";
import {Link, useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import {customTableStyles} from "../../utils";
import OrdersModal from "./OrdersModal";
import {Button} from "react-bootstrap";

const OrdersPage = () => {
    const navigate = useNavigate()
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Centre',
            selector: row => row.center,
            sortable: true,
        },
        {
            name: 'Network',
            selector: row => row.network,
            sortable: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Business Name',
            selector: row => row.business,
            sortable: true,
            width: '400px',
        },
        {
            name: 'Postcode',
            selector: row => row.postcode,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Box Value',
            selector: row => row.box,
            sortable: true,
        },
        {
            name: 'Date Placed',
            selector: row => row.date_placed,
            sortable: true,
        },
        {
            name: 'Eligibility Date',
            selector: row => row.date_eligible,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.date_eligible,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <div className="hstack gap-1 fs-1">
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-primary-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-eye-fill"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-info-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-edit-line"/>
                            </a>
                            <a
                                aria-label="anchor"
                                href="javascript:void(0);"
                                className="btn btn-icon btn-sm btn-danger-light btn-wave waves-effect waves-light"
                            >
                                <i className="ri-delete-bin-7-line"/>
                            </a>
                        </div>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const data = [
        {
            id: 35569,
            center: 'Telford',
            network: '02',
            mobile: '+9334355455',
            business: 'Chery Townsend ***Resign*** / Writing for Contact - 36 months',
            postcode: 'TR2 5DX',
            type: 'O2 Upgrade',
            box: '$ 179.90',
            date_placed: '14/07/2023',
            date_eligible: '14/07/2024',
        },
        {
            id: 35569,
            center: 'Telford',
            network: '02',
            mobile: '+9334355455',
            business: 'Chery Townsend ***Resign*** / Writing for Contact - 36 months',
            postcode: 'TR2 5DX',
            type: 'O2 Upgrade',
            box: '$ 179.90',
            date_placed: '14/07/2023',
            date_eligible: '14/07/2024',
        },
    ]
    return (
        <>
            <PageHeader name="Orders"/>


            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header w-100">
                                <div className="row w-100 m-0 justify-content-between">
                                    <div className="col-auto p-0 d-flex">
                                        <Button className="btn btn-primary" onClick={() => navigate('/new-order')}>
                                            New Order
                                        </Button>
                                    </div>
                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                        >
                                            <i className="ri-filter-2-fill"/>
                                        </Button>
                                    </div>
                                    <div className="col-auto p-0 ms-1">
                                        <Button
                                            className="btn btn-primary"
                                        >
                                            <i className="ti-export text-white"/>
                                        </Button>
                                    </div>
                                    <div className="col-lg-3 col-5 p-0 ms-auto">
                                        <div className="input-group m-0">
                                            <span
                                                className="input-group-text bg-transparent border text-primary" id="basic-addon1">
                                                <i className="ri-search-line"/>
                                            </span>
                                            <input
                                                className="form-control" type="text" placeholder="Search Here..."
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    customStyles={customTableStyles}
                                    pagination={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<OrdersModal*/}
            {/*    show={isShow}*/}
            {/*    handleClose={() => setIsShow(false)}*/}
            {/*/>*/}
        </>
    );
};

export default OrdersPage;