import React, {useState} from 'react'
import {useGetNotificationQuery} from '../../redux/services/ProfileService'
import ViewModal from "../AlertsPage/View";

const NotificationList = () => {
    const {data} = useGetNotificationQuery()

    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const handleView = (item) => {
        setIsEdit(item)
        setIsView(true)
    }

    const notify = data?.response?.data
    return (
        <>
            {/* PAGE-HEADER */}
            <div className="page-header d-flex align-items-center justify-content-between border-bottom mb-4">
                <h1 className="page-title">Notifications List</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">Pages</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Notifications List
                        </li>
                    </ol>
                </div>
            </div>
            {/* PAGE-HEADER END */}
            {/* CONTAINER */}
            <div className="main-container container-fluid">
                {/* Row */}
                {/* Container */}
                <div className="card">
                    <div className="card-body">
                        <div className="container">
                            <ul className="notification">
                                {notify?.map((item) => {
                                    if (item?.type === "notification") {
                                        return (
                                            <li>
                                                <div className="notification-time">
                                                    <div class="pe-2">
                                                        <span class="avatar avatar-md bg-secondary rounded-circle">
                                                            <i class="ti ti-bell fs-18"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="notification-icon">
                                                    <a href="javascript:void(0);"/>
                                                </div>
                                                <div className="notification-body"
                                                     onClick={() => handleView(item?.refer)}>
                                                    <div className="media mt-0">
                                                        <span class="avatar avatar-md bg-light rounded-circle">
                                                            <i class="ti ti-mail-opened text-dark fs-18"></i>
                                                        </span>
                                                        <div className="media-body ms-3 d-flex">
                                                            <div className="">
                                                                <p className="fs-15 text-dark fw-bold mb-0">
                                                                    {item?.refer?.name}
                                                                </p>
                                                                <p className="mb-0 fs-13 text-dark">
                                                                    {item?.refer?.message}
                                                                </p>
                                                            </div>
                                                            <div className="notify-time">
                                                                <p className="mb-0 text-muted fs-11">
                                                                    {item?.created_at}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <></>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Container */}
                {/* /Row */}
            </div>

            {isView && (
                <>
                    {
                        (isEdit?.type === "notification") &&
                        <ViewModal
                            show={isView}
                            handleClose={() => setIsView(false)}
                            isEdit={isEdit}
                        />
                    }
                </>
            )}
            {/* CONTAINER CLOSED */}
        </>
    )
}

export default NotificationList