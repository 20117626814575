import React from 'react';
import {Button, Form} from "react-bootstrap";

const IdentifyInformationStep = ({activeStep, setActiveStep}) => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="card custom-card">
                        <div className="card-body">
                            <h5 className="text-center">Identify Information</h5>

                            <div className="row">
                                <div className="col-lg-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Name" />
                                    </Form.Group>
                                </div>
                                
                                <div className="col-lg-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Email" />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Phone Number:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Phone Number" />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Gender:</Form.Label>
                                        <Form.Select aria-label="Gender">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                
                                <div className="col-lg-12 mt-5">
                                    <div className="row justify-content-between">
                                    
                                        <div className="col-lg-3 d-flex mb-3">
                                            <Button
                                                variant="primary"
                                                className="text-white mt-auto w-100"
                                                onClick={() => setActiveStep(pre => pre + 1)}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IdentifyInformationStep;