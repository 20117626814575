import React, {useState} from 'react';
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {customTableStyles} from "../../../utils";
import {Button, Col, Form, Row} from "react-bootstrap";
import PageHeader from "../../../components/PageHeader";

const CreateOrder = () => {

    return (
        <>
            <PageHeader name="New Order"/>

            <div className="main-container container-fluid">
                {" "}
                {/* Start::row-1 */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Campaign:</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">Sprint Communication</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mobile Number:</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Centre Network" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Account Details
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Business Name:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Business Type:</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">Sole Trader</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title:</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">Mr</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fore Name:</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Middle Name:</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Sur Name:</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date Of Birth:</Form.Label>
                                            <Form.Control type="date" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Contact Details
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Landline Number:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email Address:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Billing Address
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 1"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 2"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 3"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>City:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Country:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Postcode:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Delivery Address
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 1"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 2"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text"  placeholder="Address 3"/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>City:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Country:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Postcode:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Current Phone Package
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Current Network:</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">EE</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Eligibility Date:</Form.Label>
                                            <Form.Control type="date"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Current Handset:</Form.Label>
                                            <Form.Control type="date"  />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Current Speed per month:</Form.Label>
                                            <Form.Control type="text"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Sale Details
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Sale Type:</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">EE Customer to Business Migration</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>New Headset :</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">Aicatel 1016G</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>New Trafic :</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">EE 24 Months</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>SIM Card Required :</Form.Label>
                                            <Form.Select>
                                                <option value="">Please Select</option>
                                                <option value="">Yes</option>
                                                <option value="">No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Box Value:</Form.Label>
                                            <Form.Control type="number"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card ">
                            <div className="card-header w-100 text-dark">
                                Special Requirements
                            </div>
                            <div className="card-body">
                                <Row className="g-0">
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            {/*<Form.Label>Sale Type:</Form.Label>*/}
                                            <textarea
                                                name=""
                                                id=""
                                                cols="30"
                                                rows="4"
                                                className="form-control"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Button variant="danger" className="text-white w-100">
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-lg-6">
                                        <Button variant="primary" className="text-white w-100">
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CreateOrder;