import {newsApi} from './api'
import {
    ADD_AccountManger_ENDPOINT,
    DELETE_AccountManger_ENDPOINT,
    Edit_AccountManger_ENDPOINT,
    GET_AccountManger_ENDPOINT
} from "./apiConstant";

export const SettingAccountManger = newsApi.injectEndpoints({
    endpoints: (build) => ({
        getAccountManger: build.query({
            query: () => ({
                url: `${GET_AccountManger_ENDPOINT}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            }),
            providesTags: ['LeadStatus'],
        }),
        addAccountManger: build.mutation({
            query: (form) => ({
                url: `${ADD_AccountManger_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        editAccountManger: build.mutation({
            query: (form) => ({
                url: `${Edit_AccountManger_ENDPOINT}`,
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
        removeAccountManger: build.mutation({
            query: (id) => ({
                url: `${DELETE_AccountManger_ENDPOINT}?id=${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }),
            invalidatesTags: ['LeadStatus'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetAccountMangerQuery,
    useAddAccountMangerMutation,
    useEditAccountMangerMutation,
    useRemoveAccountMangerMutation
} = SettingAccountManger