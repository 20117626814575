import React , { useDeferredValue } from 'react';
import PageHeader from "../../components/PageHeader";
import CalendarComponent from './CalendarComponent';
import { Link } from 'react-router-dom';
import DocumentComponent from './DocumentComponent';
import TaskComponent from './TaskComponent';
import {useGetDashboardQuery, useGetNotificationQuery} from "../../redux/services/ProfileService";
import {useGetTasksQuery} from "../../redux/services/TasksService";
import {useGetTicketsQuery} from "../../redux/services/TicketsService";

const DashboardPage = () => {

    const {data} = useGetNotificationQuery()
    const notifications = data?.response?.data;
    const {data : dashboard} = useGetDashboardQuery()
    const dashboardButtonStats = dashboard?.response?.data;

    const [query, setQuery] = React.useState('')
    const deferredQuery = useDeferredValue(query)
    const { data: data2, isLoading, isFetching, refetch } = useGetTicketsQuery({ deferredQuery });
    const ticketStats = data2?.response?.data;

    return (
        <>
            <PageHeader name="Dashboard" />
            <div className="main-container container-fluid">
                {" "}
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                        <div className="card border">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0">Total Cost</p>
                                        <span className="fs-5">
                                            {ticketStats?.header?.total_cost ? parseFloat(ticketStats?.header?.total_cost)?.toFixed(2) : 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-primary-transparent text-primary">
                                            <i className="fe  fs-18">$</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                        <div className="card border">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Seats</p>
                                        <span className="fs-5">
                                            {parseInt(ticketStats?.header?.no_of_seat) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-secondary-transparent text-secondary">
                                            <i className="fe fe-package fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card border">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <p className="mb-0"># of Accounts</p>
                                        <span className="fs-5">
                                            {parseInt(ticketStats?.header?.no_of_account) || 0}
                                        </span>
                                    </div>
                                    <div className="min-w-fit-content ms-3">
                                        <span className="avatar avatar-md br-5 bg-warning-transparent text-warning">
                                            <i className="fe fe-user fs-18" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card bg-primary tx-fixed-white card-bg-img">
                            <Link to="/tickets" className="card-body text-decoration-none position-relative d-flex justify-content-between">
                                <div>
                                    <div className="flex-grow-1">
                                        <p className="mb-4 fs-18 fw-semibold text-white">Total Tickets</p>
                                    </div>
                                    {/* <p className="mb-1 fs-13 op-8">
                                        You have got 5 new offers, Track here
                                        <br /> the Sales data and best deals here.
                                    </p> */}
                                    <span className="fs text-white">
                                        {dashboardButtonStats?.total_tickets?.toLocaleString()}
                                    </span>
                                    {/* <span className="fs-12 op-7 ms-1">
                                        <i className="ti ti-trending-up mx-1" />
                                        0.5%
                                    </span> */}
                                </div>
                                <div className="min-w-fit-content">
                                    <span className="avatar avatar-xxl bg-transparent">
                                        <img src="6.png" className="op-7" alt="img" />
                                    </span>
                                </div>
                            </Link>
                        </div>

                    </div>
                    <div className="col-lg-3">
                        <div className="card bg-success tx-fixed-white card-bg-img">
                            <Link to="/teams" className="card-body position-relative d-flex justify-content-between">
                                <div>
                                    <div className="flex-grow-1">
                                        <p className="mb-4 fs-18 fw-semibold text-white">Total Teams</p>
                                    </div>
                                    {/* <p className="mb-1 fs-13 op-8">
                                        You have got 5 new offers, Track here
                                        <br /> the Sales data and best deals here.
                                    </p> */}
                                    <span className="fs text-white">
                                        {dashboardButtonStats?.total_teams?.toLocaleString()}
                                    </span>
                                    {/* <span className="fs-12 op-7 ms-1">
                                        <i className="ti ti-trending-up mx-1" />
                                        0.5%
                                    </span> */}
                                </div>
                                <div className="min-w-fit-content">
                                    <span className="avatar avatar-xxl bg-transparent">
                                        <img src="6.png" className="op-7" alt="img" />
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card bg-warning tx-fixed-white card-bg-img">
                            <Link to="/identifies/list" className="card-body position-relative d-flex justify-content-between">
                                <div>
                                    <div className="flex-grow-1">
                                        <p className="mb-4 fs-18 fw-semibold text-white">Total Identities</p>
                                    </div>
                                    {/* <p className="mb-1 fs-13 op-8">
                                        You have got 5 new offers, Track here
                                        <br /> the Sales data and best deals here.
                                    </p> */}
                                    <span className="fs text-white">
                                        {dashboardButtonStats?.total_identity?.toLocaleString()}
                                    </span>
                                    {/* <span className="fs-12 op-7 ms-1">
                                        <i className="ti ti-trending-up mx-1" />
                                        0.5%
                                    </span> */}
                                </div>
                                <div className="min-w-fit-content">
                                    <span className="avatar avatar-xxl bg-transparent">
                                        <img src="6.png" className="op-7" alt="img" />
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card bg-info tx-fixed-white card-bg-img">
                            <Link to="/documents" className="card-body position-relative d-flex justify-content-between">
                                <div>
                                    <div className="flex-grow-1">
                                        <p className="mb-4 fs-18 fw-semibold text-white">Total Documents</p>
                                    </div>
                                    {/* <p className="mb-1 fs-13 op-8">
                                        You have got 5 new offers, Track here
                                        <br /> the Sales data and best deals here.
                                    </p> */}
                                    <span className="fs text-white">
                                        {dashboardButtonStats?.total_documents?.toLocaleString()}
                                    </span>
                                    {/* <span className="fs-12 op-7 ms-1">
                                        <i className="ti ti-trending-up mx-1" />
                                        0.5%
                                    </span> */}
                                </div>
                                <div className="min-w-fit-content">
                                    <span className="avatar avatar-xxl bg-transparent">
                                        <img src="6.png" className="op-7" alt="img" />
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <CalendarComponent />
                    </div>
                    <div className="col-lg-6">
                        <div className="card h-100">
                            <div className="card-header d-flex align-items-center justify-content-between border-bottom">
                                <h6 className="card-title">Recent Notifications </h6>
                            </div>
                            <div className="card-body">
                                <ul className="mb-0 recent-activity">
                                    {
                                        notifications?.filter((val,key)=> key < 5).map((val,key)=>{
                                            let color = ['danger', 'success', 'warning', 'danger', 'info', 'primary']
                                            if (val?.type === "notification") {
                                                return (
                                                    <li key={key} className={"list-item activity-avatar-before "+ color[Math.floor(Math.random() * 5)] }>
                                                        <div className="d-flex">
                                                            <div className="avatar p-1 avatar-md rounded-circle bg-info-transparent min-w-fit-content">
                                                            <span className={`avatar avatar-sm activity-avatar bg-${color[Math.floor(Math.random() * 5)]} min-w-fit-content rounded-circle cover-image`}>
                                                                <i className="bi bi-clock-history fs-14" />
                                                            </span>
                                                            </div>
                                                            <div className="ms-3 flex-fill">
                                                                <p className="mb-0 fs-14">
                                                                    {val?.refer?.name}
                                                                </p>
                                                                <span className="clearfix" />
                                                                <small className="text-muted fs-12 ">
                                                                    {val?.refer?.message}
                                                                </small>
                                                                <span className="clearfix" />
                                                            </div>
                                                            <div className="ms-auto">
                                                <span className="text-muted fs-12 ms-2 text-truncate">
                                                    <i className="bi bi-clock" />
                                                    {val?.created_at}
                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-6">
                        <DocumentComponent />
                    </div>
                    <div className="col-lg-6">
                        <TaskComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardPage;