import React from 'react';
import {useGetNotificationQuery} from "../../redux/services/ProfileService";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";

const HeaderNotification = () => {

    const {data} = useGetNotificationQuery()

    return (
        <>
            <Dropdown className="header-element dropdown-center notifications-dropdown">
                {/* Start::header-link|dropdown-toggle */}
                <Dropdown.Toggle
                    as={Link}
                    href={void (0)}
                    className="header-link dropdown-toggle"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-link-icon"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                    </svg>
                    <span
                        className="badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary"
                        id="notification-icon-badge"
                    >{data?.response?.data?.filter((val)=>val?.is_read == '0')?.length}</span>
                </Dropdown.Toggle>
                {/* End::header-link|dropdown-toggle */}
                {/* Start::main-header-dropdown */}
                <Dropdown.Menu
                    className="main-header-dropdown dropdown-menu dropdown-menu-end"
                    data-popper-placement="none"
                >
                    <div className="p-3">
                        <div
                            className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 fs-15 fw-semibold">
                                Notifications
                            </p>
                            <a
                                href="javascript:void(0);"
                                className="badge bg-secondary-transparent"
                                id="notifiation-data"
                            >
                                {data?.response?.data?.length} Items
                            </a>
                        </div>
                    </div>
                    {
                        data?.response?.data?.length > 0 ?
                            <>
                                <div className="dropdown-divider my-0"/>

                                <ul className="list-unstyled mb-0">
                                    {
                                        data?.response?.data?.filter((val,key)=>key<5)?.map((val, key) => {
                                            if (val?.type === "notification") {
                                                return (
                                                    <li key={key} className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-start">
                                                            <div className="pe-2">
                                            <span className="avatar avatar-md bg-secondary rounded-circle">
                                                <i className="ti ti-bell fs-18"/>
                                            </span>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <Link to={'/notifications'}
                                                                              className="mb-0 fs-13 font-weight-semibold text-dark"
                                                                        >
                                                                            {val?.refer?.name}
                                                                        </Link>
                                                                        <div className="p-1 text-warning">
                                                                    <span className="fs-12 me-2">
                                                                        {val?.refer?.message}
                                                                    </span>
                                                                        </div>
                                                                    </div>
                                                                    <a href="javascript:void(0);"
                                                                       className="min-w-fit-content text-muted text-opacity-75 ms-1 dropdown-item-close1">
                                                                        <i class="ti ti-mail text-dark fs-16"></i>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })
                                    }
                                </ul>
                                {/* <div className="dropdown-divider"></div> */}
                                <div className="p-3 empty-header-item1">
                                    <div className="d-grid">
                                        <Link
                                            to="/notifications"
                                            className="btn btn-primary"
                                        >
                                            View All
                                        </Link>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="p-5 empty-item1">
                                    <div className="text-center">
                                <span className="avatar avatar-xl rounded-2 bg-secondary-transparent">
                                    <i className="ri-notification-off-line fs-2"/>
                                </span>
                                        <h6 className="fw-semibold mt-3">
                                            No New Notifications
                                        </h6>
                                    </div>
                                </div>
                            </>
                    }

                </Dropdown.Menu>
                {/* End::main-header-dropdown */}
            </Dropdown>
        </>
    );
};

export default HeaderNotification;