import React, { useEffect } from 'react';
import Layout from './components/Layout'
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Index from "./pages/DashboardPage";
import ImportLeadPage from "./pages/ImportLeadPage";
import LeadListPage from "./pages/LeadListPage";
import ArchiveLeadPage from "./pages/ArchiveLeadPage";
import PipelineLeadPage from "./pages/PipelineLeadPage";
import LeadStatusPage from "./pages/LeadStatusPage";
import BusinessTypePage from "./pages/BusinessTypePage";
import NetworksPage from "./pages/NetworksPage";
import SaleTypePage from "./pages/SaleTypePage";
import HeadSetPage from "./pages/HeadSetPage";
import TarrifPage from "./pages/TarrifPage";
import CreateLeadPage from "./pages/CreateLeadPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPage from "./pages/ForgotPage";
import UsersPage from "./pages/UsersPage";
import RolesPage from "./pages/RolesPage";
import CreateRolePage from "./pages/CreateRolePage";
import ImportClientPage from "./pages/ImportClientPage";
import ClientListPage from "./pages/ClientListPage";
import PipelineClientPage from "./pages/PipelineClientPage";
import TrainingPage from "./pages/TrainingPage";
import OrdersPage from "./pages/OrdersPage";
import NewCampaignPage from "./pages/NewCampaignPage";
import CampaignListPage from "./pages/CampaignListPage";
import CompaignPage from "./pages/CompaignPage";
import CampaignTemplatePage from "./pages/CampaignTemplatePage";
import ChatPage from "./pages/ChatPage";
import Group from "./components/Group/Group";
import Profile from "./components/Group/Profile";
import Notes from "./components/Group/Notes";
import Invoice from "./components/Group/Invoice";
import Tasks from "./components/Group/Tasks";
import Group2 from "./components/Group/Group2";
import ClientStatusPage from "./pages/ClientStatusPage";
import ActivityPage from "./pages/ActivityPage";
import ActivityLogsPage from "./pages/ActivityLogsPage";
import Campaign from "./components/Group/Campaign";
import Documents from "./components/Group/Documents";
import Logs from "./components/Group/Logs";
import Order from "./components/Group/Order";
import NewOrder from "./pages/OrdersPage/NewOrder";
import CreateOrder from "./components/Group/Order/CreateOrder";
import ProfilePage from "./pages/ProfilePage";
import OrderStatusPage from "./pages/OrderStatusPage";
import VerifyPage from "./pages/VerifyPage";
import ResetPage from "./pages/ResetPage";

// Current Routes
import AlertsPage from './pages/AlertsPage';
import TaggingPage from './pages/TaggingPage';
import AccountManagerPage from './pages/AcccountManagerPage';
import TicketTypePage from './pages/TicketTypePage';
import TicketStatusPage from './pages/TicketStatusPage';

import LeaguesPage from './pages/LeaguesPage';
import IdentifiesListPage from './pages/IdentitiesPage';
import CreateIdentifyPage from './pages/IdentitiesPage/Create';
import TasksPage from './pages/TasksPage';
import NotesPage from './pages/NotesPage';
import DocumentsPage from './pages/DocumentsPage';
import CalendarsPage from './pages/CalendarPage';
import EditRolePage from './pages/EditRolePage';
import TeamsPage from './pages/TeamsPage';
import TicketsPage from './pages/TicketsPage';
import CreateTicketPage from './pages/TicketsPage/CreateTicketPage';
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import usePermission from "./hooks/usePermission";
import { useGetTaggingQuery } from "./redux/services/SettingTaggingService";
import ImportTicketPage from "./pages/ImportTicketPage";
import SettingButtons from "./components/Group/SettingButtons";
import NotificationList from './pages/notificationlist';
import Settings from "./pages/Settings";

function App() {
    const { checkPermission } = usePermission()
    const { refetch } = useGetTaggingQuery({ deferredQuery: '' })

    useEffect(() => {
        refetch()
    }, []);

    return (
        <>
            <BrowserRouter>

                <Routes>
                    <Route path="/sign-in" element={<PublicRoute component={SignInPage} />} />
                    <Route path="/sign-up" element={<PublicRoute component={SignUpPage} />} />
                    <Route path="/forgot-password" element={<PublicRoute component={ForgotPage} />} />
                    <Route path="/verify-otp" element={<PublicRoute component={VerifyPage} />} />
                    <Route path="/reset-password" element={<PublicRoute component={ResetPage} />} />

                    <Route path="/" element={<PrivateRoute component={Layout} />}>

                        <Route index element={<Index />} />

                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/notifications" element={<NotificationList />} />

                        <Route path="lead" element={<><Outlet /></>}>
                            {checkPermission('lead.store') && (
                                <Route path="create" element={<CreateLeadPage />} />
                            )}
                            {checkPermission('lead.upload-csv') && (
                                <Route path="import" element={<ImportLeadPage />} />
                            )}
                            {checkPermission('lead.edit') && (
                                <Route path="edit/:id" element={<CreateLeadPage />} />
                            )}
                            {checkPermission('lead.get') && (
                                <Route path="list" element={<LeadListPage />} />
                            )}
                            {checkPermission('lead.get-archive') && (
                                <Route path="archive" element={<ArchiveLeadPage />} />
                            )}
                            {(checkPermission('lead.get-archive') || checkPermission('lead.get')) && (
                                <Route path="pipeline" element={<PipelineLeadPage />} />
                            )}
                            <Route path="group" element={<Group />}>
                                <Route path="profile" element={<Profile />} />
                                <Route path="notes" element={<Notes />} />
                                <Route path="invoice" element={<Invoice />} />
                                <Route path="tasks" element={<Tasks />} />
                                <Route path="campaign" element={<Campaign />} />
                                <Route path="documents" element={<Documents />} />
                                <Route path="logs" element={<Logs />} />
                                <Route path="order" element={<Order />} />
                            </Route>
                            <Route path="new-order" element={<CreateOrder />} />
                        </Route>

                        <Route path="identifies" element={<><Outlet /></>}>
                            {checkPermission('identity.get') && (
                                <Route path="list" element={<IdentifiesListPage />} />
                            )}
                            {checkPermission('identity.store') && (
                                <Route path="create" element={<CreateIdentifyPage />} />
                            )}
                            {checkPermission('identity.edit') && (
                                <Route path="edit/:id" element={<CreateIdentifyPage />} />
                            )}
                            {checkPermission('lead.store') && (
                                <Route path="import" element={<ImportClientPage />} />
                            )}
                        </Route>

                        {checkPermission('ticket.get') && (
                            <Route path="/tickets" element={<TicketsPage />} />
                        )}
                        {checkPermission('ticket.upload-csv') && (
                            <Route path="/import-ticket" element={<ImportTicketPage />} />
                        )}
                        {checkPermission('ticket.store') && (
                            <Route path="/create-ticket" element={<CreateTicketPage />} />
                        )}
                        {checkPermission('ticket.edit') && (
                            <Route path="/edit-ticket/:id" element={<CreateTicketPage />} />
                        )}

                        {checkPermission('team.get') && (
                            <Route path="/teams" element={<TeamsPage />} />
                        )}

                        {checkPermission('logs.get-logs') && (
                            <Route path="/activity-logs" element={<ActivityLogsPage />} />
                        )}

                        {checkPermission('employees.index') && (
                            <Route path="/users" element={<UsersPage />} />
                        )}

                        {checkPermission('roles.index') && (
                            <Route path="/roles" element={<RolesPage />} />
                        )}

                        {checkPermission('roles.add') && (
                            <Route path="/create-role" element={<CreateRolePage />} />
                        )}

                        {checkPermission('roles.edit') && (
                            <Route path="/edit-role/:id" element={<EditRolePage />} />
                        )}

                        {checkPermission('task.get') && (
                            <Route path="tasks" element={<TasksPage />} />
                        )}


                        {checkPermission('notes.get') && (
                            <Route path="notes" element={<NotesPage />} />
                        )}
                        {checkPermission('document.get') && (
                            <Route path="documents" element={<DocumentsPage />} />
                        )}
                        {checkPermission('task.get-calender') && (
                            <Route path="calendar" element={<CalendarsPage />} />
                        )}


                        <Route path="setting" element={<><Outlet /></>}>

                            <Route path="index" element={<Settings />} />

                            {checkPermission('lead-status.get') && (
                                <Route path="lead-status" element={<LeadStatusPage />} />
                            )}
                            {checkPermission('ticket-status.get') && (
                                <Route path="ticket-status" element={<TicketStatusPage />} />
                            )}
                            {checkPermission('ticket-type.get') && (
                                <Route path="ticket-page" element={<TicketTypePage />} />
                            )}
                            {checkPermission('tagging.get') && (
                                <Route path="tagging" element={<TaggingPage />} />
                            )}
                            {checkPermission('account-manager.get') && (
                                <Route path="account-manager" element={<AccountManagerPage />} />
                            )}
                            {checkPermission('alert.get') && (
                                <Route path="alerts" element={<AlertsPage />} />
                            )}
                            {checkPermission('league.get') && (
                                <Route path="leagues" element={<LeaguesPage />} />
                            )}


                            <Route path="client-status" element={<ClientStatusPage />} />
                            <Route path="order-status" element={<OrderStatusPage />} />
                            <Route path="compaigns" element={<CompaignPage />} />
                            <Route path="business-type" element={<BusinessTypePage />} />
                            <Route path="networks" element={<NetworksPage />} />
                            <Route path="sale-type" element={<SaleTypePage />} />
                            <Route path="head-set" element={<HeadSetPage />} />
                            <Route path="tarrif" element={<TarrifPage />} />
                        </Route>


                        {/*old components*/}
                        <Route path="client" element={<><Outlet /></>}>
                            <Route path="create" element={<CreateLeadPage />} />
                            <Route path="list" element={<ClientListPage />} />
                            <Route path="pipeline" element={<PipelineClientPage />} />
                            <Route path="group" element={<Group2 />}>
                                <Route path="profile" element={<Profile />} />
                                <Route path="notes" element={<Notes />} />
                                <Route path="invoice" element={<Invoice />} />
                                <Route path="tasks" element={<Tasks />} />
                                <Route path="campaign" element={<Campaign />} />
                                <Route path="documents" element={<Documents />} />
                                <Route path="logs" element={<Logs />} />
                                <Route path="order" element={<Order />} />
                            </Route>
                            <Route path="new-order" element={<CreateOrder />} />
                        </Route>

                        <Route path="marketing" element={<><Outlet /></>}>
                            <Route path="new-campaign" element={<NewCampaignPage />} />
                            <Route path="campaign-list" element={<CampaignListPage />} />

                            <Route path="activity-list" element={<ActivityPage />} />
                            <Route path="campaign-template" element={<CampaignTemplatePage />} />
                        </Route>


                        <Route path="/chat" element={<ChatPage />} />

                        <Route path="/training" element={<TrainingPage />} />

                        <Route path="/orders" element={<OrdersPage />} />
                        <Route path="/new-order" element={<NewOrder />} />
                        {/*<Route path="calendar" element={<CalendarsPage />} />*/}
                    </Route>
                </Routes >
            </BrowserRouter >
        </>
    );
}

export default App;
