import React, { useEffect, useState } from 'react';
import user from '../assets/images/21.jpg'
import { Link, NavLink, Outlet, Route, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logo from '../assets/images/logo5.png'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import usePermission from "../hooks/usePermission";
import ImportLeadPage from "../pages/ImportLeadPage";
import PipelineLeadPage from "../pages/PipelineLeadPage";
import IdentifiesListPage from "../pages/IdentitiesPage";
import TeamsPage from "../pages/TeamsPage";
import TicketsPage from "../pages/TicketsPage";
import SearchModal from "./SearchModal";
import HeaderNotification from "./Group/HeaderNotification";

const Layout = () => {
    const { checkPermission } = usePermission()
    const navigate = useNavigate()
    const user = useSelector(state => state?.authenticate?.user)
    const dispatch = useDispatch()
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        let elements = document.querySelectorAll('#sidebar-scroll nav ul li.slide.has-sub');

        const handler = (element) => {
            const slideMenu = element.querySelector('.slide-menu')

            if (element.classList.contains('open')) {
                console.log('yes')
                slideMenu.classList.remove('d-block');
                element.classList.remove('open');
            } else {
                console.log('no')
                slideMenu.classList.add('d-block');
                element.classList.add('open');
            }
        }

        elements.forEach(function (element) {
            // Add a click event listener
            element.querySelector('.side-menu__item').addEventListener('click', function () {
                console.log('ss', element.classList.contains('open'))
                handler(element)
            });
        });

        return () => {
            elements.forEach(function (element) {
                // Add a click event listener
                element.querySelector('.side-menu__item').addEventListener('click', function () {
                    console.log('ss', element.classList.contains('open'))
                    handler(element)
                });
            });
        }
    }, []);


    useEffect(() => {
        let elements = document.querySelectorAll('#sidebar-scroll nav ul li a.side-menu__item');
        let overlay = document.querySelector("#responsive-overlay");
        let htmlTag = document.querySelector("html")

        elements.forEach(function (element) {
            element.addEventListener('click', function () {
                console.log('ele', element)
                setTimeout(() => {
                    if (element.classList.contains('active')) {
                        htmlTag.setAttribute("data-toggled", "close")
                        overlay.classList.remove("active")
                    }
                }, 300)
            });

        });
    }, []);

    useEffect(() => {
        const toggle = document.getElementById('sidemenu-toggle');
        let overlay = document.querySelector("#responsive-overlay");

        function handleClick() {
            let htmlTag = document.querySelector("html")
            if (window.innerWidth >= 992) {
                htmlTag.hasAttribute('data-toggled') ? htmlTag.removeAttribute('data-toggled') : htmlTag.setAttribute('data-toggled', 'icon-overlay-close')
            } else {
                if (htmlTag.getAttribute('data-toggled') == 'close') {
                    htmlTag.setAttribute("data-toggled", "open")
                    overlay.classList.add("active")
                } else {
                    htmlTag.setAttribute("data-toggled", "close")
                    overlay.classList.remove("active")
                }
            }
        }

        toggle.addEventListener('click', handleClick);
        overlay.addEventListener('click', handleClick);

        return () => {
            toggle.removeEventListener('click', handleClick);
            overlay.removeEventListener('click', handleClick);
        }

    }, []);

    useEffect(() => {
        // Function to update the windowWidth state
        const updateWindowWidth = () => {
            console.log('resize', window.innerWidth)
            let overlay = document.querySelector("#responsive-overlay");

            if (window.innerWidth >= 992) {
                document.querySelector("html").removeAttribute('data-toggled')
                overlay.classList.remove("active")
            } else {
                document.querySelector("html").setAttribute("data-toggled", "close")
            }
        };

        // Event listener to update the windowWidth state when the window is resized
        window.addEventListener('resize', updateWindowWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);


    return (<>
        {/* PAGE */}
        <div className="page">
            <div className="page-main">
                {/* Main-Header */}
                <header className="app-header header sticky">
                    {/* Start::main-header-container */}
                    <div className="main-header-container container-fluid">
                        {/* Start::header-content-left */}
                        <div className="header-content-left align-items-center">
                            <div className="header-element">
                                <div className="horizontal-logo">
                                    <a href="index.html" className="header-logo">
                                        {/* <img
                                            src={logo}
                                            alt="logo"
                                            className="desktop-logo"
                                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                                        />
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="toggle-logo"
                                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                                        />
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="desktop-dark"
                                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                                        />
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="toggle-dark"
                                            style={{ height: 92, objectFit: 'contain', objectPosition: 'center' }}
                                        /> */}
                                        {/*<h5 className='text-center'>Logo</h5>*/}
                                    </a>
                                </div>
                            </div>
                            {/* Start::header-element */}
                            <div className="header-element">
                                {/* Start::header-link */}
                                <a
                                    href="javascript:void(0);"
                                    className="sidemenu-toggle header-link"
                                    data-bs-toggle="sidebar"
                                    id="sidemenu-toggle"
                                >
                                    <span className="open-toggle">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 0v24H0V0h24z" fill="none" opacity=".87" />
                                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6 1.41-1.41zM6 6h2v12H6V6z" />
                                        </svg>
                                    </span>
                                    <span className="close-toggle">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                            fill="#000000"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                                        </svg>
                                    </span>
                                </a>
                                {/* End::header-link */}
                            </div>
                            {/* End::header-element */}
                            <div className="main-header-center  d-none d-lg-block  header-link">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="typehead"
                                    placeholder="Search for results..."
                                    autoComplete="off"
                                    autoFocus={false}
                                    value={''}
                                    role="button"
                                    onClick={() => setIsShow(true)}
                                />
                                <button className="btn pe-1">
                                    <i className="fe fe-search" aria-hidden="true" />
                                </button>
                                <div id="headersearch" className="header-search">
                                    <div className="p-3">
                                        <div className="">
                                            <p className="fw-semibold text-muted mb-2 fs-13">
                                                Recent Searches
                                            </p>
                                            <div className="ps-2">
                                                <a href="javascript:void(0);" className="search-tags">
                                                    <i className="fe fe-search me-2" />
                                                    People
                                                    <span />
                                                </a>
                                                <a href="javascript:void(0);" className="search-tags">
                                                    <i className="fe fe-search me-2" />
                                                    Pages
                                                    <span />
                                                </a>
                                                <a href="javascript:void(0);" className="search-tags">
                                                    <i className="fe fe-search me-2" />
                                                    Articles
                                                    <span />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p className="fw-semibold text-muted mb-2 fs-13">
                                                Apps and pages
                                            </p>
                                            <ul className="ps-2">
                                                <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                                                    <a href="calendar2.html">
                                                        <span>
                                                            <i className="bi bi-calendar me-2 fs-14 bg-primary-transparent avatar rounded-circle " />
                                                            Calendar
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                                                    <a href="email-inbox.html">
                                                        <span>
                                                            <i className="bi bi-envelope me-2 fs-14 bg-primary-transparent avatar rounded-circle" />
                                                            Mail
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                                                    <a href="buttons.html">
                                                        <span>
                                                            <i className="bi bi-dice-1 me-2 fs-14 bg-primary-transparent avatar rounded-circle " />
                                                            Buttons
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-3">
                                            <p className="fw-semibold text-muted mb-2 fs-13">Links</p>
                                            <ul className="ps-2">
                                                <li className="p-1 align-items-center text-muted mb-1 search-app">
                                                    <a href="javascript:void(0);" className="text-primary">
                                                        <u>http://spruko/html/spruko.com</u>
                                                    </a>
                                                </li>
                                                <li className="p-1 align-items-center text-muted mb-1 search-app">
                                                    <a href="javascript:void(0);" className="text-primary">
                                                        <u>http://spruko/demo/spruko.com</u>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="py-3 border-top px-0">
                                        <div className="text-center">
                                            <a
                                                href="javascript:void(0);"
                                                className="text-primary text-decoration-underline fs-15"
                                            >
                                                View all
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* header search */}
                        </div>
                        {/* End::header-content-left */}
                        {/* Start::header-content-right */}
                        <div className="header-content-right">
                            {/*<button
                                    className="navbar-toggler navresponsive-toggler d-lg-none ms-auto"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent-4"
                                    aria-controls="navbarSupportedContent-4"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon fe fe-more-vertical"/>
                                </button>*/}

                            <div className="d-flex align-items-center">
                                {/* Start::header-element */}
                                {/* Start::country select collapse */}
                                <div className="header-element country-dropdown dropdown-center d-none">
                                    {/* Start::header-link|dropdown-toggle */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src="build/assets/images/flags-img/us_flag.jpg"
                                            className="rounded-circle header-link-icon"
                                            alt="img"
                                        />
                                    </a>
                                    {/* End::header-link|dropdown-toggle */}
                                    {/* Start::main-header-dropdown */}
                                    <div
                                        className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                        data-popper-placement="none"
                                    >
                                        <ul className="mb-0">
                                            <li className="dropdown-item mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/india_flag.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">India</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/malaysia.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">Malaysia</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/italy_flag.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">Italy</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/argentina.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">Argentina</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item active mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/us_flag.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">USA</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-2">
                                                        <img
                                                            src="build/assets/images/flags-img/turkey.jpg"
                                                            className="rounded-circle country-img"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0 fs-15">Turkey</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End::main-header-dropdown */}
                                </div>
                                {/* End::country select collapse */}
                                {/* Start::header-element */}
                                <div className="header-element header-theme-mode d-none">
                                    {/* Start::header-link|layout-setting */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link layout-setting"
                                    >
                                        <span className="light-layout">
                                            {/* Start::header-link-icon */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                enableBackground="new 0 0 24 24"
                                                className="header-link-icon"
                                                viewBox="0 0 24 24"
                                            >
                                                <g>
                                                    <rect fill="none" height={24} width={24} />
                                                </g>
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M19.78,17.51c-2.47,0-6.57-1.33-8.68-5.43C8.77,7.57,10.6,3.6,11.63,2.01C6.27,2.2,1.98,6.59,1.98,12 c0,0.14,0.02,0.28,0.02,0.42C2.61,12.16,3.28,12,3.98,12c0,0,0,0,0,0c0-3.09,1.73-5.77,4.3-7.1C7.78,7.09,7.74,9.94,9.32,13 c1.57,3.04,4.18,4.95,6.8,5.86c-1.23,0.74-2.65,1.15-4.13,1.15c-0.5,0-1-0.05-1.48-0.14c-0.37,0.7-0.94,1.27-1.64,1.64 c0.98,0.32,2.03,0.5,3.11,0.5c3.5,0,6.58-1.8,8.37-4.52C20.18,17.5,19.98,17.51,19.78,17.51z" />
                                                        <path
                                                            d="M7,16l-0.18,0C6.4,14.84,5.3,14,4,14c-1.66,0-3,1.34-3,3s1.34,3,3,3c0.62,0,2.49,0,3,0c1.1,0,2-0.9,2-2 C9,16.9,8.1,16,7,16z" />
                                                    </g>
                                                </g>
                                            </svg>
                                            {/* End::header-link-icon */}
                                        </span>
                                        <span className="dark-layout">
                                            {/* Start::header-link-icon */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                enableBackground="new 0 0 24 24"
                                                className="header-link-icon"
                                                viewBox="0 0 24 24"
                                            >
                                                <rect fill="none" height={24} width={24} />
                                                <path
                                                    d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
                                            </svg>
                                            {/* End::header-link-icon */}
                                        </span>
                                    </a>
                                    {/* End::header-link|layout-setting */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element */}
                                <div className="header-element header-search d-none">
                                    {/* Start::header-link */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link d-lg-none d-block"
                                        data-bs-toggle="modal"
                                        data-bs-target="#searchModal"
                                    >
                                        {/* <i className="bi bi-search-alt-2   ps-0"></i> */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                        </svg>
                                    </a>
                                    {/* End::header-link */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element */}
                                <div className="header-element dropdown-center cart-dropdown d-none">
                                    {/* Start::header-link|dropdown-toggle */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                                        </svg>
                                        <span
                                            className="badge bg-primary rounded-pill header-icon-badge"
                                            id="cart-icon-badge"
                                        >
                                            5
                                        </span>
                                    </a>
                                    {/* End::header-link|dropdown-toggle */}
                                    {/* Start::main-header-dropdown */}
                                    <div
                                        className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                        data-popper-placement="none"
                                    >
                                        <div className="p-3 border-bottom">
                                            <div
                                                className="d-flex align-items-center justify-content-between">
                                                <p className="mb-0 fs-15 fw-semibold">Cart Items</p>
                                                <span
                                                    className="badge bg-secondary-transparent"
                                                    id="cart-data"
                                                >
                                                    5 Items
                                                </span>
                                            </div>
                                        </div>
                                        <ul
                                            className="mb-0 overflow-auto"
                                            id="header-cart-items-scroll"
                                        >
                                            <li className="dropdown-item">
                                                <div
                                                    className="d-flex align-items-start cart-dropdown-item">
                                                    <img
                                                        src="build/assets/images/products/8.jpg"
                                                        alt="img"
                                                        className="avatar avatar-md br-5 me-3"
                                                    />
                                                    <div className="flex-grow-1">
                                                        <div
                                                            className="d-flex align-items-start justify-content-between mb-0">
                                                            <div className="mb-0 fs-14 fw-semibold">
                                                                <a href="cart.html" className="text-dark">
                                                                    White Tshirt
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="fs-15 mb-1">$1,299.00</span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="header-cart-remove float-end dropdown-item-close"
                                                                >
                                                                    <i className="ti ti-trash" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="min-w-fit-content d-flex align-items-start justify-content-between">
                                                            <ul className="header-product-item">
                                                                <li>Quantity: 1</li>
                                                                <li>Size: XL</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div
                                                    className="d-flex align-items-start cart-dropdown-item">
                                                    <img
                                                        src="build/assets/images/products/2.jpg"
                                                        alt="img"
                                                        className="avatar avatar-md br-5 me-3"
                                                    />
                                                    <div className="flex-grow-1">
                                                        <div
                                                            className="d-flex align-items-start justify-content-between mb-0">
                                                            <div
                                                                className="mb-0 fs-14 text-dark fw-semibold">
                                                                <a href="cart.html" className="text-dark">
                                                                    Canvas Shoes
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span className="fs-15 mb-1">$179.29</span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="header-cart-remove float-end dropdown-item-close"
                                                                >
                                                                    <i className="ti ti-trash" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="min-w-fit-content d-flex align-items-start justify-content-between">
                                                            <ul className="header-product-item">
                                                                <li>Quantity: 2</li>
                                                                <li>Size: L</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div
                                                    className="d-flex align-items-start cart-dropdown-item">
                                                    <img
                                                        src="build/assets/images/products/1.jpg"
                                                        alt="img"
                                                        className="avatar avatar-md br-5 me-3"
                                                    />
                                                    <div className="flex-grow-1">
                                                        <div
                                                            className="d-flex align-items-start justify-content-between mb-0">
                                                            <div
                                                                className="mb-0 fs-14 text-dark fw-semibold">
                                                                <a href="cart.html" className="text-dark">
                                                                    Photo Frame
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span className="fs-15 mb-1">$29.00</span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="header-cart-remove float-end dropdown-item-close"
                                                                >
                                                                    <i className="ti ti-trash" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="min-w-fit-content d-flex align-items-start justify-content-between">
                                                            <ul className="header-product-item">
                                                                <li>Quantity: 5</li>
                                                                <li>Size: M</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div
                                                    className="d-flex align-items-start cart-dropdown-item">
                                                    <img
                                                        src="build/assets/images/products/3.jpg"
                                                        alt="img"
                                                        className="avatar avatar-md br-5 me-3"
                                                    />
                                                    <div className="flex-grow-1">
                                                        <div
                                                            className="d-flex align-items-start justify-content-between mb-0">
                                                            <div
                                                                className="mb-0 fs-14 text-dark fw-semibold">
                                                                <a href="cart.html" className="text-dark">
                                                                    Flower pot
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="fs-15 mb-1">$4,999.00</span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="header-cart-remove float-end dropdown-item-close"
                                                                >
                                                                    <i className="ti ti-trash" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="min-w-fit-content d-flex align-items-start justify-content-between">
                                                            <ul className="header-product-item">
                                                                <li>Quantity: 2</li>
                                                                <li>Size: XXL</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div
                                                    className="d-flex align-items-start cart-dropdown-item">
                                                    <img
                                                        src="build/assets/images/products/5.jpg"
                                                        alt="img"
                                                        className="avatar avatar-md br-5 me-3"
                                                    />
                                                    <div className="flex-grow-1">
                                                        <div
                                                            className="d-flex align-items-start justify-content-between mb-0">
                                                            <div
                                                                className="mb-0 fs-14 text-dark fw-semibold">
                                                                <a href="cart.html" className="text-dark">
                                                                    Kikon Camera
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span className="fs-15 mb-1">$129.00</span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="header-cart-remove float-end dropdown-item-close"
                                                                >
                                                                    <i className="ti ti-trash" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="min-w-fit-content d-flex align-items-start justify-content-between">
                                                            <ul className="header-product-item">
                                                                <li>Quantity: 4</li>
                                                                <li>Size: XS</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="p-3 empty-header-item border-top">
                                            <div className="d-grid">
                                                <a href="checkout.html" className="btn btn-primary">
                                                    Proceed to checkout
                                                </a>
                                            </div>
                                        </div>
                                        <div className="p-5 empty-item d-none">
                                            <div className="text-center">
                                                <span className="avatar avatar-xl rounded-2 bg-warning-transparent">
                                                    <i className="ri-shopping-cart-2-line fs-2" />
                                                </span>
                                                <h6 className="fw-bold mb-1 mt-3">
                                                    Your Cart is Empty
                                                </h6>
                                                <a
                                                    href="shop.html"
                                                    className="btn btn-primary btn-wave btn-sm m-1"
                                                    data-abc="true"
                                                >
                                                    Back to Shop{" "}
                                                    <i className="bi bi-arrow-right ms-1" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End::main-header-dropdown */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element */}
                                <div className="header-element dropdown-center notifications-dropdown d-none">
                                    {/* Start::header-link|dropdown-toggle */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                                        </svg>
                                        <span
                                            className="badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary"
                                            id="notification-icon-badge"
                                        >
                                            4
                                        </span>
                                    </a>
                                    {/* End::header-link|dropdown-toggle */}
                                    {/* Start::main-header-dropdown */}
                                    <HeaderNotification />
                                    {/* End::main-header-dropdown */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element */}
                                <div className="header-element dropdown-center header-shortcuts-dropdown d-none">
                                    {/* Start::header-link|dropdown-toggle */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="notificationDropdown"
                                        aria-expanded="false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
                                        </svg>
                                    </a>
                                    {/* End::header-link|dropdown-toggle */}
                                    {/* Start::main-header-dropdown */}
                                    <div
                                        className="main-header-dropdown header-shortcuts-dropdown dropdown-menu pb-0 dropdown-menu-end"
                                        aria-labelledby="notificationDropdown"
                                    >
                                        <div className="p-3">
                                            <div
                                                className="d-flex align-items-center justify-content-between">
                                                <p className="mb-0 fs-17 fw-semibold">Related Apps</p>
                                            </div>
                                        </div>
                                        <div className="dropdown-divider my-0" />
                                        <div
                                            className="main-header-shortcuts py-1 px-4"
                                            id="header-shortcut-scroll"
                                        >
                                            <div className="row">
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="chat.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-message-outline text-primary fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Chat
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="file-manager.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-file-multiple-outline text-info fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Files
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="calendar2.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-calendar-range-outline text-warning fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Calendar
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="settings.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-cog-outline text-info fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Settings
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="faq.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-help-circle-outline text-success fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Help
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="profile.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-account-outline text-primary fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Profile
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="about.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-phone text-primary fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Contact
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="rating.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-comment-quote-outline text-secondary fs-24" />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Feeback
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-4 p-1 mt-0">
                                                    <a href="login.html">
                                                        <div
                                                            className="text-center p-3 related-app border rounded-2">
                                                            <span>
                                                                <i className="mdi mdi-logout text-warning fs-24 " />
                                                            </span>
                                                            <span className="d-block fs-12 text-dark">
                                                                Logout
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3 border-top">
                                            <div className="d-grid">
                                                <a
                                                    href="javascript:void(0);"
                                                    className="btn btn-primary"
                                                >
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End::main-header-dropdown */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element */}
                                <div className="header-element header-fullscreen d-none">
                                    {/* Start::header-link */}
                                    <a
                                        onClick="openFullscreen();"
                                        href="javascript:void(0);"
                                        className="header-link"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="header-link-icon full-screen-open"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            className="header-link-icon full-screen-close d-none"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="none"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" />
                                        </svg>
                                    </a>
                                    {/* End::header-link */}
                                </div>
                                {/* End::header-element */}
                                {/* Start::header-element|main-profile-user */}

                                <div className="header-element dropdown-center cart-dropdown">
                                    {/* <Link
                                        to="/chat"
                                        className="header-link"
                                    >
                                        <svg
                                            fill="#000000"
                                            height={14}
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                            stroke="#000000"
                                            strokeWidth="2.56"
                                            className="header-link-icon"
                                        >
                                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                            <g id="SVGRepo_iconCarrier">
                                                {" "}
                                                <g>
                                                    {" "}
                                                    <g>
                                                        {" "}
                                                        <path d="M426.872,0H212.824c-46.944,0-85.12,38.192-85.12,85.136v79.824c0,4.656,0.4,9.232,1.136,13.696H85.16 c-46.96,0.016-85.152,38.208-85.152,85.152v79.808c0,38.176,25.52,70.992,61.28,81.392V512l104.016-83.232h133.888 c46.944,0,85.12-38.192,85.12-85.136v-79.808c0-4.688-0.48-9.232-1.216-13.696h43.76c46.96,0,85.136-38.192,85.136-85.136V85.136 C512.008,38.192,473.816,0,426.872,0z M299.208,396.768h-145.12l-60.8,48.64v-47.68l-14.16-1.648 c-26.864-3.104-47.12-25.664-47.12-52.448v-79.808c0-29.296,23.84-53.136,53.136-53.136h56.304 c10.912,16.864,27.488,29.856,47.504,35.664v87.008l104.032-83.232h57.488c1.184,4.368,1.84,8.96,1.84,13.696v79.808h0.016 C352.328,372.928,328.504,396.768,299.208,396.768z M480.008,164.976c0,29.296-23.84,53.136-53.136,53.136h-56.096h-44.944 h-44.064l-60.816,48.64V219.04l-14.16-1.648c-7.36-0.848-14.144-3.28-20.24-6.736c-2.976-1.696-5.744-3.68-8.304-5.872 c-0.176-0.16-0.336-0.32-0.512-0.48c-2.4-2.128-4.608-4.432-6.576-6.944c-0.128-0.16-0.256-0.336-0.384-0.512 c-2-2.608-3.76-5.376-5.248-8.32c-0.032-0.064-0.064-0.144-0.112-0.208c-1.52-3.072-2.768-6.288-3.696-9.648 c-1.2-4.4-2.048-8.944-2.048-13.696v-79.84C159.688,55.84,183.528,32,212.824,32h214.048c29.296,0,53.136,23.84,53.136,53.136 V164.976z" />{" "}
                                                    </g>{" "}
                                                </g>{" "}
                                            </g>
                                        </svg>



                                        <span
                                            className="badge bg-primary rounded-pill header-icon-badge"
                                            id="cart-icon-badge"
                                        >
                                            5
                                        </span>
                                    </Link> */}

                                    <HeaderNotification />
                                </div>
                                <Dropdown className="header-element main-profile-user">
                                    <Dropdown.Toggle as={Link}
                                        className="header-link d-flex align-items-center"
                                        id="mainHeaderProfile"
                                    >
                                        <span className="me-2">
                                            <span
                                                className="avatar avatar-md brround bg-danger-transparent text-danger text-uppercase">
                                                {user?.name?.split('')[0]}
                                                {''}
                                                {user?.last_name?.split('')[0]}
                                            </span>
                                        </span>
                                        <div className="d-xl-block d-none lh-1">
                                            <h6 className="fs-13 font-weight-semibold mb-0">
                                                {user?.name || ''}{' '}{user?.last_name || ''}
                                            </h6>
                                            <span className="op-8 fs-10">{user?.email || ''}</span>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className=" pt-0 overflow-hidden mt-1">
                                        <li>
                                            <Link className="dropdown-item" to="/profile">
                                                <i className="ti ti-user-circle fs-18 me-2 op-7" />
                                                Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/">
                                                <i className="ti ti-inbox fs-18 me-2 op-7" />
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li className="d-none">
                                            <a
                                                className="dropdown-item border-block-end"
                                                href="blog.html"
                                            >
                                                <i className="ti ti-clipboard-check fs-18 me-2 op-7" />
                                                Posts &amp; Activities
                                            </a>
                                        </li>
                                        <li className="d-none">
                                            <a className="dropdown-item" href="settings.html">
                                                <i className="ti ti-adjustments-horizontal fs-18 me-2 op-7" />
                                                Settings &amp; Privacy
                                            </a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider my-0" />
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                role="button"
                                                onClick={() => {
                                                    dispatch(logout())
                                                    navigate('/sign-in')
                                                }}
                                            >
                                                <i className="ti ti-power fs-18 me-2 op-7" />
                                                Sign Out
                                            </a>
                                        </li>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="header-element main-profile-user d-none">
                                    {/* Start::header-link|dropdown-toggle */}
                                    <a
                                        href="javascript:void(0);"
                                        className="header-link dropdown-toggle d-flex align-items-center"
                                        id="mainHeaderProfile"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span className="me-2">
                                            <img
                                                src={user}
                                                alt="img"
                                                width={30}
                                                height={30}
                                                className="rounded-circle"
                                            />
                                        </span>
                                        <div className="d-xl-block d-none lh-1">
                                            <h6 className="fs-13 font-weight-semibold mb-0">
                                                Jason Taylor
                                            </h6>
                                            <span className="op-8 fs-10">Web Designer</span>
                                        </div>
                                    </a>
                                    {/* End::header-link|dropdown-toggle */}
                                    <ul
                                        className="dropdown-menu pt-0 overflow-hidden dropdown-menu-end mt-1"
                                        aria-labelledby="mainHeaderProfile"
                                    >
                                        <li>
                                            <a className="dropdown-item" href="profile.html">
                                                <i className="ti ti-user-circle fs-18 me-2 op-7" />
                                                Profile
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="index.html">
                                                <i className="ti ti-inbox fs-18 me-2 op-7" />
                                                Dashboard
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item border-block-end"
                                                href="blog.html"
                                            >
                                                <i className="ti ti-clipboard-check fs-18 me-2 op-7" />
                                                Posts &amp; Activities
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="settings.html">
                                                <i className="ti ti-adjustments-horizontal fs-18 me-2 op-7" />
                                                Settings &amp; Privacy
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item border-block-end"
                                                href="faq.html"
                                            >
                                                <i className="ti ti-help fs-18 me-2 op-7" />
                                                Help Center
                                            </a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider my-0" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="register.html">
                                                <i className="ti ti-user-plus fs-18 me-2 op-7" />
                                                Add Another Account
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="login.html">
                                                <i className="ti ti-power fs-18 me-2 op-7" />
                                                Sign Out
                                            </a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider my-0" />
                                        </li>
                                        <li className="d-flex justify-content-center p-2">
                                            <span>
                                                <a
                                                    className="fs-12 px-2 border-end"
                                                    href="javascript:void(0);"
                                                >
                                                    Privacy Policy
                                                </a>
                                            </span>
                                            <span>
                                                <a
                                                    className="fs-12 px-2 border-end"
                                                    href="javascript:void(0);"
                                                >
                                                    Terms
                                                </a>
                                            </span>
                                            <span>
                                                <a className="fs-12 px-2" href="javascript:void(0);">
                                                    Cookies
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {/* End::header-element|main-profile-user */}
                            </div>
                            <div className="navbar navbar-collapse responsive-navbar p-0 d-none">
                                <div
                                    className="collapse navbar-collapse"
                                    id="navbarSupportedContent-4"
                                >
                                    <div className="d-flex align-items-center">
                                        {/* Start::header-element */}
                                        {/* Start::country select collapse */}
                                        <div className="header-element country-dropdown dropdown-center d-none">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="build/assets/images/flags-img/us_flag.jpg"
                                                    className="rounded-circle header-link-icon"
                                                    alt="img"
                                                />
                                            </a>
                                            {/* End::header-link|dropdown-toggle */}
                                            {/* Start::main-header-dropdown */}
                                            <div
                                                className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                                data-popper-placement="none"
                                            >
                                                <ul className="mb-0">
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/india_flag.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">India</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/malaysia.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">Malaysia</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/italy_flag.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">Italy</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/argentina.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">Argentina</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item active mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/us_flag.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">USA</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pe-2">
                                                                <img
                                                                    src="build/assets/images/flags-img/turkey.jpg"
                                                                    className="rounded-circle country-img"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="mb-0 fs-15">Turkey</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* End::main-header-dropdown */}
                                        </div>
                                        {/* End::country select collapse */}
                                        {/* Start::header-element */}
                                        <div className="header-element header-theme-mode">
                                            {/* Start::header-link|layout-setting */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link layout-setting"
                                            >
                                                <span className="light-layout">
                                                    {/* Start::header-link-icon */}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        enableBackground="new 0 0 24 24"
                                                        className="header-link-icon"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <g>
                                                            <rect fill="none" height={24} width={24} />
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M19.78,17.51c-2.47,0-6.57-1.33-8.68-5.43C8.77,7.57,10.6,3.6,11.63,2.01C6.27,2.2,1.98,6.59,1.98,12 c0,0.14,0.02,0.28,0.02,0.42C2.61,12.16,3.28,12,3.98,12c0,0,0,0,0,0c0-3.09,1.73-5.77,4.3-7.1C7.78,7.09,7.74,9.94,9.32,13 c1.57,3.04,4.18,4.95,6.8,5.86c-1.23,0.74-2.65,1.15-4.13,1.15c-0.5,0-1-0.05-1.48-0.14c-0.37,0.7-0.94,1.27-1.64,1.64 c0.98,0.32,2.03,0.5,3.11,0.5c3.5,0,6.58-1.8,8.37-4.52C20.18,17.5,19.98,17.51,19.78,17.51z" />
                                                                <path
                                                                    d="M7,16l-0.18,0C6.4,14.84,5.3,14,4,14c-1.66,0-3,1.34-3,3s1.34,3,3,3c0.62,0,2.49,0,3,0c1.1,0,2-0.9,2-2 C9,16.9,8.1,16,7,16z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    {/* End::header-link-icon */}
                                                </span>
                                                <span className="dark-layout">
                                                    {/* Start::header-link-icon */}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        enableBackground="new 0 0 24 24"
                                                        className="header-link-icon"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <rect fill="none" height={24} width={24} />
                                                        <path
                                                            d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
                                                    </svg>
                                                    {/* End::header-link-icon */}
                                                </span>
                                            </a>
                                            {/* End::header-link|layout-setting */}
                                        </div>
                                        {/* End::header-element */}
                                        {/* Start::header-element */}
                                        <div className="header-element header-search ">
                                            {/* Start::header-link */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link d-lg-none d-block"
                                                data-bs-toggle="modal"
                                                data-bs-target="#searchModal"
                                            >
                                                {/* <i className="bi bi-search-alt-2   ps-0"></i> */}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                </svg>
                                            </a>
                                            {/* End::header-link */}
                                        </div>
                                        {/* End::header-element */}
                                        {/* Start::header-element */}

                                        <div className="header-element dropdown-center cart-dropdown">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                                                </svg>
                                                <span
                                                    className="badge bg-primary rounded-pill header-icon-badge"
                                                    id="cart-icon-badge"
                                                >
                                                    5
                                                </span>
                                            </a>
                                        </div>

                                        {/* End::header-element */}
                                        {/* Start::header-element */}
                                        <div className="header-element dropdown-center notifications-dropdown">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                                                </svg>
                                                <span
                                                    className="badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary"
                                                    id="notification-icon-badge"
                                                >
                                                    4
                                                </span>
                                            </a>
                                            {/* End::header-link|dropdown-toggle */}
                                            {/* Start::main-header-dropdown */}
                                            <div
                                                className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                                data-popper-placement="none"
                                            >
                                                <div className="p-3">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between">
                                                        <p className="mb-0 fs-15 fw-semibold">
                                                            Notifications
                                                        </p>
                                                        <a
                                                            href="javascript:void(0);"
                                                            className="badge bg-secondary-transparent"
                                                            id="notifiation-data"
                                                        >
                                                            4 Items
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="dropdown-divider my-0" />
                                                <ul className="list-unstyled mb-0">
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-start">
                                                            <div className="pe-2">
                                                                <span className="avatar avatar-md bg-primary rounded-circle">
                                                                    <i className="ti ti-gift fs-18" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div
                                                                    className="d-flex align-items-start justify-content-between">
                                                                    <div>
                                                                        <a
                                                                            href="notify-list.html"
                                                                            className="mb-0 fs-13 font-weight-semibold text-dark"
                                                                        >
                                                                            Nile Robetz send to a HTML file for
                                                                            Upload
                                                                        </a>
                                                                        <div className="p-1 text-warning">
                                                                            <span className="fs-12 me-2">
                                                                                <i className="bi bi-folder2-open me-1 fs-14" />
                                                                                HTML File
                                                                            </span>
                                                                            <span className="fs-13">
                                                                                <i className="bi bi-download" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="min-w-fit-content text-muted text-opacity-75 ms-1 dropdown-item-close1"
                                                                    >
                                                                        <i className="ti ti-x fs-16" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-start">
                                                            <div className="pe-2">
                                                                <span className="avatar avatar-md bg-secondary rounded-circle">
                                                                    <i className="ti ti-discount-2 fs-18" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div
                                                                    className="d-flex align-items-start justify-content-between">
                                                                    <div>
                                                                        <a
                                                                            href="notify-list.html"
                                                                            className="mb-0 fs-13 font-weight-semibold text-dark"
                                                                        >
                                                                            Conference meeting about client project
                                                                        </a>
                                                                        <div className="p-1">
                                                                            <span className="fs-11 text-muted me-3">
                                                                                <i className="bi bi-calendar me-1" />
                                                                                Monday - 11:00 AM - 45 minutes
                                                                            </span>
                                                                        </div>
                                                                        <a
                                                                            href="profile.html"
                                                                            className="d-flex align-items-center mt-1"
                                                                        >
                                                                            <span className="avatar avatar-sm brround">
                                                                                <img
                                                                                    src="build/assets/images/users/1.jpg"
                                                                                    className="brround"
                                                                                    alt="img"
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                className="ms-2 fs-13 text-gray-600">
                                                                                Nile Rebort
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="min-w-fit-content text-muted text-opacity-75 ms-1 dropdown-item-close1"
                                                                    >
                                                                        <i className="ti ti-x fs-16" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-start">
                                                            <div className="pe-2">
                                                                <span className="avatar avatar-md bg-pink rounded-circle">
                                                                    <i className="ti ti-user-check fs-18" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div
                                                                    className="d-flex align-items-start justify-content-between">
                                                                    <a
                                                                        href="notify-list.html"
                                                                        className="mb-0 fs-13 font-weight-semibold text-dark"
                                                                    >
                                                                        Taylor invite to a design channel
                                                                    </a>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="min-w-fit-content text-muted text-opacity-75 ms-1 dropdown-item-close1"
                                                                    >
                                                                        <i className="ti ti-x fs-16" />
                                                                    </a>
                                                                </div>
                                                                <div className="fs-12">
                                                                    <small className="text-muted fs-12">
                                                                        Hi, can i change my commission amount?
                                                                    </small>
                                                                    <div className="mt-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary-light btn-sm me-1 fs-11"
                                                                        >
                                                                            Accept
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger-light btn-sm fs-11"
                                                                        >
                                                                            Reject
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="dropdown-item mb-1">
                                                        <div className="d-flex align-items-start">
                                                            <div className="pe-2">
                                                                <span className="avatar avatar-md bg-warning rounded-circle">
                                                                    <i className="ti ti-circle-check fs-18" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div
                                                                    className="d-flex align-items-start justify-content-between">
                                                                    <a
                                                                        href="notify-list.html"
                                                                        className="mb-0 fs-13 font-weight-semibold text-dark"
                                                                    >
                                                                        Order Placed{" "}
                                                                        <span className="text-primary">
                                                                            ID: #1116773
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="min-w-fit-content text-muted text-opacity-75 ms-1 dropdown-item-close1"
                                                                    >
                                                                        <i className="ti ti-x fs-16" />
                                                                    </a>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-conent-between fs-12">
                                                                    <span className="text-muted">
                                                                        Order Placed Successfully
                                                                    </span>
                                                                    <span
                                                                        className="align-self-end min-w-fit-content text-muted mg-s-5">
                                                                        12:46
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                {/* <div className="dropdown-divider"></div> */}
                                                <div className="p-3 empty-header-item1">
                                                    <div className="d-grid">
                                                        <a
                                                            href="notify-list.html"
                                                            className="btn btn-primary"
                                                        >
                                                            View All
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="p-5 empty-item1 d-none">
                                                    <div className="text-center">
                                                        <span className="avatar avatar-xl rounded-2 bg-secondary-transparent">
                                                            <i className="ri-notification-off-line fs-2" />
                                                        </span>
                                                        <h6 className="fw-semibold mt-3">
                                                            No New Notifications
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End::main-header-dropdown */}
                                        </div>
                                        {/* End::header-element */}
                                        {/* Start::header-element */}
                                        <div className="header-element dropdown-center header-shortcuts-dropdown">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                id="notificationDropdown"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
                                                </svg>
                                            </a>
                                            {/* End::header-link|dropdown-toggle */}
                                            {/* Start::main-header-dropdown */}
                                            <div
                                                className="main-header-dropdown header-shortcuts-dropdown dropdown-menu pb-0 dropdown-menu-end"
                                                aria-labelledby="notificationDropdown"
                                            >
                                                <div className="p-3">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between">
                                                        <p className="mb-0 fs-17 fw-semibold">Related Apps</p>
                                                    </div>
                                                </div>
                                                <div className="dropdown-divider my-0" />
                                                <div
                                                    className="main-header-shortcuts py-1 px-4"
                                                    id="header-shortcut-scroll"
                                                >
                                                    <div className="row">
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="chat.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-message-outline text-primary fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Chat
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="file-manager.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-file-multiple-outline text-info fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Files
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="calendar2.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-calendar-range-outline text-warning fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Calendar
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="settings.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-cog-outline text-info fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Settings
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="faq.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-help-circle-outline text-success fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Help
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="profile.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-account-outline text-primary fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Profile
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="about.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-phone text-primary fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Contact
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="rating.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-comment-quote-outline text-secondary fs-24" />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Feeback
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-4 p-1 mt-0">
                                                            <a href="login.html">
                                                                <div
                                                                    className="text-center p-3 related-app border rounded-2">
                                                                    <span>
                                                                        <i className="mdi mdi-logout text-warning fs-24 " />
                                                                    </span>
                                                                    <span className="d-block fs-12 text-dark">
                                                                        Logout
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-3 border-top">
                                                    <div className="d-grid">
                                                        <a
                                                            href="javascript:void(0);"
                                                            className="btn btn-primary"
                                                        >
                                                            View All
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End::main-header-dropdown */}
                                        </div>
                                        {/* End::header-element */}
                                        {/* Start::header-element */}
                                        <div className="header-element header-fullscreen">
                                            {/* Start::header-link */}
                                            <a
                                                onclick="openFullscreen();"
                                                href="javascript:void(0);"
                                                className="header-link"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon full-screen-open"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    className="header-link-icon full-screen-close d-none"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="none"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" />
                                                </svg>
                                            </a>
                                            {/* End::header-link */}
                                        </div>
                                        {/* End::header-element */}
                                        {/* Start::header-element|main-profile-user */}

                                        <div className="header-element dropdown-center cart-dropdown">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-link-icon"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path
                                                        d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                                                </svg>
                                                <span
                                                    className="badge bg-primary rounded-pill header-icon-badge"
                                                    id="cart-icon-badge"
                                                >
                                                    5
                                                </span>
                                            </a>
                                        </div>

                                        <Dropdown className="header-element main-profile-user d-none">
                                            <Dropdown.Toggle as={Link}
                                                className="header-link d-flex align-items-center"
                                                id="mainHeaderProfile"
                                            >
                                                <span className="me-2">
                                                    <img
                                                        src={user}
                                                        alt="img"
                                                        width={30}
                                                        height={30}
                                                        className="rounded-circle"
                                                    />
                                                </span>
                                                <div className="d-xl-block d-none lh-1">
                                                    <h6 className="fs-13 font-weight-semibold mb-0">
                                                        Json Taylor
                                                    </h6>
                                                    <span className="op-8 fs-10">Web Designer</span>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className=" pt-0 overflow-hidden mt-1">
                                                <li>
                                                    <a className="dropdown-item" href="profile.html">
                                                        <i className="ti ti-user-circle fs-18 me-2 op-7" />
                                                        Profile
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="index.html">
                                                        <i className="ti ti-inbox fs-18 me-2 op-7" />
                                                        Dashboard
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item border-block-end"
                                                        href="blog.html"
                                                    >
                                                        <i className="ti ti-clipboard-check fs-18 me-2 op-7" />
                                                        Posts &amp; Activities
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="settings.html">
                                                        <i className="ti ti-adjustments-horizontal fs-18 me-2 op-7" />
                                                        Settings &amp; Privacy
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item border-block-end"
                                                        href="faq.html"
                                                    >
                                                        <i className="ti ti-help fs-18 me-2 op-7" />
                                                        Help Center
                                                    </a>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider my-0" />
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="register.html">
                                                        <i className="ti ti-user-plus fs-18 me-2 op-7" />
                                                        Add Another Account
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="login.html">
                                                        <i className="ti ti-power fs-18 me-2 op-7" />
                                                        Sign Out
                                                    </a>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="header-element main-profile-user d-none">
                                            {/* Start::header-link|dropdown-toggle */}
                                            <a
                                                href="javascript:void(0);"
                                                className="header-link dropdown-toggle d-flex align-items-center"
                                                id="mainHeaderProfile"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span className="me-2">
                                                    <img
                                                        src={user}
                                                        alt="img"
                                                        width={30}
                                                        height={30}
                                                        className="rounded-circle"
                                                    />
                                                </span>
                                                <div className="d-xl-block d-none lh-1">
                                                    <h6 className="fs-13 font-weight-semibold mb-0">
                                                        Jason Taylor
                                                    </h6>
                                                    <span className="op-8 fs-10">Web Designer</span>
                                                </div>
                                            </a>
                                            {/* End::header-link|dropdown-toggle */}
                                            <ul
                                                className="dropdown-menu pt-0 overflow-hidden dropdown-menu-end mt-1"
                                                aria-labelledby="mainHeaderProfile"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="profile.html">
                                                        <i className="ti ti-user-circle fs-18 me-2 op-7" />
                                                        Profile
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="index.html">
                                                        <i className="ti ti-inbox fs-18 me-2 op-7" />
                                                        Dashboard
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item border-block-end"
                                                        href="blog.html"
                                                    >
                                                        <i className="ti ti-clipboard-check fs-18 me-2 op-7" />
                                                        Posts &amp; Activities
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="settings.html">
                                                        <i className="ti ti-adjustments-horizontal fs-18 me-2 op-7" />
                                                        Settings &amp; Privacy
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item border-block-end"
                                                        href="faq.html"
                                                    >
                                                        <i className="ti ti-help fs-18 me-2 op-7" />
                                                        Help Center
                                                    </a>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider my-0" />
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="register.html">
                                                        <i className="ti ti-user-plus fs-18 me-2 op-7" />
                                                        Add Another Account
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="login.html">
                                                        <i className="ti ti-power fs-18 me-2 op-7" />
                                                        Sign Out
                                                    </a>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider my-0" />
                                                </li>
                                                <li className="d-flex justify-content-center p-2">
                                                    <span>
                                                        <a
                                                            className="fs-12 px-2 border-end"
                                                            href="javascript:void(0);"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <a
                                                            className="fs-12 px-2 border-end"
                                                            href="javascript:void(0);"
                                                        >
                                                            Terms
                                                        </a>
                                                    </span>
                                                    <span>
                                                        <a className="fs-12 px-2" href="javascript:void(0);">
                                                            Cookies
                                                        </a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* End::header-element|main-profile-user */}
                                    </div>
                                </div>
                            </div>
                            {/* Start::header-element */}
                            <div className="header-element d-none">
                                {/* Start::header-link|switcher-icon */}
                                <a
                                    href="javascript:void(0);"
                                    className="header-link switcher-icon"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#switcher-canvas"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        className="header-link-icon fa-spin"
                                    >
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path
                                            d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                                    </svg>
                                </a>
                                {/* End::header-link|switcher-icon */}
                            </div>
                            {/* End::header-element */}
                        </div>
                        {/* End::header-content-right */}
                    </div>
                    {/* End::main-header-container */}
                </header>
                {/* End Main-Header */}
                {/*Main-Sidebar*/}
                <aside className="app-sidebar sticky overflow-auto" id="sidebar">
                    {/* Start::main-sidebar-header */}
                    <div className="main-sidebar-header">
                        <Link to="/" className="header-logo">
                            <img
                                src={logo}
                                alt="logo"
                                className="desktop-logo"
                                style={{ height: 82, objectFit: 'contain', objectPosition: 'center' }}
                            />
                            <img
                                src={logo}
                                alt="logo"
                                className="toggle-logo"
                                style={{ height: 82, objectFit: 'contain', objectPosition: 'center' }}
                            />
                            <img
                                src={logo}
                                alt="logo"
                                className="desktop-dark"
                                style={{ height: 82, objectFit: 'contain', objectPosition: 'center' }}
                            />
                            <img
                                src={logo}
                                alt="logo"
                                className="toggle-dark"
                                style={{ height: 82, objectFit: 'contain', objectPosition: 'center' }}
                            />
                            {/*<h3 className='text-center text-white fw-bold'>Logo</h3>*/}
                        </Link>
                    </div>
                    {/* End::main-sidebar-header */}
                    {/* Start::main-sidebar */}
                    <div className="main-sidebar" id="sidebar-scroll">
                        {/* Start::nav */}
                        <nav className="main-menu-container nav nav-pills flex-column sub-open">
                            <div className="slide-left" id="slide-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                                </svg>
                            </div>
                            <ul className="main-menu">

                                {/*
                               
                                <li className="slide">
                                    <NavLink to="/" className="side-menu__item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="side-menu__icon"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#000000"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
                                        </svg>
                                        <span className="side-menu__label">Dashboard</span>
                                    </NavLink>
                                </li>
                              
                               

                                
                                <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg
                                            className="side-menu__icon"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 18"
                                            style={{ height: 14 }}
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none"></path>
                                            <path
                                                d="m2.133 2.6 5.856 6.9L8 14l4 3 .011-7.5 5.856-6.9a1 1 0 0 0-.804-1.6H2.937a1 1 0 0 0-.804 1.6Z"
                                            />
                                        </svg>
                                        <span className="side-menu__label">Leads</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Leads</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/lead/import" className="side-menu__item">
                                                Import
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/lead/create" className="side-menu__item">
                                                Create
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/lead/list" className="side-menu__item">
                                                List
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/lead/archive" className="side-menu__item">
                                                Archive
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/lead/pipeline" className="side-menu__item">
                                                Pipeline
                                            </NavLink>
                                        </li>
                                      
                                    </ul>
                                </li>

                               

                                
                                <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg
                                            className="side-menu__icon"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 18"
                                            style={{ height: 14 }}
                                        >
                                            <path
                                                // stroke="currentColor"
                                                // strokeLinecap="round"
                                                // strokeLinejoin="round"
                                                // strokeWidth={2}
                                                d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                                            />
                                        </svg>
                                        <span className="side-menu__label">Clients</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Clients</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/import" className="side-menu__item">
                                                Import
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/create" className="side-menu__item">
                                                Create
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/list" className="side-menu__item">
                                                List
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/pipeline" className="side-menu__item">
                                                Pipeline
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                               

                                <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg
                                            className="side-menu__icon"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            style={{ height: 14 }}
                                        >
                                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                                        </svg>
                                        <span className="side-menu__label">Orders</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Clients</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/orders" className="side-menu__item">
                                                Sprint Communications
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/orders" className="side-menu__item">
                                                EE Communications
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                               
                                <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg
                                            className="side-menu__icon"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 18"
                                            style={{ height: 14 }}
                                        >
                                            <path
                                                // stroke="currentColor"
                                                // strokeLinecap="round"
                                                // strokeLinejoin="round"
                                                // strokeWidth={2}
                                                d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                                            />
                                        </svg>
                                        <span className="side-menu__label">Marketing</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Marketing</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/marketing/new-campaign" className="side-menu__item">
                                                New Campaign
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/marketing/campaign-list" className="side-menu__item">
                                                Campaigns List
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/marketing/campaign-template" className="side-menu__item">
                                                Campaign Templates
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                               
                                <li className="slide">
                                    <NavLink to="/training" className="side-menu__item">
                                        <svg
                                            className="side-menu__icon"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            style={{ height: 14 }}
                                        >
                                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                                        </svg>
                                        <span className="side-menu__label">Training & Material</span>
                                    </NavLink>
                                </li>
                               
                                <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            className="side-menu__icon"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path>
                                        </svg>
                                        <span className="side-menu__label">Settings</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Settings</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/lead-status" className="side-menu__item">
                                                Lead Status
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/client-status" className="side-menu__item">
                                                Client Status
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/order-status" className="side-menu__item">
                                                Order Status
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/compaigns" className="side-menu__item">
                                                Campaigns
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/business-type" className="side-menu__item">
                                                Business Type
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/networks" className="side-menu__item">
                                                Networks
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/sale-type" className="side-menu__item">
                                                Sale Type
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/head-set" className="side-menu__item">
                                                Head Set
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/setting/tarrif" className="side-menu__item">
                                                Tarrif
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li> */}

                                {/* Dashboard Start Here */}
                                <li className="slide mt-5">
                                    <NavLink to="/" className="side-menu__item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="side-menu__icon"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#000000"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
                                        </svg>
                                        <span className="side-menu__label">Dashboard</span>
                                    </NavLink>
                                </li>
                                {/* Dashboard End Here */}
                                {/* Leads Start Here */}

                                {(checkPermission('lead.get') || checkPermission('lead.store') || checkPermission('lead.upload-csv') || checkPermission('lead.get-archive')) && (
                                    <>
                                        {/* <li className="slide">
                                            <NavLink to="/lead/list" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                          fill="currentColor"/>
                                                </svg>
                                                <span className="side-menu__label">Leads</span>
                                            </NavLink>
                                        </li> */}
                                        <li className="slide has-sub">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Leads</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Leads</a>
                                                </li>
                                                {checkPermission('lead.get') && (
                                                    <li className="slide">
                                                        <NavLink to="/lead/list" className="side-menu__item">
                                                            List
                                                        </NavLink>
                                                    </li>
                                                )}
                                                {/* {checkPermission('lead.store') && (
                                                    <li className="slide">
                                                        <NavLink to="/lead/create" className="side-menu__item">
                                                            Create
                                                        </NavLink>
                                                    </li>
                                                )} */}

                                                {/* {checkPermission('lead.upload-csv') && (
                                                    <li className="slide">
                                                        <NavLink to="/lead/import" className="side-menu__item">
                                                            Import
                                                        </NavLink>
                                                    </li>
                                                )} */}
                                                {(checkPermission('lead.get-archive') || checkPermission('lead.get')) && (
                                                    <li className="slide">
                                                        <NavLink to="/lead/pipeline" className="side-menu__item">
                                                            Pipeline
                                                        </NavLink>
                                                    </li>
                                                )}
                                                {(checkPermission('lead.get-archive')) && (
                                                    <li className="slide">
                                                        <NavLink to="/lead/archive" className="side-menu__item">
                                                            Archive
                                                        </NavLink>
                                                    </li>
                                                )}


                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Leads End Here */}
                                {/* Clients Start Here */}

                                {/* <li className="slide has-sub">
                                    <a className="side-menu__item" role="button">
                                        <svg className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M18 1H6c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 19H6V4h12v16zM8.5 6c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5S10.827 7.5 10 7.5 8.5 6.827 8.5 6zM14 18h-4v-2h4v2zm0-3h-4v-1h4v1zm0-2h-4v-1h4v1zm0-2h-4V9h4v1z" fill="currentColor" /></svg>
                                        <span className="side-menu__label">Clients</span>
                                        <i className="fe fe-chevron-right side-menu__angle" />
                                    </a>
                                    <ul className="slide-menu child1">
                                        <li className="slide side-menu__label1">
                                            <a href="javascript:void(0);">Clients</a>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/import" className="side-menu__item">
                                                Import
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/create" className="side-menu__item">
                                                Create
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/list" className="side-menu__item">
                                                List
                                            </NavLink>
                                        </li>
                                        <li className="slide">
                                            <NavLink to="/client/pipeline" className="side-menu__item">
                                                Pipeline
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li> */}
                                {/* Clients End Here */}
                                {/* Identity Start Here */}

                                {(checkPermission('identity.get') || checkPermission('identity.store') || checkPermission('identity.store')) && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/identifies/list" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm2-8a5 5 0 1 1-4.669 6.686l-6.226 6.229a1 1 0 0 1-1.438-1.39l6.225-6.228A5 5 0 0 1 11 4z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Identities</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm2-8a5 5 0 1 1-4.669 6.686l-6.226 6.229a1 1 0 0 1-1.438-1.39l6.225-6.228A5 5 0 0 1 11 4z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Identities</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Identity</a>
                                                </li>
                                                {checkPermission('identity.get') && (
                                                    <li className="slide">
                                                        <NavLink to="/identifies/list" className="side-menu__item">
                                                            List
                                                        </NavLink>
                                                    </li>
                                                )}
                                                {checkPermission('identity.store') && (
                                                    <li className="slide">
                                                        <NavLink to="/identifies/create" className="side-menu__item">
                                                            Create
                                                        </NavLink>
                                                    </li>
                                                )}
                                                {checkPermission('identity.store') && (
                                                    <li className="slide">
                                                        <NavLink to="/identifies/import" className="side-menu__item">
                                                            Import
                                                        </NavLink>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Identity End Here */}
                                {/* Tickets Start Here */}

                                {(checkPermission('ticket.get') || checkPermission('ticket.store')) && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/tickets" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Tickets</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Tickets</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Tickets</a>
                                                </li>
                                                {checkPermission('ticket.get') && (
                                                    <li className="slide">
                                                        <NavLink to="/tickets" className="side-menu__item">
                                                            List
                                                        </NavLink>
                                                    </li>
                                                )}
                                                {checkPermission('ticket.store') && (
                                                    <li className="slide">
                                                        <NavLink to="/create-ticket" className="side-menu__item">
                                                            Create
                                                        </NavLink>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Tickets End Here */}
                                {/* Teams Start Here */}

                                {checkPermission('team.get') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/teams" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Teams</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Teams</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Teams</a>
                                                </li>
                                                {checkPermission('team.get') && (
                                                    <li className="slide">
                                                        <NavLink to="/teams" className="side-menu__item">
                                                            Teams
                                                        </NavLink>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Teams End Here */}
                                {/* Tasks Start Here */}

                                {checkPermission('task.get') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/tasks" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Tasks</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M12 4.5c3.038 0 5.5 2.462 5.5 5.5 0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5c0-3.038 2.462-5.5 5.5-5.5zM12 3C7.037 3 3 7.037 3 12c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.963-4.037-9-9-9zm-2 11.5h4v2h-4v-2zm4-6.5h-4v-1h4v1zm1-1.5v-3h-1v3h1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Tasks</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Tasks</a>
                                                </li>
                                                <li className="slide">
                                                    <NavLink to="/tasks" className="side-menu__item">
                                                        Tasks
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Tasks End Here */}
                                {/* Calendar Start Here */}

                                {checkPermission('task.get-calender') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/calendar" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M5 5V4h14v1h2v16H3V5h2zm14 2H3v12h18V7zm-4 7h-4v-4h4v4z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Calendar</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M5 5V4h14v1h2v16H3V5h2zm14 2H3v12h18V7zm-4 7h-4v-4h4v4z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Calendar</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Calendar</a>
                                                </li>
                                                <li className="slide">
                                                    <NavLink to="/calendar" className="side-menu__item">
                                                        Calendar
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* Calendar End Here */}
                                {/* Notes Start Here */}

                                {checkPermission('notes.get') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/notes" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Notes</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Notes</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Notes</a>
                                                </li>
                                                <li className="slide">
                                                    <NavLink to="/notes" className="side-menu__item">
                                                        Notes
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}


                                {/* Notes End Here */}
                                {/* Documents Start Here */}

                                {checkPermission('document.get') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/documents" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Documents</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M20 5V2H4v3h4v2H4v3h4v2H4v7a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7h-4v-2h4V9h-4V7h4V5zm-6 12h-4v-2h4v2zm0-3h-4v-2h4v2zm0-3h-4v-2h4v2z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Documents</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Documents</a>
                                                </li>
                                                <li className="slide">
                                                    <NavLink to="/documents" className="side-menu__item">
                                                        Documents
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}


                                {/* Documents End Here */}
                                {/* Activity & Logs */}
                                {checkPermission('logs.get-logs') && (
                                    <>
                                        <li className="slide">
                                            <NavLink to="/activity-logs" className="side-menu__item">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M18 1H6c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 19H6V4h12v16zM8.5 6c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5S10.827 7.5 10 7.5 8.5 6.827 8.5 6zM14 18h-4v-2h4v2zm0-3h-4v-1h4v1zm0-2h-4v-1h4v1zm0-2h-4V9h4v1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Activity Logs</span>
                                            </NavLink>
                                        </li>
                                        <li className="slide has-sub d-none">
                                            <a className="side-menu__item" role="button">
                                                <svg className="side-menu__icon" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M18 1H6c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 19H6V4h12v16zM8.5 6c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5S10.827 7.5 10 7.5 8.5 6.827 8.5 6zM14 18h-4v-2h4v2zm0-3h-4v-1h4v1zm0-2h-4v-1h4v1zm0-2h-4V9h4v1z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span className="side-menu__label">Activity Logs</span>
                                                <i className="fe fe-chevron-right side-menu__angle" />
                                            </a>
                                            <ul className="slide-menu child1">
                                                <li className="slide side-menu__label1">
                                                    <a href="javascript:void(0);">Activity Logs</a>
                                                </li>
                                                <li className="slide">
                                                    <NavLink to="/activity-logs" className="side-menu__item">
                                                        Activity Logs
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}

                                {/* User & Roles */}

                                {/* Start::slide */}
                                {(checkPermission('employees.index') || checkPermission('roles.index')) && (
                                    <li className="slide has-sub">
                                        <a className="side-menu__item" role="button">
                                            <svg
                                                className="side-menu__icon"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 18"
                                                style={{ height: 14 }}
                                            >
                                                <path
                                                    // stroke="currentColor"
                                                    // strokeLinecap="round"
                                                    // strokeLinejoin="round"
                                                    // strokeWidth={2}
                                                    d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                                                />
                                            </svg>
                                            <span className="side-menu__label">Users & Roles</span>
                                            <i className="fe fe-chevron-right side-menu__angle" />
                                        </a>
                                        <ul className="slide-menu child1">
                                            <li className="slide side-menu__label1">
                                                <a href="javascript:void(0);">Users & Roles</a>
                                            </li>
                                            {checkPermission('employees.index') && (
                                                <li className="slide">
                                                    <NavLink to="/users" className="side-menu__item">
                                                        Users
                                                    </NavLink>
                                                </li>
                                            )}
                                            {checkPermission('roles.index') && (
                                                <li className="slide">
                                                    <NavLink to="/roles" className="side-menu__item">
                                                        Roles
                                                    </NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {/* Settings */}
                                <li className="slide">
                                    <NavLink to="/setting/index" className="side-menu__item">
                                        <svg className="side-menu__icon" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm2-8a5 5 0 1 1-4.669 6.686l-6.226 6.229a1 1 0 0 1-1.438-1.39l6.225-6.228A5 5 0 0 1 11 4z"
                                                fill="currentColor" />
                                        </svg>
                                        <span className="side-menu__label">Settings</span>
                                    </NavLink>
                                </li>

                            </ul>
                            <div className="slide-right" id="slide-right">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                                </svg>
                            </div>
                        </nav>
                        {/* End::nav */}
                    </div>
                    {/* End::main-sidebar */}
                </aside>
                {/* End Main-Sidebar*/}
                {/*app-content open*/}
                <div className="main-content app-content mt-0">
                    <Outlet />
                </div>
            </div >
            {/* Footer opened */}
            < footer className="footer d-none" >
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-md-12 col-sm-12 text-center">
                            Copyright © <span id="year" /> <a href="javascript:void(0);">Ohtechsolutions</a>
                            . Designed with <span className="fa fa-heart text-danger" /> by{" "}
                            <a href="javascript:void(0);"> Shahab </a> All rights reserved.
                        </div>
                    </div>
                </div>
            </footer >
            {" "}
            {/* End Footer */}
        </div >

        <div id="responsive-overlay"></div>


        {
            isShow && (
                <SearchModal
                    show={isShow}
                    handleClose={() => setIsShow(false)}
                />
            )
        }
    </>
    );
};

export default Layout;