import React, {useState} from 'react';
import {Nav, Tab, Tabs} from "react-bootstrap";

const Profile = () => {
    const [key, setKey] = useState('home');

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="card">
                    <div className="card-header">
                        <Nav  variant="pills" className="gap-2">
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    Customer Details
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">
                                    Biling & Shiping
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="card-body p-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="">
                                            <div className="p-5">
                                                <div className="form-horizontal">
                                                    <div className="mb-4 main-content-label">
                                                        Customer Details
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Street</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                  <textarea
                                                                      className="form-control"
                                                                      name="example-textarea-input"
                                                                      rows={2}
                                                                      placeholder="Street"
                                                                      defaultValue={"82477 Winifred Camp Apt. 131"}
                                                                  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Company</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Company"
                                                                    defaultValue="Luettgen Group"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">VAT Number</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="VAT Number"
                                                                    defaultValue="34234"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Phone</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Phone"
                                                                    defaultValue="31096-4360"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Website</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Website"
                                                                    defaultValue="von.com"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group ">
                                                        <div className="row align-items-end">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Default Language</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Default Language"
                                                                    defaultValue="english"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr/>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Street</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                  <textarea
                                                                      className="form-control"
                                                                      name="example-textarea-input"
                                                                      rows={2}
                                                                      placeholder="Street"
                                                                      defaultValue={"82477 Winifred Camp Apt. 131"}
                                                                  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">City</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="City"
                                                                    defaultValue="Jayceemouth"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">State</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="State"
                                                                    defaultValue="Colorado"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Zip Code</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Zip Code"
                                                                    defaultValue="31096-4360"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Country</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Country"
                                                                    defaultValue="Pakistan"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn ripple btn-light w-sm me-2"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn ripple btn-primary w-sm"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="">
                                            <div className="p-5">
                                                <div className="form-horizontal">
                                                    <div className="mb-4 main-content-label">
                                                        Billing Address
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Street</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                  <textarea
                                                                      className="form-control"
                                                                      name="example-textarea-input"
                                                                      rows={2}
                                                                      placeholder="Street"
                                                                      defaultValue={"82477 Winifred Camp Apt. 131"}
                                                                  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">City</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="City"
                                                                    defaultValue="Jayceemouth"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">State</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="State"
                                                                    defaultValue="Colorado"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Zip Code</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Zip Code"
                                                                    defaultValue="31096-4360"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Country</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Country"
                                                                    defaultValue="Pakistan"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4 main-content-label mt-4">
                                                        Shipping Address
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Street</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                  <textarea
                                                                      className="form-control"
                                                                      name="example-textarea-input"
                                                                      rows={2}
                                                                      placeholder="Street"
                                                                      defaultValue={"82477 Winifred Camp Apt. 131"}
                                                                  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">City</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="City"
                                                                    defaultValue="Jayceemouth"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">State</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="State"
                                                                    defaultValue="Colorado"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Zip Code</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Zip Code"
                                                                    defaultValue="31096-4360"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="form-label">Country</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Country"
                                                                    defaultValue="Pakistan"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn ripple btn-light w-sm me-2"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn ripple btn-primary w-sm"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
};

export default Profile;