import React from 'react';
import PageHeader from "../../components/PageHeader";
import User from '../../assets/images/21.jpg'

const ChatPage = () => {
    return (
        <>
            <PageHeader name="Chat"/>

            <div className="main-container container-fluid">
                {/* Row */}
                <div className="row row-sm">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                        <div className="card overflow-hidden">
                            <div className="main-content-app pt-0">
                                <div
                                    className="main-content-left main-content-left-chat"
                                    id="main-content-left-chat"
                                >
                                    {/* main-chat-header */}
                                    <div className="card-body d-none">
                                        <div className="main-chat-contacts-slider d-none d-sm-block overflow-auto">
                                            <label className="form-label mb-2 fs-13 mt-0">
                                                Online Users
                                            </label>
                                            <div className="lSSlideOuter main-chat-contacts-slider">
                                                <div className="main-chat-contacts-slider lSSlideWrapper usingCss">
                                                    <div className="main-chat-contacts lightSlider lsGrab lSSlide ps-0">
                                                        <div className="lslide active">
                        <span
                            className="avatar avatar-md bradius cover-image"
                            data-bs-image-src="build/assets/images/users/5.jpg"
                        >
                          <span className="avatar-status bg-secondary" />
                        </span>
                                                            <small>Ariana</small>
                                                        </div>
                                                        <div className="lslide">
                        <span
                            className="avatar avatar-md bradius cover-image"
                            data-bs-image-src="build/assets/images/users/9.jpg"
                        >
                          <span className="avatar-status bg-red" />
                        </span>
                                                            <small>Monino</small>
                                                        </div>
                                                        <div className="lslide">
                        <span
                            className="avatar avatar-md bradius cover-image"
                            data-bs-image-src="build/assets/images/users/6.jpg"
                        >
                          <span className="avatar-status bg-green" />
                        </span>
                                                            <small>Reynante</small>
                                                        </div>
                                                        <div className="lslide">
                        <span
                            className="avatar avatar-md bradius cover-image"
                            data-bs-image-src="build/assets/images/users/7.jpg"
                        >
                          <span className="avatar-status bg-yellow" />
                        </span>
                                                            <small>Labares</small>
                                                        </div>
                                                        <div className="lslide">
                        <span
                            className="avatar avatar-md bradius cover-image"
                            data-bs-image-src="build/assets/images/users/5.jpg"
                        >
                          <span className="avatar-status bg-secondary" />
                        </span>
                                                            <small>Rolando</small>
                                                        </div>
                                                        <div className="lslide">
                                                            <div className="avatar avatar-md bradius text-primary">
                                                                <i className="fe fe-users" />
                                                            </div>
                                                            <small>More</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* main-active-contacts */}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                            />
                                            <span className="input-group-text btn btn-primary">
                                              <i className="fe fe-search" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="tab-menu-heading border-top">
                                        <div className=" tabs-menu1">
                                            <ul className="nav panel-tabs justify-content-center">
                                                <li>
                                                    <a
                                                        href="#ChatList"
                                                        className="d-flex align-items-center active"
                                                        data-bs-toggle="tab"
                                                    >
                                                        Messages
                                                    </a>
                                                </li>
                                                <li className="d-none">
                                                    <a
                                                        href="#ChatGroups"
                                                        className="d-flex align-items-center"
                                                        data-bs-toggle="tab"
                                                    >
                                                        Groups
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#ChatContacts"
                                                        className="d-flex align-items-center"
                                                        data-bs-toggle="tab"
                                                    >
                                                        Users
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="panel-body tabs-menu-body  main-chat-list flex-2 main-chat-2"
                                        id="main-chat-list"
                                    >
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="ChatList">
                                                <div className="main-chat-list tab-pane" style={{overflowY: 'auto'}}>
                                                    <div className="media new border-top-0">
                                                        <div className="main-img-user online">
                                                            <img alt="" src={User} />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Raymart Santiago</span> <span>10 min</span>
                                                            </div>
                                                            <p> Hey! there I'm available </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>3</span>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Ariana Monino</span> <span>30 min</span>
                                                            </div>
                                                            <p>Good Morning</p>
                                                        </div>
                                                    </div>
                                                    <div className="media selected">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Reynante Labares</span> <span>9.40 am</span>
                                                            </div>
                                                            <p> Nice to meet you </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                      <span className="avatar avatar-md brround bg-danger-transparent text-danger">
                        J
                      </span>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Joyce Chua</span> <span>11.20 am</span>
                                                            </div>
                                                            <p> Hi, How are you? </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Rolando Paloso</span> <span>1.38 pm</span>
                                                            </div>
                                                            <p> Hey! there I'm available </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <div className="avatar avatar-md brround bg-primary-transparent text-primary">
                                                                D
                                                            </div>
                                                            <span>1</span>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Dexter dela Cruz</span> <span>4.08 pm</span>
                                                            </div>
                                                            <p>Typing...</p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Maricel Villalon</span> <span>8.09 pm</span>
                                                            </div>
                                                            <p> Hey! there I'm available </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                      <span className="avatar avatar-md brround bg-success-transparent text-success">
                        M
                      </span>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Maryjane Pechon</span> <span>1 day ago</span>
                                                            </div>
                                                            <p>I have some work</p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Lovely Dela Cruz</span> <span>3 days ago</span>
                                                            </div>
                                                            <p>I have some work</p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="avatar avatar-md brround bg-secondary-transparent">
                                                            <i className="fe fe-user text-secondary" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Daniel Padilla</span> <span>5 days ago</span>
                                                            </div>
                                                            <p>I have some work</p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>John Pratts</span> <span>20/06/2021</span>
                                                            </div>
                                                            <p>I have some work</p>
                                                        </div>
                                                    </div>
                                                    <div className="media new">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Socrates Itumay</span> <span>18/07/2021</span>
                                                            </div>
                                                            <p> Hey! there I'm available </p>
                                                        </div>
                                                    </div>
                                                    <div className="media new border-bottom-0">
                                                        <div className="main-img-user">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}                                                        </div>
                                                        <div className="media-body">
                                                            <div className="media-contact-name">
                                                                <span>Samuel Lerin</span> <span>29/07/2021</span>
                                                            </div>
                                                            <p> Hey! there I'm available </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="ChatGroups">
                                                <div className="d-flex align-items-center media border-top-0">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online text-primary">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Web Designers</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center media">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Project 2020</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center media">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Project 2021</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center media">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Freshers</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center media">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Experienced</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center media">
                                                    <div className="mb-0 me-2">
                                                        <div className="main-img-user online">
                                                            <img
                                                                alt=""
                                                                src={User}
                                                            />{" "}
                                                            <span>2</span>
                                                        </div>
                                                    </div>
                                                    <div className="align-items-center justify-content-between">
                                                        <div className="media-body ms-2">
                                                            <div className="media-contact-name">
                                                                <span>Freelancer</span>
                                                                <span />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="contact-status text-primary fe fe-user-plus" />
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                <div className="text-center p-5">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="btn btn-outline-primary"
                                                    >
                                                        Create New Group
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="ChatContacts">
                                                <div>
                                                    <div className="py-4 px-6 fw-bold">A</div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img
                                                                    alt=""
                                                                    src={User}
                                                                />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Anna Sthesia</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Home</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img
                                                                    alt=""
                                                                    src={User}
                                                                />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Abraham</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Mobile</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img
                                                                    alt=""
                                                                    src={User}
                                                                />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Anderson</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Office</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="py-4 px-6 fw-bold">B</div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img
                                                                    alt=""
                                                                    src={User}
                                                                />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Bernadette</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Mobile</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="py-4 px-6 fw-bold">C</div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/5.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Cameron</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Home</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/21.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Christ Arnold</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Mobile</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/2.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Clarkson Gray</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Home</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="py-4 px-6 fw-bold">D</div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/7.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Deirdre Short</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Home</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/12.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Dylan Gill</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Mobile</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/1.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Donna Davies</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Office</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="py-4 px-6 fw-bold">E</div>
                                                    <div className="d-flex align-items-center media">
                                                        <div className="mb-0 me-2">
                                                            <div className="main-img-user online">
                                                                <img alt="" src="build/assets/images/users/4.jpg" />{" "}
                                                                <span>2</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center justify-content-between">
                                                            <div className="media-body ms-2">
                                                                <div className="media-contact-name">
                                                                    <span>Elizabeth Scott</span>
                                                                    <span />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="text-muted fs-13">Office</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-primary fe fe-message-square me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-success fe fe-phone-call me-2" />
                                                            </a>
                                                            <a href="javascript:void(0);">
                                                                <i className="contact-status text-danger fe fe-video me-2" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* main-chat-list */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="card">
                            <div className="main-content-app pt-0">
                                <div className="main-content-body main-content-body-chat h-100">
                                    <div className="main-chat-header pt-3 d-block d-sm-flex">
                                        <div className="main-img-user online">
                                            <img alt="avatar" src={User} />
                                            <span className="avatar-status bg-primary" />
                                        </div>
                                        <div className="main-chat-msg-name mt-2 flex-fill">
                                            <h6>Saul Goodmate</h6>
                                            <small className="me-3">Last Semm 2 Hours ago</small>
                                        </div>
                                        <div className="d-flex">
                                            <button className="btn btn-icon btn-outline-light rounded-circle ms-2">
                                                <i className="ti ti-search" />
                                            </button>
                                            <button className="btn btn-icon btn-outline-light rounded-circle ms-2">
                                                <i className="ti ti-phone" />
                                            </button>
                                            <button className="btn btn-icon btn-outline-light rounded-circle ms-2">
                                                <i className="ti ti-video" />
                                            </button>
                                            <div className="dropdown ms-2">
                                                <button
                                                    className="btn btn-icon btn-outline-light rounded-circle btn-wave waves-light waves-effect waves-light"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i className="ti ti-dots-vertical" />
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-phone-call me-1 d-inline-flex" />{" "}
                                                        Phone Call
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-video me-1 d-inline-flex" /> Video
                                                        Call
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-user-plus me-1 d-inline-flex" /> Add
                                                        Contact
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-trash-2 me-1 d-inline-flex" /> Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* main-chat-header */}
                                    <div className="main-chat-body flex-2" style={{overflowY: 'auto'}} id="ChatBody">
                                        <div className="content-inner">
                                            <label className="main-chat-time">
                                                <span>2 days ago</span>
                                            </label>
                                            <div className="media chat-left">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        Lorem ipsum dolor sit amet 😀
                                                    </div>
                                                    <div>
                                                        <span>9:32 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media flex-row-reverse chat-right">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                                    </div>
                                                    <div className="main-msg-wrapper">
                                                      <span>
                                                        <span>
                                                          <i className="fa fa-image fs-14 pe-2" />
                                                        </span>
                                                        <span className="fs-14 mt-1">
                                                          {" "}
                                                            Image_attachment.jpg{" "}
                                                        </span>
                                                        <i className="fe fe-download mt-3 ps-2" />
                                                      </span>
                                                    </div>
                                                    <div>
                                                        <span>11:22 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="main-chat-time">
                                                <span>Yesterday</span>
                                            </label>
                                            <div className="media chat-left">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        Lorem ipsum dolor sit amet 👋
                                                    </div>
                                                    <div>
                                                        <span>9:32 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media flex-row-reverse chat-right">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        <i className="bi bi-gift text-whiet me-2" />
                                                        Donec quam felis, ultricies nec,
                                                    </div>
                                                    <div>
                                                        <span>9:48 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="main-chat-time">
                                                <span>Today</span>
                                            </label>
                                            <div className="media chat-left">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        Maecenas tempus, tellus eget condimentum rhoncus
                                                    </div>
                                                    <div className="main-msg-wrapper">
                                                        <img
                                                            alt="avatar"
                                                            className="w-10 h-10"
                                                            src="build/assets/images/media/3.jpg"
                                                        />
                                                        <img
                                                            alt="avatar"
                                                            className="w-10 h-10"
                                                            src="build/assets/images/media/4.jpg"
                                                        />
                                                        <img
                                                            alt="avatar"
                                                            className="w-10 h-10"
                                                            src="build/assets/images/media/5.jpg"
                                                        />
                                                    </div>
                                                    <div>
                                                        <span>10:12 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media flex-row-reverse chat-right">
                                                <div className="main-img-user online">
                                                    <img alt="avatar" src={User} />
                                                    <span className="avatar-status bg-primary" />
                                                </div>
                                                <div className="media-body">
                                                    <div className="main-msg-wrapper">
                                                        <div className="">
                                                            <a href="javascript:void(0);" className="text-white">
                                                                <i className="ri-play-circle-line align-middle" />
                                                            </a>
                                                            <span className="mx-1">
                          <svg width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                          <svg className="chat_audio" width={20} height={20}>
                            <defs />
                            <g transform="matrix(1,0,0,1,0,0)">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 3"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                              >
                                <path
                                    d="M6 19a1 1 0 0 1-1-1V6A1 1 0 0 1 7 6V18A1 1 0 0 1 6 19zM12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 12 18zM9 21a1 1 0 0 1-1-1V4a1 1 0 0 1 2 0V20A1 1 0 0 1 9 21zM3 17a1 1 0 0 1-1-1V8A1 1 0 0 1 4 8v8A1 1 0 0 1 3 17zM21 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 21 16zM15 16a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v6A1 1 0 0 1 15 16zM18 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0V17A1 1 0 0 1 18 18z"
                                    fill="rgba(255, 255, 255, 0.5)"
                                    className="fill-primary"
                                />
                              </svg>
                            </g>
                          </svg>
                        </span>
                                                            <a href="javascript:void(0);" className="text-white">
                                                                <i className="ri-download-2-line align-middle" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="main-msg-wrapper">
                                                        Maecenas tempus, tellus eget condimentum rhoncus
                                                    </div>
                                                    <div>
                                                        <span>09:40 am</span>{" "}
                                                        <a href="javascript:void(0);">
                                                            <i className="icon ion-android-more-horizontal" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-chat-footer">
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-outline-light brround"
                                        >
                                            <i className="fe fe-plus" />
                                        </button>
                                        <input
                                            className="form-control"
                                            placeholder="text here..."
                                            type="text"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-icon  btn-primary brround"
                                        >
                                            <i className="fa fa-paper-plane-o" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                        <div className="card overflow-hidden">
                            <div
                                className="main-content-app profile-details-main"
                                style={{overflowY: 'auto'}}
                                id="profile-details-main"
                            >
                                <div className="card-body p-5">
                                    <div className="">
                                        <nav className="nav float-end">
                                            <div className="dropdown">
                                                <a
                                                    className="nav-link text-muted fs-15 p-0 ps-3"
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-user me-1 d-inline-flex" /> Profile
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-edit me-1 d-inline-flex" /> Edit
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-users me-1 d-inline-flex" /> New Group
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-settings me-1 d-inline-flex" />{" "}
                                                        Settings
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        <i className="fe fe-trash-2 me-1 d-inline-flex" /> Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </nav>
                                        <div className="text-center chat-image border-bottom p-4 pb-0 mb-4 br-5">
                <span
                    className="avatar avatar-xxl mb-1 rounded-circle cover-image"
                    data-bs-image-src="build/assets/images/users/5.jpg"
                >
                  <span className="avatar-status bg-primary" />
                </span>
                                            <div className="main-chat-msg-name">
                                                <a href="profile.html">
                                                    <h5 className="mb-0 text-dark fw-semibold">
                                                        Percy Kewshun
                                                    </h5>
                                                    <p className="text-muted fs-13">Web Developer</p>
                                                </a>
                                            </div>
                                            <div className="btn-list mt-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-icon btn-primary-light rounded-pill"
                                                >
                                                    <i className="fe fe-phone-call" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-icon btn-primary-light rounded-pill"
                                                >
                                                    <i className="fe fe-message-circle" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-icon btn-primary-light rounded-pill"
                                                >
                                                    <i className="fe fe-video" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-icon btn-primary-light rounded-pill"
                                                >
                                                    <i className="fe fe-more-horizontal" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="border-bottom">
                                            <div className="d-flex mb-2">
                                                <div>
                                                    <a
                                                        className="nav-link border rounded-pill avatar avatar-sm bg-light me-2"
                                                        href="javascript:void(0);"
                                                    >
                                                        <i className="fe fe-mail" />
                                                    </a>
                                                </div>
                                                <div className="ms-2">
                                                    <p className="fs-13 fw-600 mb-0">Email</p>
                                                    <p className="fs-12 text-muted">gracie435@gmail.com.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2 mt-2">
                                                <div>
                                                    <a
                                                        className="nav-link border rounded-pill avatar avatar-sm bg-light me-2"
                                                        href="javascript:void(0);"
                                                    >
                                                        <i className="fe fe-phone" />
                                                    </a>
                                                </div>
                                                <div className="ms-2">
                                                    <p className="fs-13 fw-600 mb-0">Phone</p>
                                                    <p className="fs-12 text-muted">+123(45)-678-90.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2 mt-2">
                                                <div>
                                                    <a
                                                        className="nav-link border rounded-pill avatar avatar-sm bg-light me-2"
                                                        href="javascript:void(0);"
                                                    >
                                                        <i className="fe fe-map-pin" />
                                                    </a>
                                                </div>
                                                <div className="ms-2">
                                                    <p className="fs-13 fw-600 mb-0">Address</p>
                                                    <p className="fs-12 text-muted">
                                                        2nd street,houston texas,united states.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom">
                                            <div className="fs-15 fw-600 mt-3 mb-2">
                                                Shared Files :
                                                <span className="float-end fs-12">
                    <a href="javascript:void(0);" className="text-underline">
                      <u>View All</u>
                    </a>
                  </span>
                                            </div>
                                            <div className="">
                                                <div className="row">
                                                    <div className="media p-3">
                                                        <div className="avatar avatar-md me-2 bg-success-transparent rounded-circle">
                                                            <i className="fa fa-folder-open" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="d-flex align-items-center">
                                                                <div className="mt-0">
                                                                    <h5 className="mb-1 fs-13 fw-semibold text-dark">
                                                                        {" "}
                                                                        Demo_Document
                                                                    </h5>
                                                                    <p
                                                                        className="mb-0 fs-13 text-muted d-inline-flex"
                                                                        style={{ lineHeight: 0 }}
                                                                    >
                                                                        ppt<span className="fs-11 ms-2">1.2 mb</span>
                                                                    </p>
                                                                </div>
                                                                <span className="ms-auto fs-14">
                            <span className="float-end">
                              <span className="op-7 text-muted">
                                <i className="fe fe-download" />
                              </span>
                            </span>
                          </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media p-3">
                                                        <div className="avatar avatar-md me-2 bg-secondary-transparent rounded-circle">
                                                            <i className="fa fa-image" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="d-flex align-items-center">
                                                                <div className="mt-0">
                                                                    <h5 className="mb-1 fs-13 fw-semibold text-dark">
                                                                        Image_attachment
                                                                    </h5>
                                                                    <p
                                                                        className="mb-0 fs-13 text-muted d-inline-flex"
                                                                        style={{ lineHeight: 0 }}
                                                                    >
                                                                        img<span className="fs-11 ms-2">12 mb</span>
                                                                    </p>
                                                                </div>
                                                                <span className="ms-auto fs-14">
                            <span className="float-end">
                              <span className="op-7 text-muted">
                                <i className="fe fe-download" />
                              </span>
                            </span>
                          </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media p-3">
                                                        <div className="avatar avatar-md me-2 bg-info-transparent rounded-circle">
                                                            <i className="fa fa-envelope" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="d-flex align-items-center">
                                                                <div className="mt-0">
                                                                    <h5 className="mb-1 fs-13 fw-semibold text-dark">
                                                                        {" "}
                                                                        Video_attachment
                                                                    </h5>
                                                                    <p
                                                                        className="mb-0 fs-13 text-muted d-inline-flex"
                                                                        style={{ lineHeight: 0 }}
                                                                    >
                                                                        Video<span className="fs-12 ms-2">16 mb</span>
                                                                    </p>
                                                                </div>
                                                                <span className="ms-auto fs-14">
                            <span className="float-end">
                              <span className="op-7 text-muted">
                                <i className="fe fe-download" />
                              </span>
                            </span>
                          </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="fs-15 fw-600 mt-3 mb-2">
                                                Media :
                                                <span className="float-end fs-12">
                    <a href="javascript:void(0);" className="text-underline">
                      <u>View All</u>
                    </a>
                  </span>
                                            </div>
                                            <ul id="lightgallery" className="list-unstyled row">
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/01.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/01.jpg"
                                                    data-sub-html="<h4>Gallery Image 1</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-1"
                                                        />
                                                    </a>
                                                </li>
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/02.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/02.jpg"
                                                    data-sub-html="<h4>Gallery Image 2</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-2"
                                                        />
                                                    </a>
                                                </li>
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/03.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/03.jpg"
                                                    data-sub-html="<h4>Gallery Image 3</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-1"
                                                        />
                                                    </a>
                                                </li>
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/04.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/04.jpg"
                                                    data-sub-html=" <h4>Gallery Image 4</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-2"
                                                        />
                                                    </a>
                                                </li>
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/05.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/05.jpg"
                                                    data-sub-html="<h4>Gallery Image 5</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-1"
                                                        />
                                                    </a>
                                                </li>
                                                <li
                                                    className="col-xs-6 col-sm-4 col-md-4 col-xl-4 mt-2 border-bottom-0 px-1"
                                                    data-responsive="https://laravelui.spruko.com/vexel/build/assets/images/media/files/06.jpg"
                                                    data-src="https://laravelui.spruko.com/vexel/build/assets/images/media/files/06.jpg"
                                                    data-sub-html="<h4>Gallery Image 6</h4><p> Many desktop publishing packages and web page editors now use Lorem Ipsum</p>"
                                                >
                                                    <a href="javascript:void(0);">
                                                        <img
                                                            className="img-responsive br-5"
                                                            src={User}
                                                            alt="Thumb-2"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Row */}
            </div>

        </>
    );
};

export default ChatPage;