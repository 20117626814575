import React from 'react';
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { convertToFormData, sweetAlert } from "../../utils";
import LoadingButtonWithForm from "../../components/LoadingButtonWithForm";
import { useAddAlertMutation, useEditAlertMutation } from "../../redux/services/SettingAlertService";
import { useAddTeamsMutation, useEditTeamsMutation } from "../../redux/services/TeamsService";
import { useGetLeagueQuery } from "../../redux/services/SettingLeagueService";
import { useGetAccountMangerQuery } from "../../redux/services/SettingAccountMangerService";

const TeamCreateModal = ({ show, handleClose, isEdit }) => {
    let {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm()
    // add
    const [addRequest, { isLoading: addLoading }] = useAddTeamsMutation()
    // Edit
    const [editRequest, { isLoading: editLoading }] = useEditTeamsMutation()

    const { data: leagueData } = useGetLeagueQuery()
    const { data: managerData } = useGetAccountMangerQuery()
    const league = leagueData?.response?.data
    const accountManager = managerData?.response?.data

    const onSubmit = async (data) => {
        if (isEdit) {
            const newForm = convertToFormData({ id: isEdit?.id, ...data })
            await editRequest(newForm).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        } else {
            const newForm = convertToFormData(data)
            await addRequest(newForm).unwrap()
                .then((res) => {
                    if (res?.status) {
                        sweetAlert(res?.message, 'success')
                        reset()
                        handleClose()
                    }
                    if (res?.errors) {
                        Object.entries(res?.errors)?.forEach(([key, value]) => {
                            sweetAlert(value[0], 'error')
                        })
                    }
                })
                .catch((err) => {
                    sweetAlert(err?.data?.message, 'error')
                })
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="h5">{isEdit ? 'Edit' : 'Create'} Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} id="lead-status">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Team Name:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={isEdit?.name || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        type="text" placeholder="Enter Team Name "
                                        isInvalid={errors.name}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                League:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="league"
                                control={control}
                                defaultValue={isEdit?.league || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <Form.Select
                                        {...field}
                                        isInvalid={errors.league}
                                    >
                                        <option value="">Please Select</option>
                                        {league?.map((item) => (
                                            <option key={item?.id} value={item?.id}>
                                                {item?.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Link to the Account Manager:
                                {true && <span className="text-danger text-bold">*</span>}
                            </Form.Label>
                            <Controller
                                name="account_manager"
                                control={control}
                                defaultValue={isEdit?.account_manager || ''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <>
                                        {/*<Form.Select
                                            {...field}
                                            isInvalid={errors.account_manager}
                                        >
                                            <option value="">Please Select</option>
                                            {accountManager?.map((item) => (
                                                <option key={item?.id} value={item?.id}>
                                                    {item?.link}
                                                </option>
                                            ))}
                                        </Form.Select>*/}
                                        <Form.Control
                                            {...field}
                                            type="text" placeholder="https://example.com/"
                                            isInvalid={errors.account_manager}
                                        />
                                    </>
                                )}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Logo:</Form.Label>
                            <Controller
                                name="logo"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: false
                                }}
                                render={({ field }) => (
                                    <Form.Control
                                        type="file" placeholder="Logo"
                                        onChange={(e) => field.onChange(e.target.files[0])}
                                        isInvalid={errors.logo}
                                        accept="image/*"
                                    />
                                )}
                            />
                            {isEdit?.logo && (
                                <img src={isEdit?.logo_url} alt="logo" className="img-fluid mt-2" style={{ maxHeight: '80px' }} />
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <LoadingButtonWithForm
                        isLoading={editLoading ? editLoading : addLoading}
                        name="Save"
                        form="lead-status"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TeamCreateModal;